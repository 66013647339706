import Decimal from 'decimal.js';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router-dom';
import { Remarkable } from 'remarkable';
import styled from 'styled-components';
import { Button, ButtonRow } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import Page from '../../components/Global/Page';
import { Grid, GridColumn } from '../../components/Grid/Grid';
import ListItem from '../../components/ListItem';
import { Loading } from '../../components/Loading/Loading';
import { Modal, ModalBody, ModalHeader, ModalTitle } from '../../components/Modal';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { SegmentedControls } from '../../components/SegmentedControls/SegmentedControls';
import VoteItem, { ModifiedJazzicon } from '../../components/VoteItem';
import VoteLogo from '../../components/VoteLogo';
import { DEFAULT_SPACE } from '../../constants';
import { GSPI } from '../../constants/tokens';
import { useActiveWeb3React } from '../../hooks';
import { useProvider } from '../../hooks/useProvider';
import Governance from '../../http/Governance';
import { getVotes as api_getVotes } from '../../http/GQLGovernance';
import { getScores } from '../../http/snapshot';
import { _numeral } from '../../libs/utils';
import { AppState } from '../../states';
import { getProposals, getSpaces } from '../../states/governance/actions';
import { shorten } from '../../states/governance/hooks';
import { IProposal, IVote } from '../../types/governance';

const md = new Remarkable();

const VoteCard = styled(Card)`
	@media (max-width: 767px) {
		.card-body {
			padding-top: 0;
		}
	}
`;

const CardHeader = styled.div<{ withoutSubtitle?: boolean }>`
	display: flex;
	flex-direction: column;
	margin: -5px;

	@media (min-width: 768px) {
		margin: -10px 0 -18px;
		height: ${({ withoutSubtitle }) => (withoutSubtitle ? '70px' : 'auto')};
	}
`;

const VoteContent = styled.div`
	line-height: 19px;
	font-size: 1rem !important;
	color: ${({ theme }) => theme.text1};
	font-weight: 400;

	& p {
		margin: 0;
		font-weight: 400;
		font-size: 0.875rem;

		@media (min-width: 768px) {
			font-size: 1rem;
		}
	}

	& h1,
	& h2,
	& h3,
	& h4,
	& h5,
	& h6 {
		font-size: 1.25rem;
		margin-bottom: 0;
		font-weight: 500;

		@media (min-width: 768px) {
			font-size: 1.875rem;
		}
	}
`;

const TokenValue = styled.span`
	font-size: 0.875rem;
	font-weight: 500;
	padding-left: 11px;
	color: ${({ theme }) => theme.text1};

	@media (min-width: 768px) {
		font-size: 1rem;
		font-weight: 700;
	}
`;

const BoxTitle = styled.h4`
	font-weight: 700;
	font-size: 0.875rem;
	line-height: 1.5rem;
	color: ${({ theme }) => theme.text1};
	margin: 0 auto 0 0;
	padding-right: 0.75rem;

	@media (min-width: 768px) {
		padding-right: 0;
		font-size: 1.25rem;
		margin: 0 1.25rem 0 0;
	}
`;

const BoxSubTitle = styled.span`
	margin-bottom: 0.5rem;
	font-size: 0.875rem;
	font-weight: 400;
	color: ${({ theme }) => theme.text1};
	display: none;

	@media (min-width: 768px) {
		display: block;
	}
`;

const BoxHeader = styled.div`
	min-height: 40px;
	display: flex;
	align-items: center;

	& .label {
		@media (max-width: 767px) {
			height: 30px;
			padding: 5px 17px;
			border-radius: 12px;
			font-size: 0.875rem;
		}
	}
`;

const AuthorLink = styled.a`
	color: ${({ theme }) => theme.primary};
	font-weight: 700;
	font-size: 0.875rem;

	@media (min-width: 768px) {
		font-size: 1rem;
	}

	&:focus,
	&:active {
		outline: none;
	}
`;
const InfoText = styled.span<{ fontWeight?: number }>`
	font-size: 0.875rem;
	font-weight: ${({ fontWeight }) => fontWeight || 700};
	color: ${({ theme }) => theme.text1};

	@media (min-width: 768px) {
		font-size: 1rem;
		font-weight: 700;
	}
`;

const breadcrumb = [
	{
		title: 'Proposals',
		url: '/governance',
	},
	{
		title: 'Proposal',
	},
];

export type ExtendedVote = IVote & {
	scores: number[];
	balance: number;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ProposalDetails: React.VFC<RouteChildrenProps<{ id?: string }>> = (props) => {
	const api = useMemo(() => new Governance(), [Governance]);
	const { account, library } = useActiveWeb3React();
	const [selectedProposal, setSelectedProposal] = useState<IProposal | undefined>(undefined);
	const [selectedVote, setSelectedVote] = useState<number>();
	const [showModal, setShowModal] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const [votes, setVotes] = useState<Array<IVote> | undefined>(undefined);
	const [result, setResult] = useState<{
		results: {
			totalBalances?: number[];
			totalVotesBalances: number;
		};
	}>();
	const [status, setStatus] = useState<{ className: string; title: string }>();
	const dispatch = useDispatch();
	const { spaces, loading: governanceLoading, proposals } = useSelector((state: AppState) => state.governance);
	const web3Provider = useProvider();

	useEffect(() => {
		dispatch(getSpaces());
		dispatch(getProposals(DEFAULT_SPACE));
	}, [dispatch, getSpaces, getProposals, DEFAULT_SPACE]);

	const space = useMemo(() => {
		return spaces.find((space) => space.id == DEFAULT_SPACE);
	}, [spaces]);

	const proposalId = useMemo(() => {
		return props?.match?.params?.id;
	}, [props.match]);

	const getVotes = useCallback(() => {
		if (proposalId !== undefined && selectedProposal !== undefined && space !== undefined) {
			api_getVotes(proposalId).then(async (apiVotes) => {
				return getScores(
					space.id,
					selectedProposal.strategies,
					space.network,
					apiVotes.map((vote) => vote.voter),
					new Decimal(selectedProposal.snapshot).toNumber()
				)
					.then((scores) => {
						return apiVotes.map<IVote>((vote) => {
							return {
								...vote,
								score: new Decimal(scores[vote.voter] ?? 0),
							};
						});
					})
					.then((votes) => {
						votes.sort((a, b) => (a.score.greaterThan(b.score) ? -1 : 1));
						setVotes(votes);
						let totalVotesBalances = new Decimal(0);

						const totalBalances = selectedProposal.choices.map<number>((_, index) => {
							let totalBalance = new Decimal(0);
							votes
								.filter((vote) => vote.choice - 1 === index)
								.forEach((vote) => {
									totalBalance = totalBalance.add(vote.score);
									totalVotesBalances = totalVotesBalances.add(vote.score);
								});
							return totalBalance.toNumber();
						});
						setResult({ results: { totalBalances, totalVotesBalances: totalVotesBalances.toNumber() } });
					});
			});
		}
	}, [proposalId, api_getVotes, setVotes, selectedProposal, space]);

	useEffect(() => {
		getVotes();
	}, [getVotes]);

	useEffect(() => {
		const proposal = proposals.find((proposal) => proposal.id.toLowerCase() === proposalId?.toLowerCase());
		if (proposal !== undefined) {
			setSelectedProposal(proposal);
			const ts = (Date.now() / 1000).toFixed();
			const { start, end } = proposal;
			const state =
				ts > end.toFixed()
					? { title: 'Closed', className: 'label-light-danger' }
					: ts > start.toFixed()
					? { title: 'Active', className: 'label-light-success' }
					: { title: 'Pending', className: 'label-light-info' };
			setStatus(state);
		}
	}, [proposals, proposalId, props.history]);

	// const handleSubmit = useCallback(async () => {
	// 	if (account) {
	// 		try {
	// 			const payload = {
	// 				proposal: address,
	// 				choice: selectedVote,
	// 				metadata: {},
	// 			};
	// 			toast('Sending ...', {
	// 				icon: '💡',
	// 			});
	// 			const res = await api.broadcast(web3Provider, account, DEFAULT_SPACE!, 'vote', payload);
	// 			if (typeof res === 'object' && res !== null && res.hasOwnProperty('ipfsHash')) {
	// 				await getVotes(address!).then((votes) => {
	// 					setVotes(votes);
	// 				});
	// 				toast.success('Your Vote is in!');
	// 				setShowModal(false);
	// 			}
	// 		} catch (error) {
	// 			console.error(error);
	// 			toast.error('Something went wrong!');
	// 		}
	// 	} else {
	// 		props.history.push('/connect');
	// 		return false;
	// 	}
	// }, [selectedVote, account, address, api, props.history, web3Provider, getVotes]);

	const handleSubmit = useCallback(async () => {
		if (!account) {
			props.history.push('/connect');
			return;
		}
		if (selectedProposal === undefined) {
			return;
		}
		const msg: {
			address: string;
			msg: string;
			sig?: string;
		} = {
			address: account,
			msg: JSON.stringify({
				version: '0.1.3',
				timestamp: (Date.now() / 1000).toFixed(),
				type: 'vote',
				space: DEFAULT_SPACE,
				payload: {
					proposal: selectedProposal.id,
					choice: selectedVote,
					metadata: {},
				},
			}),
		};
		try {
			toast.success('Sending ...', {
				icon: '💡',
			});
			msg.sig = await library?.getSigner(account).signMessage(msg.msg);
			await api.sendMessage(msg);
			toast.success('Your Vote is in!');
			getVotes();
		} catch (e) {
			console.log(e);
			toast.error('Something went wrong!');
		} finally {
			setShowModal(false);
		}
	}, [selectedVote, account, api, library, spaces, getVotes]);

	return (
		<Page title={'Governance'} breadcrumb={breadcrumb}>
			{governanceLoading && !selectedProposal ? (
				<Card>
					<Loading />
				</Card>
			) : (
				selectedProposal && (
					<Grid className={'custom-row'}>
						<GridColumn width="2/3">
							<Grid>
								<GridColumn className={'gutter-b'}>
									<Card
										title={
											<CardHeader className="d-flex flex-column justify-content-around">
												<BoxSubTitle>Proposal</BoxSubTitle>
												<BoxHeader>
													<BoxTitle>{selectedProposal.title}</BoxTitle>
													{status && (
														<span
															className={`label ${status.className} label-lg font-weight-medium d-flex label-inline py-3`}
														>
															{status.title}
														</span>
													)}
												</BoxHeader>
											</CardHeader>
										}
									>
										<VoteContent
											className={'font-size-lg opacity-80'}
											style={{ whiteSpace: 'pre-wrap' }}
											dangerouslySetInnerHTML={{
												__html: md.render(selectedProposal.body),
											}}
										/>
									</Card>
								</GridColumn>
								{status?.title === 'Active' && (
									<GridColumn className={'gutter-b'}>
										<Card
											header={
												<CardHeader className="d-flex flex-column justify-content-around">
													<BoxSubTitle>Vote</BoxSubTitle>
													<BoxHeader>
														<BoxTitle>Cast Your vote</BoxTitle>
													</BoxHeader>
												</CardHeader>
											}
										>
											<div className="flex flex-col">
												<SegmentedControls
													labelId="vote-options"
													active={selectedVote}
													onChange={setSelectedVote}
													options={selectedProposal.choices.map((option, index) => ({
														id: `vote-${index + 1}`,
														label: option,
														value: index + 1,
													}))}
													vertical
												/>
												<Button
													primary
													className="py-3 mt-3 self-center"
													disabled={!selectedVote}
													style={{ width: 250 }}
													onClick={() => {
														!account ? props.history.push('/connect') : setShowModal(true);
													}}
												>
													{account ? 'Vote' : 'Connect Wallet'}
												</Button>
											</div>
										</Card>
									</GridColumn>
								)}
								<GridColumn className={'gutter-b'}>
									<VoteCard
										title={
											<CardHeader className="d-flex flex-column justify-content-around">
												<BoxSubTitle>Proposal</BoxSubTitle>
												<BoxHeader>
													<BoxTitle>Votes</BoxTitle>
													{votes && (
														<span
															className={`label label-light-primary label-lg font-weight-medium d-flex label-inline py-3`}
														>
															{votes.length}
														</span>
													)}
												</BoxHeader>
											</CardHeader>
										}
									>
										<div className="d-flex flex-column">
											<table className="table">
												<thead>
													<tr>
														<th>User</th>
														<th>Vote</th>
														<th>Power</th>
													</tr>
												</thead>
												<tbody>
													{votes &&
														(showMore || votes.length <= 10
															? votes.map((vote, index) => {
																	return (
																		<VoteItem
																			key={index}
																			address={vote.voter}
																			vote={selectedProposal.choices[vote.choice - 1]}
																			score={vote.score.toNumber()}
																			symbol={space?.symbol}
																		/>
																	);
															  })
															: votes.slice(0, 10).map((vote, index) => {
																	return (
																		<VoteItem
																			key={index}
																			address={vote.voter}
																			vote={selectedProposal.choices[vote.choice - 1]}
																			score={vote.score.toNumber()}
																			symbol={space?.symbol}
																		/>
																	);
															  }))}
												</tbody>
											</table>
											{votes ? (
												<>
													{votes.length > 10 ? (
														<div className="flex justify-center mt-6">
															<Button onClick={() => setShowMore(true)}>Show More</Button>
														</div>
													) : null}
												</>
											) : (
												<Loading />
											)}
										</div>
									</VoteCard>
								</GridColumn>
							</Grid>
						</GridColumn>
						<GridColumn width="1/3">
							<Grid>
								<GridColumn>
									<Card
										header={
											<CardHeader className="flex flex-col justify-around" withoutSubtitle>
												<BoxHeader>
													<BoxTitle>Information</BoxTitle>
												</BoxHeader>
											</CardHeader>
										}
									>
										<div className="flex flex-col justify-start">
											<ListItem title={'Strategy'}>
												<div className="flex items-center justify-end">
													{space?.strategies?.map((s, index) => {
														return (
															<div
																key={index}
																className="flex items-center"
																style={{ marginLeft: index !== 0 ? '21px' : '0px' }}
															>
																<VoteLogo id={DEFAULT_SPACE} symbolIndex={index} size={30} />
																<TokenValue>{s.params.symbol}</TokenValue>
															</div>
														);
													})}
												</div>
											</ListItem>

											<ListItem title={'Author'}>
												<div className="flex items-center">
													<ModifiedJazzicon address={selectedProposal && selectedProposal.author} />
													<AuthorLink
														href={`https://bscscan.com/address/${selectedProposal && selectedProposal.author}`}
														target={'_blank'}
														rel={'noopener noreferrer'}
													>
														{selectedProposal && selectedProposal.author.slice(0, 6)}...
														{selectedProposal && selectedProposal.author.slice(-4)}
													</AuthorLink>
												</div>
											</ListItem>

											{/* <ListItem title={'IPFS'}>
												<AuthorLink
													href={`https://ipfs.io/ipfs/${selectedProposal && selectedProposal.authorIpfsHash}`}
													target={'_blank'}
													rel={'noopener noreferrer'}
												>
													#{selectedProposal && selectedProposal.authorIpfsHash.slice(0, 7)}
												</AuthorLink>
											</ListItem> */}
											<ListItem title={'Start Date'}>
												<InfoText fontWeight={400}>
													{selectedProposal && moment(selectedProposal.start * 1e3).format('YYYY/MM/DD hh:mm')}
												</InfoText>
											</ListItem>
											<ListItem title={'End Date'}>
												<InfoText fontWeight={400}>
													{selectedProposal && moment(selectedProposal.end * 1e3).format('YYYY/MM/DD hh:mm')}
												</InfoText>
											</ListItem>
											<ListItem title={'Token'}>
												<InfoText>
													{GSPI.address.slice(0, 6)}...{GSPI.address.slice(-4)}
												</InfoText>
											</ListItem>
											<ListItem title={'Snapshot'}>
												<AuthorLink
													href={`https://bscscan.com/block/${selectedProposal && selectedProposal.snapshot}`}
													target={'_blank'}
													rel={'noopener noreferrer'}
												>
													{selectedProposal && selectedProposal.snapshot}
												</AuthorLink>
											</ListItem>
										</div>
									</Card>
								</GridColumn>
								<GridColumn>
									{result?.results?.hasOwnProperty('totalBalances') && (
										<Card
											header={
												<CardHeader className="d-flex flex-column justify-content-around" withoutSubtitle>
													<BoxHeader>
														<BoxTitle>Results</BoxTitle>
													</BoxHeader>
												</CardHeader>
											}
										>
											<div>
												{selectedProposal.choices.map((choice, i) => (
													<ProgressBar
														key={i}
														label={shorten(choice, 'choice')}
														labelId={`vote-choice-${i + 1}`}
														lowerBound={0}
														upperBound={result.results.totalVotesBalances}
														current={result.results.totalBalances?.[i] ?? 0}
														valueFormatter={(value) => `${_numeral(value)} ${shorten(space?.symbol ?? '', 'symbol')}`}
													/>
												))}
											</div>
										</Card>
									)}
								</GridColumn>
							</Grid>
						</GridColumn>
					</Grid>
				)
			)}
			{selectedProposal && (
				<Modal
					semantic
					isOpen={showModal}
					onDismiss={() => setShowModal(false)}
					aria-labelledby="confirm-proposal-vote-label"
				>
					<ModalHeader>
						<ModalTitle headingId="confirm-proposal-vote-label" headingLevel={2} onClose={() => setShowModal(false)}>
							Confirm Vote
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to vote "{selectedProposal.choices[selectedVote! - 1]}"?</p>
						<p>
							This action <strong>cannot</strong> be undone.
						</p>
						<ButtonRow>
							<Button onClick={() => setShowModal(false)}>Close</Button>
							<Button primary onClick={handleSubmit} className={'ml-3'}>
								Vote
							</Button>
						</ButtonRow>
					</ModalBody>
				</Modal>
			)}
		</Page>
	);
};

export default ProposalDetails;
