import classNames from 'classnames';
import './Badge.scss';

export type BadgeType = 'info' | 'highlighted' | 'disabled';

type BadgeProps = {
	className?: string;
	/**
	 * @default "info"
	 */
	type?: BadgeType;
};

export const Badge: React.FC<BadgeProps> = ({ className, children, type = 'info' }) => (
	<div
		className={classNames('badge', className, {
			'bg-lighterGray text-dark': type === 'info',
			'bg-brand1 text-white': type === 'highlighted',
			'bg-lighterGray text-gray': type === 'disabled',
		})}
	>
		{children}
	</div>
);
