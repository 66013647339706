import styled from 'styled-components';

const Pane = styled.div`
	background-color: ${({ theme }) => theme.elementBG};
	border-radius: 16px;
	padding: 16px;
	margin-bottom: 1rem;
`;

export default Pane;
