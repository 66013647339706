import { Redirect, Route, Switch } from 'react-router-dom';
import AMM from './pages/AMM';
import BuyCrypto from './pages/buyCrypto';
import Connect from './pages/connect';
import CreateProposal from './pages/CreateProposal';
import Governance from './pages/Governance';
import ProposalDetails from './pages/Proposal';
import { Staking } from './pages/Staking/Staking';

const Routes = () => {
	return (
		<Switch>
			<Route path={'/buy-crypto'} exact component={BuyCrypto} />
			<Route path={'/governance'} exact component={Governance} />
			<Route path={'/governance/proposal/:id'} exact component={ProposalDetails} />
			<Route path={'/governance/create'} exact component={CreateProposal} />
			<Route path={'/connect'} exact component={Connect} />
			<Route path={'/amm'} component={AMM} />
			<Route path={'/stake'} component={Staking} />
			<Redirect to={'/governance'} />
		</Switch>
	);
};

export default Routes;
