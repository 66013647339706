import styled from 'styled-components';
import AMM from '../../../../assets/images/navigation/AMM.svg';
import Bridge from '../../../../assets/images/navigation/bridge.svg';
import buyCrypto from '../../../../assets/images/navigation/buyCrypto.svg';
import Farming from '../../../../assets/images/navigation/farming.svg';
import Governance from '../../../../assets/images/navigation/governance.svg';

const Wrapper = styled.div`
	width: 56px;
	height: 56px;
	border-radius: 56px;
	background-color: var(--brand-color-1);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;

	img {
		width: 24px;
		height: 24px;
	}
`;

const Icon: React.VFC<{ type: string; alt: string }> = (props) => {
	let C = Governance;
	switch (props.type) {
		case 'governance': {
			C = Governance;
			break;
		}
		case 'farming': {
			C = Farming;
			break;
		}
		case 'amm': {
			C = AMM;
			break;
		}
		case 'bridge': {
			C = Bridge;
			break;
		}
		case 'buyCrypto': {
			C = buyCrypto;
			break;
		}
		default: {
			C = Governance;
		}
	}

	return (
		<Wrapper>
			<img src={C} alt={props.alt} />
		</Wrapper>
	);
};

export default Icon;
