import classNames from 'classnames';
import './Loading.scss';

type LoadingProps = {
	large?: boolean;
	medium?: boolean;
	small?: boolean;
	overlay?: boolean;
	pause?: boolean;
};

export const Loading: React.VFC<LoadingProps> = ({ large, medium, small, overlay, pause }) => (
	<div
		className={classNames('loading-container', {
			'loading--large': large,
			'loading--medium': medium,
			'loading--small': small,
			'loading--overlay': overlay,
			'loading--pause': pause,
		})}
	>
		<div className="loading-1" />
		<div className="loading-2" />
		{!small && !medium && <div className="loading-3" />}
	</div>
);
