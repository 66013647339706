import styled from 'styled-components';

import Base from './Base';

import Image1 from '../../assets/guides/trustWallet/1.png';
import Image2 from '../../assets/guides/trustWallet/2.png';
import Image3 from '../../assets/guides/trustWallet/3.jpeg';
import Image4 from '../../assets/guides/trustWallet/4.png';
import Image5 from '../../assets/guides/trustWallet/5.png';
import Image6 from '../../assets/guides/trustWallet/6.png';

const Content = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1rem;
`;

const Title = styled.span`
	font-size: 1rem;
	color: ${({ theme }) => theme.text1};
	margin-bottom: 1rem;
`;

const InnerContent = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	margin-left: -1rem;
	margin-bottom: 1.5rem;

	@media (max-width: 991px) {
		flex-direction: column;
	}
`;

const Img = styled.img`
	border-radius: 36px;
	margin-right: 1.5rem;

	@media (max-width: 991px) {
		max-width: 100%;
	}
`;

const Anchor = styled.a`
	color: ${({ theme }) => theme.text1};
	text-decoration: underline;
	font-weight: 300;

	&:hover {
		color: ${({ theme }) => theme.text2};
	}
`;

const ReferenceBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	min-height: 56px;
	line-height: 56px;
	background-color: ${({ theme }) => theme.elementBG};
	color: ${({ theme }) => theme.text1};
	font-weight: 700;
	font-size: 1rem;
	width: 100%;
`;

const TrustWalletGuideline = (props) => {
	return (
		<Base provider={'Trust Wallet'}>
			<Content>
				<Title>
					Please make sure you have the most recent version of the app. You can download it from{' '}
					<Anchor href={'https://trustwallet.com'}>https://trustwallet.com</Anchor>.
					<br />
					<br />
					As an example, we will try to access a BSC DApp that is also live on the Ethereum Network.
					<br />
					<br />
					Open your DApp browser, and go to this site: app.cream.finance
				</Title>
				<InnerContent>
					<Img src={Image1} style={{ zoom: '100%' }} />
					<Img src={Image2} style={{ zoom: '100%' }} />
				</InnerContent>
			</Content>
			<Content>
				<Title>
					Change the Network to Binance Smart Chain by tapping the ETH logo at the upper right-hand corner. Choose Smart
					Chain from the list of options.
				</Title>
				<InnerContent>
					<Img src={Image3} style={{ zoom: '100%' }} />
					<Img src={Image4} style={{ zoom: '100%' }} />
				</InnerContent>
			</Content>
			<Content>
				<Title>Next tap on Connect to a wallet and then choose Metamask. Your wallet is now connected.</Title>
				<InnerContent>
					<Img src={Image5} style={{ zoom: '100%' }} />
					<Img src={Image6} style={{ zoom: '100%' }} />
				</InnerContent>
			</Content>

			<ReferenceBox>
				<span className="pr-2">Source:</span>{' '}
				<Anchor
					href="https://community.trustwallet.com/t/access-binance-smart-chain-dapps-with-trust-wallet/70660"
					target={'_blank'}
					rel={'noreferrer noopener'}
				>
					Trust Wallet
				</Anchor>
			</ReferenceBox>
		</Base>
	);
};

export default TrustWalletGuideline;
