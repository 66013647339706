import React, { useCallback } from 'react';
import { useActiveWeb3React } from '../../hooks';
import useENS from '../../hooks/useENS';
import { getEtherscanLink } from '../../utils';
import { Input, InputContainer } from '../Input/Input';
import { ExternalLink } from '../Shared';

type Props = {
	id: string;
	value: string;
	onChange: (value: string) => void;
	alternativeLabel?: string;
	alternativeSecondaryLabel?: string;
	disableLabel?: boolean;
	readonly?: boolean;
	disabled?: boolean;
};

const AddressInputPanel: React.VFC<Props> = ({
	id,
	value,
	onChange,
	alternativeLabel,
	alternativeSecondaryLabel,
	disableLabel = false,
	readonly = false,
	disabled = false,
}) => {
	const { chainId } = useActiveWeb3React();

	const { address, loading, name } = useENS(value);

	const handleInput = useCallback(
		(event) => {
			const input = event.target.value;
			const withoutSpaces = input.replace(/\s+/g, '');
			onChange(withoutSpaces);
		},
		[onChange]
	);

	const error = Boolean(value.length > 0 && !loading && !address);

	return (
		<InputContainer
			htmlFor={id}
			label={alternativeLabel ?? 'Recipient'}
			secondaryLabel={
				alternativeSecondaryLabel ??
				(address && chainId && (
					<ExternalLink href={getEtherscanLink(chainId, name ?? address, 'address')} style={{ fontSize: '14px' }}>
						(View on Etherscan)
					</ExternalLink>
				))
			}
			hideLabel={disableLabel}
			disabled={disabled}
		>
			<Input
				id={id}
				className="recipient-address-input"
				type="text"
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="off"
				spellCheck="false"
				placeholder="Wallet Address or ENS name"
				error={error}
				pattern="^(0x[a-fA-F0-9]{40})$"
				onChange={handleInput}
				value={value}
				readOnly={readonly}
			/>
		</InputContainer>
	);
};

export default AddressInputPanel;
