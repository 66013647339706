import { CurrencyAmount, JSBI, Token, Trade } from '@pancakeswap-libs/sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowDown } from 'react-feather';
import AddressInputPanel from '../../components/AddressInputPanel';
import { Button, ButtonRow, LinkButton } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { AutoColumn } from '../../components/Column';
import CurrencyInputPanel from '../../components/CurrencyInputPanel';
import PageHeader from '../../components/PageHeader';
import ProgressSteps from '../../components/ProgressSteps';
import { AutoRow, RowBetween } from '../../components/Row';
import { LinkStyledButton, TYPE } from '../../components/Shared';
import { GreyCard } from '../../components/StyledCards';
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown';
import BetterTradeLink from '../../components/swap/BetterTradeLink';
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee';
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal';
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds';
import TradePrice from '../../components/swap/TradePrice';
import SyrupWarningModal from '../../components/SyrupWarningModal';
import TokenWarningModal from '../../components/TokenWarningModal';
import { BETTER_TRADE_LINK_THRESHOLD, INITIAL_ALLOWED_SLIPPAGE } from '../../constants';
import { isTradeBetter } from '../../data/V1';
import { useActiveWeb3React } from '../../hooks';
import { useCurrency } from '../../hooks/Tokens';
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback';
import { useSwapCallback } from '../../hooks/useSwapCallback';
import useTheme from '../../hooks/useTheme';
import useToggledVersion, { Version } from '../../hooks/useToggledVersion';
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback';
import { Field } from '../../states/swap/actions';
import {
	useDefaultsFromURLSearch,
	useDerivedSwapInfo,
	useSwapActionHandlers,
	useSwapState,
} from '../../states/swap/hooks';
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from '../../states/user/hooks';
import { maxAmountSpend } from '../../utils/maxAmountSpend';
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices';

const { main: Main, Black: Text } = TYPE;

const Swap = () => {
	const loadedUrlParams = useDefaultsFromURLSearch();

	// token warning stuff
	const [loadedInputCurrency, loadedOutputCurrency] = [
		useCurrency(loadedUrlParams?.inputCurrencyId),
		useCurrency(loadedUrlParams?.outputCurrencyId),
	];
	const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false);
	const [isSyrup, setIsSyrup] = useState<boolean>(false);
	const [syrupTransactionType, setSyrupTransactionType] = useState<string>('');
	const urlLoadedTokens: Token[] = useMemo(
		() => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
		[loadedInputCurrency, loadedOutputCurrency]
	);
	const handleConfirmTokenWarning = useCallback(() => {
		setDismissTokenWarning(true);
	}, []);

	const handleConfirmSyrupWarning = useCallback(() => {
		setIsSyrup(false);
		setSyrupTransactionType('');
	}, []);

	const { account } = useActiveWeb3React();
	const theme = useTheme();

	const [isExpertMode] = useExpertModeManager();

	// get custom setting values for user
	const [deadline] = useUserDeadline();
	const [allowedSlippage] = useUserSlippageTolerance();

	// swap state
	const { independentField, typedValue, recipient } = useSwapState();
	const {
		v1Trade,
		v2Trade,
		currencyBalances,
		parsedAmount,
		currencies,
		inputError: swapInputError,
	} = useDerivedSwapInfo();
	const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
		currencies[Field.INPUT],
		currencies[Field.OUTPUT],
		typedValue
	);
	const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
	//   const { address: recipientAddress } = useENSAddress(recipient)
	const toggledVersion = useToggledVersion();
	const trade = showWrap
		? undefined
		: {
				[Version.v1]: v1Trade,
				[Version.v2]: v2Trade,
		  }[toggledVersion];

	const betterTradeLinkVersion: Version | undefined =
		toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
			? Version.v1
			: toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
			? Version.v2
			: undefined;

	const parsedAmounts = showWrap
		? {
				[Field.INPUT]: parsedAmount,
				[Field.OUTPUT]: parsedAmount,
		  }
		: {
				[Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
				[Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
		  };

	const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers();
	const isValid = !swapInputError;
	const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

	const handleTypeInput = useCallback(
		(value: string) => {
			onUserInput(Field.INPUT, value);
		},
		[onUserInput]
	);
	const handleTypeOutput = useCallback(
		(value: string) => {
			onUserInput(Field.OUTPUT, value);
		},
		[onUserInput]
	);

	// modal and loading
	const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
		showConfirm: boolean;
		tradeToConfirm: Trade | undefined;
		attemptingTxn: boolean;
		swapErrorMessage: string | undefined;
		txHash: string | undefined;
	}>({
		showConfirm: false,
		tradeToConfirm: undefined,
		attemptingTxn: false,
		swapErrorMessage: undefined,
		txHash: undefined,
	});

	const formattedAmounts = {
		[independentField]: typedValue,
		[dependentField]: showWrap
			? parsedAmounts[independentField]?.toExact() ?? ''
			: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
	};

	const route = trade?.route;
	const userHasSpecifiedInputOutput = Boolean(
		currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
	);
	const noRoute = !route;

	// check whether the user has approved the router on the input token
	const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage);

	// check if user has gone through approval process, used to show two step buttons, reset on token change
	const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

	// mark when a user has submitted an approval, reset onTokenSelection for input field
	useEffect(() => {
		if (approval === ApprovalState.PENDING) {
			setApprovalSubmitted(true);
		}
	}, [approval, approvalSubmitted]);

	const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT]);
	const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput));

	// the callback to execute the swap
	const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
		trade,
		allowedSlippage,
		deadline,
		recipient
	);

	const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

	const handleSwap = useCallback(() => {
		if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
			return;
		}
		if (!swapCallback) {
			return;
		}
		setSwapState((prevState) => ({
			...prevState,
			attemptingTxn: true,
			swapErrorMessage: undefined,
			txHash: undefined,
		}));
		swapCallback()
			.then((hash) => {
				setSwapState((prevState) => ({
					...prevState,
					attemptingTxn: false,
					swapErrorMessage: undefined,
					txHash: hash,
				}));
			})
			.catch((error) => {
				setSwapState((prevState) => ({
					...prevState,
					attemptingTxn: false,
					swapErrorMessage: error.message,
					txHash: undefined,
				}));
			});
	}, [priceImpactWithoutFee, swapCallback, setSwapState]);

	// errors
	const [showInverted, setShowInverted] = useState<boolean>(false);

	// warnings on slippage
	const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

	// show approve flow when: no error on inputs, not approved or pending, or approved in current session
	// never show if price impact is above threshold in non expert mode
	const showApproveFlow =
		!swapInputError &&
		(approval === ApprovalState.NOT_APPROVED ||
			approval === ApprovalState.PENDING ||
			(approvalSubmitted && approval === ApprovalState.APPROVED)) &&
		!(priceImpactSeverity > 3 && !isExpertMode);

	const handleConfirmDismiss = useCallback(() => {
		setSwapState((prevState) => ({ ...prevState, showConfirm: false }));

		// if there was a tx hash, we want to clear the input
		if (txHash) {
			onUserInput(Field.INPUT, '');
		}
	}, [onUserInput, txHash, setSwapState]);

	const handleAcceptChanges = useCallback(() => {
		setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }));
	}, [trade]);

	// This will check to see if the user has selected Syrup to either buy or sell.
	// If so, they will be alerted with a warning message.
	const checkForSyrup = useCallback(
		(selected: string, purchaseType: string) => {
			if (selected === 'syrup') {
				setIsSyrup(true);
				setSyrupTransactionType(purchaseType);
			}
		},
		[setIsSyrup, setSyrupTransactionType]
	);

	const handleInputSelect = useCallback(
		(inputCurrency) => {
			setApprovalSubmitted(false); // reset 2 step UI for approvals
			onCurrencySelection(Field.INPUT, inputCurrency);
			if (inputCurrency.symbol.toLowerCase() === 'syrup') {
				checkForSyrup(inputCurrency.symbol.toLowerCase(), 'Selling');
			}
		},
		[onCurrencySelection, setApprovalSubmitted, checkForSyrup]
	);

	const handleMaxInput = useCallback(() => {
		if (maxAmountInput) {
			onUserInput(Field.INPUT, maxAmountInput.toExact());
		}
	}, [maxAmountInput, onUserInput]);

	const handleOutputSelect = useCallback(
		(outputCurrency) => {
			onCurrencySelection(Field.OUTPUT, outputCurrency);
			if (outputCurrency.symbol.toLowerCase() === 'syrup') {
				checkForSyrup(outputCurrency.symbol.toLowerCase(), 'Buying');
			}
		},
		[onCurrencySelection, checkForSyrup]
	);

	return (
		<>
			<TokenWarningModal
				isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
				tokens={urlLoadedTokens}
				onConfirm={handleConfirmTokenWarning}
			/>
			<SyrupWarningModal
				isOpen={isSyrup}
				transactionType={syrupTransactionType}
				onConfirm={handleConfirmSyrupWarning}
			/>
			<Card header={<PageHeader title="Swap" showTabs={true} activeTab={0} />}>
				<Wrapper id="swap-page">
					<ConfirmSwapModal
						isOpen={showConfirm}
						trade={trade}
						originalTrade={tradeToConfirm}
						onAcceptChanges={handleAcceptChanges}
						attemptingTxn={attemptingTxn}
						txHash={txHash}
						recipient={recipient}
						allowedSlippage={allowedSlippage}
						onConfirm={handleSwap}
						swapErrorMessage={swapErrorMessage}
						onDismiss={handleConfirmDismiss}
					/>
					<div>
						<AutoColumn gap="md">
							<CurrencyInputPanel
								label={independentField === Field.OUTPUT && !showWrap && trade ? 'From (estimated)' : 'From'}
								value={formattedAmounts[Field.INPUT]}
								showMaxButton={!atMaxAmountInput}
								currency={currencies[Field.INPUT]}
								onUserInput={handleTypeInput}
								onMax={handleMaxInput}
								onCurrencySelect={handleInputSelect}
								otherCurrency={currencies[Field.OUTPUT]}
								id="swap-currency-input"
							/>
							<AutoColumn justify="space-between">
								<AutoRow justify={'space-between'} style={{ padding: '0 0 0.75rem' }}>
									<ArrowWrapper clickable>
										<div
											onClick={() => {
												setApprovalSubmitted(false); // reset 2 step UI for approvals
												onSwitchTokens();
											}}
											style={{ borderRadius: '50%' }}
										>
											<ArrowDown size="16" color={theme.text1} />
										</div>
									</ArrowWrapper>
									{recipient === null && !showWrap ? (
										<LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
											+ Add a send (optional)
										</LinkStyledButton>
									) : null}
								</AutoRow>
							</AutoColumn>
							<CurrencyInputPanel
								value={formattedAmounts[Field.OUTPUT]}
								onUserInput={handleTypeOutput}
								label={independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'}
								showMaxButton={false}
								currency={currencies[Field.OUTPUT]}
								onCurrencySelect={handleOutputSelect}
								otherCurrency={currencies[Field.INPUT]}
								id="swap-currency-output"
							/>

							{recipient !== null && !showWrap ? (
								<>
									<AutoRow justify="space-between" style={{ padding: '0 0 0.75rem' }}>
										<ArrowWrapper clickable={false}>
											<ArrowDown size="16" color={theme.text1} />
										</ArrowWrapper>
										<LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
											- Remove send
										</LinkStyledButton>
									</AutoRow>
									<AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
								</>
							) : null}

							{showWrap ? null : (
								<Card>
									<AutoColumn gap="4px">
										{Boolean(trade) && (
											<RowBetween align="center">
												<Text fontSize="14px">Price</Text>
												<TradePrice
													price={trade?.executionPrice}
													showInverted={showInverted}
													setShowInverted={setShowInverted}
												/>
											</RowBetween>
										)}
										{allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
											<RowBetween align="center">
												<Text fontSize="14px">Slippage Tolerance</Text>
												<Text fontSize="14px">{allowedSlippage / 100}%</Text>
											</RowBetween>
										)}
									</AutoColumn>
								</Card>
							)}
						</AutoColumn>
						{trade && <AdvancedSwapDetailsDropdown trade={trade} />}
						<BottomGrouping>
							{!account ? (
								<LinkButton primary to={'/connect'}>
									Connect Wallet
								</LinkButton>
							) : showWrap ? (
								<Button disabled={Boolean(wrapInputError)} onClick={onWrap} primary>
									{wrapInputError ??
										(wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
								</Button>
							) : noRoute && userHasSpecifiedInputOutput ? (
								<GreyCard style={{ textAlign: 'center' }}>
									<Main mb="4px">Insufficient liquidity for this trade.</Main>
								</GreyCard>
							) : showApproveFlow ? (
								<ButtonRow>
									<Button
										onClick={approveCallback}
										disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
										primary={approval === ApprovalState.NOT_APPROVED}
										loading={{
											isLoading: approval === ApprovalState.PENDING,
											hideLabel: false,
										}}
									>
										{approval === ApprovalState.PENDING
											? 'Approving'
											: approvalSubmitted && approval === ApprovalState.APPROVED
											? 'Approved'
											: `Approve ${currencies[Field.INPUT]?.symbol}`}
									</Button>
									<Button
										onClick={() => {
											if (isExpertMode) {
												handleSwap();
											} else {
												setSwapState({
													tradeToConfirm: trade,
													attemptingTxn: false,
													swapErrorMessage: undefined,
													showConfirm: true,
													txHash: undefined,
												});
											}
										}}
										style={{ width: '48%' }}
										id="swap-button"
										disabled={
											!isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
										}
										primary
										destructive={isValid && priceImpactSeverity > 2}
									>
										{priceImpactSeverity > 3 && !isExpertMode
											? `Price Impact High`
											: `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
									</Button>
								</ButtonRow>
							) : (
								<Button
									onClick={() => {
										if (isExpertMode) {
											handleSwap();
										} else {
											setSwapState({
												tradeToConfirm: trade,
												attemptingTxn: false,
												swapErrorMessage: undefined,
												showConfirm: true,
												txHash: undefined,
											});
										}
									}}
									id="swap-button"
									disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
									primary
									destructive={isValid && priceImpactSeverity > 2 && !swapCallbackError}
								>
									{swapInputError ||
										(priceImpactSeverity > 3 && !isExpertMode
											? `Price Impact Too High`
											: `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`)}
								</Button>
							)}
							{showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
							{isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
							{betterTradeLinkVersion && <BetterTradeLink version={betterTradeLinkVersion} />}
						</BottomGrouping>
					</div>
				</Wrapper>
			</Card>
		</>
	);
};

export default Swap;
