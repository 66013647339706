import { multicall } from '../../utils';

export const author = 'vfatouros';
export const version = '0.1.0';

const tokenAbi = [
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
		],
		name: 'balanceOf',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'totalSupply',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_account',
				type: 'address',
			},
		],
		name: 'staked',
		outputs: [
			{
				internalType: 'uint256',
				name: 'ghst_',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'uniV2PoolTokens_',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
];

export async function strategy(_space, network, provider, addresses, options, snapshot) {
	const blockTag = typeof snapshot === 'number' ? snapshot : 'latest';
	const res = await multicall(
		network,
		provider,
		tokenAbi,
		[
			[options.uniswapAddress, 'totalSupply', []],
			[options.tokenAddress, 'balanceOf', [options.uniswapAddress]],
		].concat(addresses.map((address) => [options.stakingAddress, 'staked', [address]])),
		{ blockTag }
	);

	const totalSupply = res[0];
	const tokenBalanceInUni = res[1];
	const tokensPerUni = tokenBalanceInUni / 10 ** options.decimals / (totalSupply / 1e18);

	const response = res.slice(2);

	return Object.fromEntries(
		response.map((values, i) => [
			addresses[i],
			//ghst_, uniV2PoolTokens
			values[0] / 1e18 + (values[1] / 10 ** options.decimals) * tokensPerUni,
		])
	);
}
