import { transparentize } from 'polished';
import React from 'react';
import { AlertTriangle } from 'react-feather';
import styled, { css } from 'styled-components';
import { TYPE } from '../../theme';
import { AutoColumn } from '../Column';

const Text = TYPE.Black;

export const Wrapper = styled.div`
	position: relative;
`;

export const ArrowWrapper = styled.div<{ clickable: boolean }>`
	padding: 2px;

	${({ clickable }) =>
		clickable
			? css`
					:hover {
						cursor: pointer;
						opacity: 0.8;
					}
			  `
			: null}
`;

export const SectionBreak = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${({ theme }) => theme.text3};
`;

export const BottomGrouping = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 2.5rem;
	margin-bottom: 1.25rem;

	@media (max-width: 991px) {
		align-items: stretch;
		margin-bottom: 0.75rem;
	}
`;

export const ErrorText = styled(Text)<{ severity?: 0 | 1 | 2 | 3 | 4 }>`
	color: ${({ theme, severity }) =>
		severity === 3 || severity === 4
			? theme.red1
			: severity === 2
			? theme.warning
			: severity === 1
			? theme.text1
			: theme.success};
`;

export const StyledBalanceMaxMini = styled.button`
	height: 22px;
	width: 22px;
	background-color: ${({ theme }) => theme.elementBG};
	border: none;
	border-radius: 50%;
	padding: 0.2rem;
	font-size: 0.875rem;
	font-weight: 400;
	margin-left: 0.4rem;
	cursor: pointer;
	color: ${({ theme }) => theme.text1};
	display: flex;
	justify-content: center;
	align-items: center;
	float: right;

	:hover {
		background-color: ${({ theme }) => theme.modalBG};
	}
	:focus {
		background-color: ${({ theme }) => theme.modalBG};
		outline: none;
	}
`;

export const TruncatedText = styled(Text)`
	text-overflow: ellipsis;
	width: 220px;
	overflow: hidden;
`;

// styles
export const Dots = styled.span`
	&::after {
		display: inline-block;
		animation: ellipsis 1.25s infinite;
		content: '.';
		width: 1em;
		text-align: left;
	}
	@keyframes ellipsis {
		0% {
			content: '.';
		}
		33% {
			content: '..';
		}
		66% {
			content: '...';
		}
	}
`;

const SwapCallbackErrorInner = styled.div`
	background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
	border-radius: 1rem;
	display: flex;
	align-items: center;
	font-size: 0.825rem;
	width: 100%;
	padding: 3rem 1.25rem 1rem 1rem;
	margin-top: -2rem;
	color: ${({ theme }) => theme.red1};
	z-index: -1;
	p {
		padding: 0;
		margin: 0;
		font-weight: 500;
	}
`;

const SwapCallbackErrorInnerAlertTriangle = styled.div`
	background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 12px;
	border-radius: 12px;
	min-width: 48px;
	height: 48px;
`;

export function SwapCallbackError({ error }: { error: string }) {
	return (
		<SwapCallbackErrorInner>
			<SwapCallbackErrorInnerAlertTriangle>
				<AlertTriangle size={24} />
			</SwapCallbackErrorInnerAlertTriangle>
			<p>{error}</p>
		</SwapCallbackErrorInner>
	);
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
	background-color: ${({ theme }) => transparentize(0.9, theme.primary)};
	color: ${({ theme }) => theme.primary};
	padding: 0.5rem;
	border-radius: 12px;
	margin-top: 8px;
`;
