import { Pair } from '@pancakeswap-libs/sdk';
import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { LinkButton } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { AutoColumn } from '../../components/Column';
import PageHeader from '../../components/PageHeader';
import FullPositionCard from '../../components/PositionCard';
import Question from '../../components/QuestionHelper';
import { RowBetween } from '../../components/Row';
import { TYPE } from '../../components/Shared';
import { LightCard } from '../../components/StyledCards';
import { Dots } from '../../components/swap/styleds';
import { usePairs } from '../../data/Reserves';
import { useActiveWeb3React } from '../../hooks';
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../states/user/hooks';
import { useTokenBalancesWithLoadingIndicator } from '../../states/wallet/hooks';

const { body: Body, Black: Text } = TYPE;

const PageCard = styled(Card)`
	& .card-header {
		border-bottom-width: 0;
		padding: 0;
	}
`;

export default function Pool() {
	const theme = useContext(ThemeContext);
	const { account } = useActiveWeb3React();

	// fetch the user's balances of all tracked V2 LP tokens
	const trackedTokenPairs = useTrackedTokenPairs();
	const tokenPairsWithLiquidityTokens = useMemo(
		() => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
		[trackedTokenPairs]
	);
	const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
		tokenPairsWithLiquidityTokens,
	]);
	const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
		account ?? undefined,
		liquidityTokens
	);

	// fetch the reserves for all V2 pools in which the user has a balance
	const liquidityTokensWithBalances = useMemo(
		() =>
			tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
				v2PairsBalances[liquidityToken.address]?.greaterThan('0')
			),
		[tokenPairsWithLiquidityTokens, v2PairsBalances]
	);

	const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens));
	const v2IsLoading =
		fetchingV2PairBalances ||
		v2Pairs?.length < liquidityTokensWithBalances.length ||
		v2Pairs?.some((V2Pair) => !V2Pair);

	const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));

	// const hasV1Liquidity = useUserHasLiquidityInAllTokens();

	return (
		<>
			<PageCard
				header={
					<PageHeader
						title="Liquidity"
						description="Add liquidity to receive LP tokens"
						showTabs={true}
						activeTab={1}
					/>
				}
			>
				<AutoColumn gap="lg" justify="center">
					<div>
						<AutoColumn gap="12px" style={{ width: '100%' }}>
							<RowBetween padding="0 8px">
								<Text color={theme.text1}>Your Liquidity</Text>
								<Question
									text={
										'When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.'
									}
								/>
							</RowBetween>

							{!account ? (
								<LightCard padding="40px">
									<Body color={theme.text2} textAlign="center">
										Connect to a wallet to view your liquidity.
									</Body>
								</LightCard>
							) : v2IsLoading ? (
								<LightCard padding="40px">
									<Body color={theme.text2} textAlign="center">
										<Dots>Loading</Dots>
									</Body>
								</LightCard>
							) : allV2PairsWithLiquidity?.length > 0 ? (
								<>
									{allV2PairsWithLiquidity.map((v2Pair) => (
										<FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
									))}
								</>
							) : (
								<LightCard padding="40px">
									<Body color={theme.text2} textAlign="center">
										No liquidity found.
									</Body>
								</LightCard>
							)}

							<LinkButton id="join-pool-button" to="/amm/add/ETH">
								Add Liquidity
							</LinkButton>
						</AutoColumn>
					</div>
				</AutoColumn>
			</PageCard>
		</>
	);
}
