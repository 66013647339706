import _ from 'lodash';
import { multicall } from './utils';

const set = _.set;

export default class Multicaller {
	network;
	provider;
	abi;
	options = {};
	calls = [];
	paths = [];

	constructor(network, provider, abi, options) {
		this.network = network;
		this.provider = provider;
		this.abi = abi;
		this.options = options || {};
	}

	call(path, address, fn, params) {
		this.calls.push([address, fn, params]);
		this.paths.push(path);
		return this;
	}

	async execute(from) {
		const obj = from || {};
		const result = await multicall(this.network, this.provider, this.abi, this.calls, this.options);
		this.paths.forEach((path, i) => set(obj, path, result[i][0]));
		this.calls = [];
		this.paths = [];
		return obj;
	}
}
