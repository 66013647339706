import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserDeadline } from '../../states/user/hooks';
import { TYPE } from '../../theme';
import { Input } from '../Input/Input';
import QuestionHelper from '../QuestionHelper';

const Text = TYPE.Black;

const StyledTransactionDeadlineSetting = styled.div`
	margin-bottom: 16px;
`;

const Label = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 8px;
`;

const Field = styled.div`
	align-items: center;
	display: inline-flex;
`;

const TransactionDeadlineSetting = () => {
	const [deadline, setDeadline] = useUserDeadline();
	const [value, setValue] = useState(deadline / 60); // deadline in minutes
	const [error, setError] = useState<string | null>(null);

	const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { value: inputValue } = evt.target;
		setValue(parseInt(inputValue, 10));
	};

	// Updates local storage if value is valid
	useEffect(() => {
		try {
			const rawValue = value * 60;
			if (!Number.isNaN(rawValue) && rawValue > 0) {
				setDeadline(rawValue);
				setError(null);
			} else {
				setError('Enter a valid deadline');
			}
		} catch {
			setError('Enter a valid deadline');
		}
	}, [value, setError, setDeadline]);

	return (
		<StyledTransactionDeadlineSetting>
			<Label>
				<Text style={{ fontWeight: 600 }}>Transaction deadline</Text>
				<QuestionHelper text="Your transaction will revert if it is pending for more than this long." />
			</Label>
			<Field>
				<Input type="number" step="1" min="1" value={value} onChange={handleChange} />
				<Text ml={'10px'}>Minutes</Text>
			</Field>
			{error && <Text color="red1">{error}</Text>}
		</StyledTransactionDeadlineSetting>
	);
};

export default TransactionDeadlineSetting;
