export type StakeParamType =
	| 'string'
	| 'uint8'
	| 'uint32'
	| 'uint64'
	| 'uint128'
	| 'int256'
	| 'uint256'
	| 'uint256[3][]'
	| 'address'
	| 'bool'
	| 'bytes';

export type StakeParam = {
	internalType: StakeParamType;
	name: string;
	type: StakeParamType;
};

export type Stake =
	| {
			type: 'constructor';
			stateMutability: 'nonpayable' | 'view';
			inputs: StakeParam[];
			payable: boolean;
	  }
	| {
			type: 'function';
			stateMutability: 'nonpayable' | 'view';
			name: string;
			constant: boolean;
			payable: boolean;
			inputs: StakeParam[];
			outputs: StakeParam[];
	  };

export const stakeABI: Stake[] = [
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: 'id',
				type: 'uint256',
			},
			{
				internalType: 'uint128',
				name: 'unlockEpoch',
				type: 'uint128',
			},
			{
				internalType: 'uint128',
				name: 'stakeBonus',
				type: 'uint128',
			},
		],
		name: 'addStakingOption',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'distributeDivs',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: 'player',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				internalType: 'bytes',
				name: 'data',
				type: 'bytes',
			},
		],
		name: 'stake',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: 'index',
				type: 'uint256',
			},
		],
		name: 'unstake',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [],
		name: 'withdrawEarnings',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'bytes',
				name: 'b',
				type: 'bytes',
			},
		],
		name: 'bytesToUint',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: 'customerAddress',
				type: 'address',
			},
		],
		name: 'dividendsOf',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: 'player',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'startIndex',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'endIndex',
				type: 'uint256',
			},
		],
		name: 'getPlayersFreezings',
		outputs: [
			{
				internalType: 'uint256[3][]',
				name: '',
				type: 'uint256[3][]',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
		],
		name: 'payoutsTo',
		outputs: [
			{
				internalType: 'int256',
				name: '',
				type: 'int256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		name: 'playersFreezes',
		outputs: [
			{
				internalType: 'uint128',
				name: 'amount',
				type: 'uint128',
			},
			{
				internalType: 'uint64',
				name: 'unlockEpoch',
				type: 'uint64',
			},
			{
				internalType: 'uint32',
				name: 'stakeBonus',
				type: 'uint32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
		],
		name: 'playersStakePower',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'profitPerShare',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		name: 'stakingBonus',
		outputs: [
			{
				internalType: 'uint128',
				name: 'unlockEpoch',
				type: 'uint128',
			},
			{
				internalType: 'uint128',
				name: 'stakeBonus',
				type: 'uint128',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'totalPowerStaked',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'totalStakePower',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
];
