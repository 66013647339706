import classNames from 'classnames';
import React, { PropsWithChildren, ReactElement, useCallback, useMemo, useRef } from 'react';
import './SegmentedControls.scss';

type Value = string | number | boolean | null | undefined;

enum Key {
	TAB = 'Tab',
	SPACE = ' ',
	LEFT = 'ArrowLeft',
	UP = 'ArrowUp',
	RIGHT = 'ArrowRight',
	DOWN = 'ArrowDown',
}

type SegmentedControlValue<T extends Value> = {
	id: string;
	label: string;
	value: T;
};

type SegmentedControlsProps<T extends Value> = {
	className?: string;
	options: SegmentedControlValue<T>[];
	onChange: (newValue: T) => void;
	active: T;
	vertical?: boolean;
	labelId: string;
	disabled?: boolean;
};

export const SegmentedControls = <T extends Value>({
	className,
	options,
	onChange,
	active,
	children,
	vertical,
	labelId,
	disabled,
}: PropsWithChildren<SegmentedControlsProps<T>>): ReactElement | null => {
	const containerRef = useRef<HTMLUListElement | null>(null);

	const { currentItem, currentItemIndex, currentItemIndexSafe } = useMemo(() => {
		const currentItemIndex = options.findIndex((option) => option.value === active);
		const currentItemIndexSafe = currentItemIndex < 0 ? undefined : currentItemIndex;
		const currentItem = currentItemIndex > -1 ? options[currentItemIndex] : undefined;

		return {
			currentItemIndex,
			currentItemIndexSafe,
			currentItem,
		};
	}, [options, active]);

	const handleChange = useCallback(
		(index: number) => {
			const option = options[index];

			if (option && containerRef.current) {
				onChange(option.value);
				containerRef.current.focus();
			}
		},
		[onChange, options]
	);

	const handleChangePrevious = useCallback(() => {
		if (currentItemIndex < 1) {
			handleChange(options.length - 1);
		} else {
			handleChange(currentItemIndex - 1);
		}
	}, [currentItemIndex, handleChange, options.length]);

	const handleChangeNext = useCallback(() => {
		if (currentItemIndex >= options.length - 1) {
			handleChange(0);
		} else {
			handleChange(currentItemIndex + 1);
		}
	}, [currentItemIndex, handleChange, options.length]);

	const handleKeydown = useCallback(
		(event: React.KeyboardEvent<HTMLUListElement>) => {
			switch (event.key) {
				case Key.SPACE:
					handleChange(currentItemIndexSafe ?? 0);
					event.stopPropagation();
					event.preventDefault();
					break;

				case Key.UP:
					handleChangePrevious();
					event.stopPropagation();
					event.preventDefault();
					break;

				case Key.DOWN:
					handleChangeNext();
					event.stopPropagation();
					event.preventDefault();
					break;

				case Key.LEFT:
					handleChangePrevious();
					event.stopPropagation();
					event.preventDefault();
					break;

				case Key.RIGHT:
					handleChangeNext();
					event.stopPropagation();
					event.preventDefault();
					break;

				default:
					break;
			}
		},
		[currentItemIndexSafe, handleChange, handleChangeNext, handleChangePrevious]
	);

	return (
		<ul
			ref={containerRef}
			className={classNames('segmented-controls', className, {
				'segmented-controls--vertical': vertical,
				'segmented-controls--equal': !children,
			})}
			role="radiogroup"
			aria-labelledby={labelId}
			aria-activedescendant={currentItem?.id}
			aria-disabled={disabled ?? false}
			tabIndex={0}
			onKeyDown={handleKeydown}
		>
			{options.map((option) => (
				<Segment key={option.id} option={option} active={active} onChange={onChange} />
			))}
			{children}
		</ul>
	);
};

type SegmentProps<T extends Value> = {
	option: SegmentedControlValue<T>;
	onChange: (newValue: T) => void;
	active: T;
	disabled?: boolean;
};

const Segment = <T extends Value>({
	option: { id, value, label },
	onChange,
	active,
	disabled,
}: SegmentProps<T>): ReactElement | null => {
	const handleChange = useCallback(() => !disabled && onChange(value), [disabled, value, onChange]);

	return (
		<li id={id} className="segment" onClick={handleChange} role="radio" aria-checked={active === value}>
			{label}
		</li>
	);
};
