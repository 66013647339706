import { Trade } from '@pancakeswap-libs/sdk';
import React, { Fragment, memo, useContext } from 'react';
import { ChevronRight } from 'react-feather';
import styled, { ThemeContext } from 'styled-components';
import { TYPE } from '../../theme';
import CurrencyLogo from '../CurrencyLogo';

const { Black } = TYPE;

const Flex = styled.div`
	display: flex;
`;

export default memo(function SwapRoute({ trade }: { trade: Trade }) {
	const theme = useContext(ThemeContext);
	return (
		<Flex style={{ border: `1px solid ${theme.text3}`, borderRadius: '1rem' }}>
			{trade.route.path.map((token, i, path) => {
				const isLastItem: boolean = i === path.length - 1;
				return (
					// eslint-disable-next-line react/no-array-index-key
					<Fragment key={i}>
						<Flex style={{ flexShrink: 0 }}>
							<CurrencyLogo currency={token} size="1.5rem" />
							<Black fontSize={14} color={theme.text1} ml="0.5rem">
								{token.symbol}
							</Black>
						</Flex>
						{isLastItem ? null : <ChevronRight color={theme.text1} />}
					</Fragment>
				);
			})}
		</Flex>
	);
});
