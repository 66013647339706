import { formatUnits } from '@ethersproject/units';
import { multicall, MULTICALL } from '../../utils';
import { abi } from '../../../constants/abis/Multicall.json';

export const author = 'bonustrack';
export const version = '0.1.0';

export async function strategy(space, network, provider, addresses, options, snapshot) {
	const blockTag = typeof snapshot === 'number' ? snapshot : 'latest';
	const response = await multicall(
		network,
		provider,
		abi,
		addresses.map((address) => [MULTICALL[network], 'getEthBalance', [address]]),
		{ blockTag }
	);
	return Object.fromEntries(response.map((value, i) => [addresses[i], parseFloat(formatUnits(value.toString(), 18))]));
}
