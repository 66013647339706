import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const PageContainer = styled.div`
	padding-bottom: 3rem;

	@media (max-width: 991px) {
		padding-bottom: 0;
	}
`;

const Title = styled.h1.attrs({ className: 'text-5xl' })`
	margin-top: 0;
	font-size: 2.25rem;
	font-weight: 700;
	margin-bottom: 2rem;
	color: ${({ theme }) => theme.text1};

	@media (max-width: 991px) {
		font-size: 1.75rem;
		margin-bottom: 1.5rem;
	}
`;

const Breadcrumb = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
`;

const Separator = styled.span`
	font-size: 0.875rem;
	color: ${({ theme }) => theme.text1};
	padding-right: 12px;

	@media (max-width: 991px) {
		font-size: 0.75rem;
		padding-right: 10px;
	}
`;

const BreadcrumbItem = styled(Link)`
	font-size: 0.875rem;
	color: ${({ theme }) => theme.text1};
	padding-right: 12px;

	@media (max-width: 991px) {
		font-size: 0.75rem;
		padding-right: 10px;
	}
`;

const LastBreadcrumb = styled.span`
	font-size: 0.875rem;
	color: ${({ theme }) => theme.text3};

	@media (max-width: 991px) {
		font-size: 0.75rem;
		padding-right: 10px;
	}
`;

const Spacer = styled.div`
	padding: 50px 0;

	@media (max-width: 991px) {
		padding: 20px 0;
	}
`;

const Content = styled.div.attrs({ className: 'container xl:px-4' })<{ restrictWidth?: boolean }>`
	max-width: ${({ restrictWidth }) => (restrictWidth ? '600px' : 'unset')};
`;

const Page: React.FC<{
	[x: string]: any;
	title?: string;
	breadcrumb?: { title: string; url?: string }[];
	restrictWidth?: boolean;
}> = ({ title, breadcrumb, restrictWidth, ...props }) => {
	useEffect(() => {
		document.body.scrollTo(0, 0);
	}, []);

	return (
		<PageContainer>
			<Content restrictWidth={restrictWidth}>
				{title && <Title>{title}</Title>}
				{breadcrumb && (
					<Breadcrumb>
						{breadcrumb.map((item, _i) => {
							if (_i === breadcrumb.length - 1) {
								return <LastBreadcrumb key="LAST">{item.title}</LastBreadcrumb>;
							} else if (item.url) {
								return (
									<Fragment key={item.url}>
										<BreadcrumbItem to={item.url}>{item.title}</BreadcrumbItem>
										<Separator>/</Separator>
									</Fragment>
								);
							}

							return null
						})}
					</Breadcrumb>
				)}
				{!title && !breadcrumb && <Spacer />}
				{props.children}
			</Content>
		</PageContainer>
	);
};

export default Page;
