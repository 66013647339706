import React from 'react';
import { useActiveWeb3React } from '../../hooks';
import { Modal } from '../Modal';
import ConfirmationPendingContent from './ConfirmationPendingContent';
import TransactionSubmittedContent from './TransactionSubmittedContent';

interface ConfirmationModalProps {
	isOpen: boolean;
	onDismiss: () => void;
	hash: string | undefined;
	content: () => React.ReactNode;
	attemptingTxn: boolean;
	pendingText: string;
}

const TransactionConfirmationModal = ({
	isOpen,
	onDismiss,
	attemptingTxn,
	hash,
	pendingText,
	content,
}: ConfirmationModalProps) => {
	const { chainId } = useActiveWeb3React();

	if (!chainId) return null;

	// confirmation screen
	return (
		<Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90} aria-labelledby="" semantic>
			{attemptingTxn ? (
				<ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
			) : hash ? (
				<TransactionSubmittedContent chainId={chainId} hash={hash} onDismiss={onDismiss} />
			) : (
				content()
			)}
		</Modal>
	);
};

export default TransactionConfirmationModal;
