import { Trade, TradeType } from '@pancakeswap-libs/sdk';
import React, { useMemo, useState } from 'react';
import { Repeat } from 'react-feather';
import { Field } from '../../states/swap/actions';
import { TYPE } from '../../theme';
import {
	computeSlippageAdjustedAmounts,
	computeTradePriceBreakdown,
	formatExecutionPrice,
	warningSeverity,
} from '../../utils/prices';
import { Button } from '../Button/Button';
import { AutoColumn } from '../Column';
import QuestionHelper from '../QuestionHelper';
import { AutoRow, RowBetween, RowFixed } from '../Row';
import FormattedPriceImpact from './FormattedPriceImpact';
import { StyledBalanceMaxMini, SwapCallbackError } from './styleds';

const Text = TYPE.Black;

export default function SwapModalFooter({
	trade,
	onConfirm,
	allowedSlippage,
	swapErrorMessage,
	disabledConfirm,
}: {
	trade: Trade;
	allowedSlippage: number;
	onConfirm: () => void;
	swapErrorMessage: string | undefined;
	disabledConfirm: boolean;
}) {
	const [showInverted, setShowInverted] = useState<boolean>(false);
	const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
		allowedSlippage,
		trade,
	]);
	const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade]);
	const severity = warningSeverity(priceImpactWithoutFee);

	return (
		<>
			<AutoColumn gap="0px">
				<RowBetween align="center">
					<Text fontSize="14px">Price</Text>
					<Text
						fontSize="14px"
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							textAlign: 'right',
							paddingLeft: '8px',
							fontWeight: 500,
						}}
					>
						{formatExecutionPrice(trade, showInverted)}
						<StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
							<Repeat size={14} />
						</StyledBalanceMaxMini>
					</Text>
				</RowBetween>

				<RowBetween>
					<RowFixed>
						<Text fontSize="14px">
							{trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
						</Text>
						<QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
					</RowFixed>
					<RowFixed>
						<Text fontSize="14px">
							{trade.tradeType === TradeType.EXACT_INPUT
								? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
								: slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
						</Text>
						<Text fontSize="14px" marginLeft="4px">
							{trade.tradeType === TradeType.EXACT_INPUT
								? trade.outputAmount.currency.symbol
								: trade.inputAmount.currency.symbol}
						</Text>
					</RowFixed>
				</RowBetween>
				<RowBetween>
					<RowFixed>
						<Text fontSize="14px">Price Impact</Text>
						<QuestionHelper text="The difference between the market price and your price due to trade size." />
					</RowFixed>
					<FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
				</RowBetween>
				<RowBetween>
					<RowFixed>
						<Text fontSize="14px">Liquidity Provider Fee</Text>
						<QuestionHelper text="For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers and 0.03% goes to the PancakeSwap treasury." />
					</RowFixed>
					<Text fontSize="14px">
						{realizedLPFee ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}` : '-'}
					</Text>
				</RowBetween>
			</AutoColumn>

			<AutoRow>
				<Button
					onClick={onConfirm}
					disabled={disabledConfirm}
					primary
					destructive={severity > 2}
					id="confirm-swap-or-send"
				>
					{severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
				</Button>

				{swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
			</AutoRow>
		</>
	);
}
