import { createAction } from '@reduxjs/toolkit';
import Governance from '../../http/Governance';
import * as gqlApi from '../../http/GQLGovernance';
import { formatProposals } from './hooks';

const api = new Governance();

export const setLoading = createAction('governance/loading', (value) => {
	return {
		payload: {
			value,
		},
	};
});

export const setSpaces = createAction('governance/spaces', (spaces) => {
	return {
		payload: {
			spaces,
		},
	};
});

export const setProposals = createAction('governance/proposals', (proposals) => {
	return {
		payload: {
			proposals,
		},
	};
});

export const fetchSpaces = () => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		try {
			const res = await api.get('spaces');
			dispatch(setSpaces(res));
			dispatch(setLoading(false));
		} catch (error) {
			dispatch(setLoading(false));
		}
	};
};

export const fetchProposals = (id) => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		try {
			let proposals = await api.get('proposals', { id });
			dispatch(setProposals(formatProposals(proposals), id));
			dispatch(setLoading(false));
		} catch (error) {
			dispatch(setLoading(false));
		}
	};
};

export const getSpaces = () => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		await gqlApi
			.getSpaces()
			.then((spaces) => {
				dispatch(setSpaces(spaces));
			})
			.finally(() => dispatch(setLoading(false)));
	};
};

export const getProposals = (spaceId) => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		await gqlApi
			.getProposals(spaceId)
			.then((proposals) => {
				dispatch(setProposals(proposals));
			})
			.finally(() => dispatch(setLoading(false)));
	};
};

export const getVotes = (proposalId) => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		await gqlApi
			.getVotes(proposalId)
			.then((votes) => {})
			.finally(() => dispatch(setLoading(false)));
	};
};
