import styled from 'styled-components';

import Base from './Base';

import Image1 from '../../assets/guides/ledger/1.png';
import Image2 from '../../assets/guides/ledger/2.png';
import Image3 from '../../assets/guides/ledger/3.png';
import Image4 from '../../assets/guides/ledger/4.png';
import Image5 from '../../assets/guides/ledger/5.png';
import Image6 from '../../assets/guides/ledger/6.png';
import Image7 from '../../assets/guides/ledger/7.png';
import Image8 from '../../assets/guides/ledger/8.png';
import Image9 from '../../assets/guides/ledger/9.png';

const Ol = styled.ol`
	padding-left: 1rem;
`;

const UL = styled.ul`
	padding-left: 2rem;
	list-style: ${({ listStyle }) => listStyle || 'disc'};
`;

const Li = styled.li`
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1rem;
`;

const Title = styled.span`
	font-size: 1rem;
	color: ${({ theme }) => theme.text1};
	margin-bottom: 1rem;
`;

const InnerContent = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	margin-left: -1rem;
	margin-bottom: 1.5rem;
`;

const Img = styled.img`
	border-radius: 36px;

	@media (max-width: 991px) {
		max-width: 100%;
	}
`;

const InnerTitle = styled.span`
	margin-bottom: 0.75rem;
	display: block;
`;

const OuterLi = styled.li`
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
`;

const InnerLi = styled.li`
	padding: 0.5rem 0;
`;

const Anchor = styled.a`
	color: ${({ theme }) => theme.text1};
	text-decoration: underline;
	font-weight: 300;

	&:hover {
		color: ${({ theme }) => theme.text2};
	}
`;

const ReferenceBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	min-height: 56px;
	line-height: 56px;
	background-color: ${({ theme }) => theme.elementBG};
	color: ${({ theme }) => theme.text1};
	font-weight: 700;
	font-size: 1rem;
	width: 100%;
`;

const PageTitle = styled.h4`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${({ theme }) => theme.text1};
	margin-top: 1rem;
	margin-bottom: 1.25rem;
	margin-right: 1rem;
`;

const LedgerGuideline = (props) => {
	return (
		<Base provider={'Ledger'}>
			<PageTitle>Requirements</PageTitle>
			<InnerContent>
				<UL>
					<OuterLi>A Ledger Nano S hardware wallet with latest firmware.</OuterLi>
					<OuterLi>Latest version of Ethereum app installed</OuterLi>
					<OuterLi>An internet connection and an available USB port. Use an adapter for USB-C ports</OuterLi>
					<OuterLi>U2F compatible browsers: Google Chrome, Opera, FireFox, Brave</OuterLi>
				</UL>
			</InnerContent>

			<PageTitle>App Installation Instructions</PageTitle>
			<Ol>
				<Li>
					<Content>
						<Title>
							Plug in and unlock your Ledger device, open Ledger Live on your computer, then open the "Manager" panel.
						</Title>

						<InnerContent>
							<Img src={Image1} alt="install app" style={{ zoom: '50%' }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>
							Within the "Manager" pane, type in "Ethereum" in the search field.Locate "Binance Chain", then click on
							"Install".
						</Title>

						<InnerContent>
							<Img src={Image2} alt="app manager" style={{ zoom: '50%' }} />
						</InnerContent>
						<InnerContent>
							<Img src={Image3} alt="find ethereum app" style={{ zoom: '50%' }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>The Ethereum app will now install on your Ledger device.</Title>

						<InnerContent>
							<Img src={Image4} alt="app installed" style={{ zoom: '50%' }} />
						</InnerContent>
					</Content>
				</Li>
			</Ol>
			<PageTitle>Enabling contract data for BEP20</PageTitle>
			<Content>
				<Title>
					If you want to send BEP-20 tokens, you need to activate contract data on your device. Otherwise, invalid
					status 6a80 is returned. <br />
					Steps:{' '}
				</Title>

				<InnerContent>
					<UL>
						<OuterLi>Connect and unlock your Ledger device.</OuterLi>
						<OuterLi>Open the Ethereum application.</OuterLi>
						<OuterLi>Press the right button to navigate to Settings. Then press both buttons to validate.</OuterLi>
						<OuterLi>U2F compatible browsers: Google Chrome, Opera, FireFox, Brave</OuterLi>
					</UL>
				</InnerContent>
			</Content>

			<PageTitle>Connect with MetaMask</PageTitle>
			<Content>
				<Title>
					Binance Smart Chain is compatible with MetaMask. Users can use MetaMask as a bridge between Ledger Nano S and
					Binance Smart Chain to make transactions to the Binance Smart Chain.
				</Title>
			</Content>
			<Ol>
				<Li>
					<Content>
						<Title>Connect MetaMask to Binance Smart Chain by following this guide to add a custom network</Title>
						<InnerContent>
							<UL>
								<OuterLi>
									<InnerTitle>MainNet</InnerTitle>
									<UL listStyle={'circle'}>
										<InnerLi>
											<Anchor
												href="https://docs.binance.org/smart-chain/developer/rpc.html"
												target={'_blank'}
												rel={'noreferrer noopener'}
											>
												RPC URLs
											</Anchor>
										</InnerLi>
										<InnerLi>ChainID: 0x38, 56 in decimal (if 56 doesn’t work, try 0x38)</InnerLi>
										<InnerLi>Symbol: BNB</InnerLi>
										<InnerLi>Block Explorer: https://bscscan.com</InnerLi>
									</UL>
								</OuterLi>
							</UL>
						</InnerContent>
					</Content>
				</Li>

				<Li>
					<Content>
						<Title>Unlock your Metamask</Title>

						<InnerContent>
							<Img src={Image5} alt="unlock metamask" style={{ zoom: '75%' }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>Click on “Connect Hardware Wallet”</Title>

						<InnerContent>
							<Img src={Image6} alt="connect hardware wallet" style={{ zoom: '75%' }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>Choose “Ledger”</Title>

						<InnerContent>
							<Img src={Image7} alt="choose ledger" style={{ zoom: '75%' }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>Choose your address</Title>

						<InnerContent>
							<Img src={Image8} alt="check address" style={{ zoom: '50%' }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>You should see a new account</Title>

						<InnerContent>
							<Img src={Image9} alt="check address" style={{ zoom: '50%' }} />
						</InnerContent>
					</Content>
				</Li>
			</Ol>
			<ReferenceBox>
				<span className="pr-2">Source:</span>{' '}
				<Anchor
					href="https://docs.binance.org/smart-chain/wallet/ledger.html"
					target={'_blank'}
					rel={'noreferrer noopener'}
				>
					Binance Smart chain Docs
				</Anchor>
			</ReferenceBox>
		</Base>
	);
};

export default LedgerGuideline;
