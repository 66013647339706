import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { Label } from '../Label/Label';
import './Input.scss';

type SpecialTextFieldProps = {
	error?: boolean;
};

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & SpecialTextFieldProps;

export const Input = forwardRef(function Input(
	{ error, className, children, type = 'text', ...props }: InputProps,
	ref: React.ForwardedRef<HTMLDivElement>
) {
	return (
		<div ref={ref} className={classNames(className, 'input-wrapper', { 'input--error': error })}>
			<input {...props} type={type} />
			<div className="input-wrapper-context">{children}</div>
		</div>
	);
});

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & SpecialTextFieldProps;

export const TextArea: React.FC<TextAreaProps> = ({ error, className, children, ...props }) => (
	<div className={classNames(className, 'input-wrapper', { 'input--error': error })}>
		<textarea {...props} />
		<div className="input-wrapper-context">{children}</div>
	</div>
);

type InputContainerProps = {
	className?: string;
	label?: React.ReactNode;
	secondaryLabel?: React.ReactNode;
	mobileControls?: React.ReactNode;
	htmlFor?: string;
	small?: boolean;
	hideLabel?: boolean;
	disabled?: boolean;
};

export const InputContainer: React.FC<InputContainerProps> = ({
	className,
	label,
	htmlFor,
	small,
	secondaryLabel,
	mobileControls,
	hideLabel,
	children,
	disabled,
}) => (
	<div
		className={classNames(
			'input-container',
			{ 'input-container--small': small, 'input-container--disabled': disabled },
			className
		)}
	>
		{label != null ? (
			<>
				<Label
					className={classNames('input-container-above', { 'visually-hidden': hideLabel })}
					text={label}
					secondaryText={
						(secondaryLabel || mobileControls) && (
							<>
								{secondaryLabel}
								{mobileControls && <div className="input-container-mobile-controls">{mobileControls}</div>}
							</>
						)
					}
					htmlFor={htmlFor}
				/>
				{mobileControls && (
					<div className="input-container-above label-row input-container-above--controls-only">
						<div className="input-container-mobile-controls">{mobileControls}</div>
					</div>
				)}
			</>
		) : null}
		<div className="input-container-main">
			{React.Children.map(children, (child) =>
				React.isValidElement(child) ? React.cloneElement(child, { disabled: disabled }) : child
			)}
		</div>
	</div>
);

type InputContainerLabelProps = {
	className?: string;
	label: React.ReactNode;
};

export const InputContainerLabel: React.FC<InputContainerLabelProps> = ({ className, label, children }) => (
	<label className={classNames('input-container', className)}>
		<div className="input-container-above">
			<div className="input-label">{label}</div>
		</div>
		<div className="input-container-main">{children}</div>
	</label>
);
