import React, { useMemo } from 'react';
import { Text, TextProps } from 'rebass';
import styled, {
	createGlobalStyle,
	css,
	DefaultTheme,
	ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import { useIsDarkMode } from '../states/preferences/hooks';
import './index.scss';
import { Colors } from './styled';

const MEDIA_WIDTHS = {
	upToExtraSmall: 576,
	upToSmall: 768,
	upToMedium: 992,
	upToLarge: 1200,
};

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
	(accumulator, size) => {
		(accumulator as any)[size] = (a: any, b: any, c: any) => css`
			@media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
				${css(a, b, c)}
			}
		`;
		return accumulator;
	},
	{}
) as any;

const white = '#FFFFFF';
const black = '#000000';

export function colors(darkMode?: boolean): Colors {
	return {
		// base
		white,
		black,

		// Text
		brand1: '#0296a6',
		brand1Fade: '#006570',

		// Text
		text1: '#131E2E',
		text2: '#5F6A7A',
		text3: '#B3BAC3',
		text4: '#DEE0E3',

		bg1: 'rgba(149, 47, 136, 0.25)',
		bg2: 'rgba(167, 50, 152, 0.22)',
		bg3: 'rgba(69, 56, 152, 0.28)',
		bg4: 'rgba(69, 56, 152, 0.26)',

		secondBG: '#F3F5F8',
		transparentBG: 'rgba(255, 255, 255, 0.06)',
		transparentDarkBG: 'rgba(16, 16, 36, 0.03)',
		bodyBG: '#F3F5F8',
		bodyColor: '#F3F5F8',
		sidebarBG: 'linear-gradient(164.33deg, #290770 4.39%, #CEB3FB 110.62%)',
		sidebarBorder: 'transparent',
		sidebarActiveBG: 'linear-gradient(90deg, rgba(255, 255, 255, 0.38) -281.12%, rgba(255, 255, 255, 0) 159.91%)',
		elementBG: '#FFF',
		primaryGrad: 'linear-gradient(57.78deg, #76BEAB 27.5%, #131E2E 72.65%)',
		modalBG: 'rgba(255, 255, 255, 1)',
		popUpBG: '#F3F5F8',
		sideDrawerBG: 'linear-gradient(164.33deg, #B3BAC3 4.39%, #FFF 110.62%)',
		footerBG: '#12171D',
		heroBG: 'linear-gradient(125.51deg, #76BEAB 29.18%, #489983 86.53%)',

		switchKey: 'rgba(93, 90, 255, .5)',
		switchBG: 'linear-gradient(90deg, rgba(255, 255, 255, 0.38) -281.12%, rgba(255, 255, 255, 0) 159.91%)',
		switchText: 'rgba(255, 255, 255, 0.5)',

		sidebarBorderWidth: '0',

		// other
		red1: '#D55555',
		green1: '#4AC8AA',
		yellow1: '#F3BA2F',
		blue1: '#87dce1',

		danger: '#D55555',
		dangerLight: 'rgba(235, 107, 107, 0.15)',
		warning: '#F3BA2F',
		success: '#2AB0B0',
		primary: '#0296A6',
		primaryLight: 'rgba(118, 190, 171, .15)',
		secondary: '#131E2E',
		secondaryLight: '#131E2E',
		tertiary: '#131E2E',
	};
}

export function theme(darkMode?: boolean): DefaultTheme {
	return {
		...colors(darkMode),

		grids: {
			sm: 8,
			md: 12,
			lg: 24,
		},

		//shadows
		shadow1: '#87dce1',

		// media queries
		mediaWidth: mediaWidthTemplates,

		// css snippets
		flexColumnNoWrap: css`
			display: flex;
			flex-flow: column nowrap;
		`,
		flexRowNoWrap: css`
			display: flex;
			flex-flow: row nowrap;
		`,
	};
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
	const darkMode = useIsDarkMode();

	const themeObject = useMemo(() => theme(darkMode), [darkMode]);

	return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>;
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
	color: ${({ color, theme }) => (theme as any)[color]};
`;

export const TYPE = {
	main(props: TextProps) {
		return <TextWrapper fontWeight={500} color={'text2'} {...props} />;
	},
	link(props: TextProps) {
		return <TextWrapper fontWeight={500} color={'primary'} {...props} />;
	},
	Black(props: TextProps) {
		return <TextWrapper fontWeight={500} color={'text1'} {...props} />;
	},
	white(props: TextProps) {
		return <TextWrapper fontWeight={500} color={'white'} {...props} />;
	},
	body(props: TextProps) {
		return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />;
	},
	largeHeader(props: TextProps) {
		return <TextWrapper fontWeight={600} fontSize={24} {...props} />;
	},
	mediumHeader(props: TextProps) {
		return <TextWrapper fontWeight={500} fontSize={20} {...props} />;
	},
	subHeader(props: TextProps) {
		return <TextWrapper fontWeight={400} fontSize={14} {...props} />;
	},
	small(props: TextProps) {
		return <TextWrapper fontWeight={500} fontSize={11} {...props} />;
	},
	blue(props: TextProps) {
		return <TextWrapper fontWeight={500} color={'primary'} {...props} />;
	},
	yellow(props: TextProps) {
		return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />;
	},
	darkGray(props: TextProps) {
		return <TextWrapper fontWeight={500} color={'text3'} {...props} />;
	},
	gray(props: TextProps) {
		return <TextWrapper fontWeight={500} color={'bg3'} {...props} />;
	},
	italic(props: TextProps) {
		return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />;
	},
	error({ error, ...props }: { error: boolean } & TextProps) {
		return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />;
	},
};

export const FixedGlobalStyle = createGlobalStyle`

html, body { font-family: "Circular", "system-ui" !important; }

/* @supports (font-variation-settings: normal) {
  html, body { font-family: "Inter var", "system-ui" !important; }
} */

html,body {
  min-height: 100vh;
}

.modal-scrollbar-measure{position:absolute;top:-9999px;width:50px;height:50px;overflow:scroll}
`;

export const ThemedGlobalStyle = createGlobalStyle`
	.react-loading-skeleton {
		background-color: #f4f5f6 !important;
		background-image: linear-gradient(
			90deg,
			'rgba(255, 255, 255, 0)',
			${({ theme }) => theme.text4},
			'rgba(255, 255, 255, 0)'
		) !important;
	}

	html,body {
		color: ${({ theme }) => theme.text1};
	}

	body {
		background: ${({ theme }) => theme.bodyBG};
		color: ${({ theme }) => theme.text1};
	}

	.modal-backdrop {
		background: ${({ theme }) => theme.text2};
	}

	@media (max-width: 991px) {
		body {
			background: ${({ theme }) => theme.bodyColor};
		}
	}
`;
