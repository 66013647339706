import React from 'react';
import LedgerGuideline from './Ledger';
import MetamaskGuideline from './Metamask';
import TrezorGuideline from './Trezor';
import TrustWalletGuideline from './TrustWallet';

const guidelines: Record<'metamask' | 'trezor' | 'ledger' | 'trustWallet', React.VFC<any>> = {
	metamask: MetamaskGuideline,
	trezor: TrezorGuideline,
	ledger: LedgerGuideline,
	trustWallet: TrustWalletGuideline,
};

export default guidelines;
