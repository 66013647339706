import React from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from './components/Global/Layout/Layout';
import Web3ReactManager from './components/Web3ReactManager';
import useTheme from './hooks/useTheme';
import Routes from './Routes';
import ApplicationUpdater from './states/application/updater';
import ListsUpdater from './states/lists/updater';
import MulticallUpdater from './states/multicall/updater';
import TransactionUpdater from './states/transactions/updater';
import { FixedGlobalStyle, ThemedGlobalStyle } from './theme';

const App = () => {
	const theme = useTheme();

	return (
		<Web3ReactManager>
			<>
				<ListsUpdater />
				<ApplicationUpdater />
				<TransactionUpdater />
				<MulticallUpdater />
				<Toaster
					position="bottom-left"
					toastOptions={{
						className: '',
						style: {
							background: theme.modalBG,
							color: theme.text1,
							zIndex: 9999999,
							boxShadow: 'initial',
							borderRadius: '12px',
							border: `1px solid ${theme.text3}`,
						},
						duration: 5000,
					}}
				/>
				<FixedGlobalStyle />
				<ThemedGlobalStyle />
				<Router>
					<Layout>
						<Routes />
					</Layout>
				</Router>
			</>
		</Web3ReactManager>
	);
};

export default App;
