import classNames from 'classnames';
import { Heading, HeadingLevel } from '../Heading/Heading';
import './Card.scss';

export enum CardSize {
	Normal,
	Large,
}

export enum CardMode {
	Normal,
	Warning,
	Critical,
}

type SpecialCardProps = {
	header?: JSX.Element;
	title?: string | React.ReactElement<CardHeaderProps, typeof CardHeader>;
	headingLevel?: HeadingLevel;
	/**
	 * @default 2
	 */
	hideHeading?: boolean;
	size?: CardSize;
	mode?: CardMode;
};

type CardProps = Omit<React.HTMLAttributes<HTMLElement>, 'title'> & SpecialCardProps;

export const Card: React.FC<CardProps> = ({
	className,
	header,
	title,
	headingLevel,
	children,
	hideHeading,
	size,
	mode,
	...props
}) => (
	<article
		{...props}
		className={classNames(
			'card',
			'rounded-lg',
			'bg-white',
			'shadow',
			{
				'card--large': size === CardSize.Large,
				'card--warning': mode === CardMode.Warning,
				'card--critical': mode === CardMode.Critical,
			},
			className
		)}
	>
		{typeof title === 'string' ? (
			<CardHeader title={title} headingLevel={headingLevel} hideHeading={hideHeading} accessoryView={header} />
		) : (
			title
		)}
		{children != null ? <CardBody>{children}</CardBody> : null}
	</article>
);

type ButtonCardProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
	SpecialCardProps & {
		active?: boolean;
	};

export const ButtonCard: React.FC<ButtonCardProps> = ({
	className,
	header,
	title,
	headingLevel,
	children,
	hideHeading,
	size,
	active,
	onClick,
	...props
}) => (
	<button
		{...props}
		className={classNames('card', { 'card--large': size === CardSize.Large, 'card--active': active }, className)}
		onClick={onClick}
	>
		{typeof title === 'string' ? (
			<CardHeader title={title} headingLevel={headingLevel} hideHeading={hideHeading} accessoryView={header} />
		) : (
			title
		)}
		{children != null ? <CardBody>{children}</CardBody> : null}
	</button>
);

type CardHeaderProps = {
	accessoryView?: JSX.Element;
	imageView?: JSX.Element;
	title?: string;
	subtitle?: string;
	headingLevel?: HeadingLevel;
	headingId?: string;
	hideHeading?: boolean;
	headingAfter?: React.ReactNode;
};

export const CardHeader: React.VFC<CardHeaderProps> = ({
	title,
	headingLevel = 2,
	headingId,
	accessoryView,
	imageView,
	hideHeading,
	subtitle,
	headingAfter,
}) => (
	<header
		className={classNames('card-header', {
			'card-header--empty': (title === undefined || hideHeading) && imageView == null && accessoryView == null,
		})}
	>
		{imageView != null ? <div className="card-header-image">{imageView}</div> : null}
		<Heading
			className={classNames('card-heading', 'text-4xl', {
				'visually-hidden': hideHeading,
				'card-heading--with-subtitle': title !== undefined && subtitle !== undefined,
			})}
			id={headingId}
			level={headingLevel}
		>
			<span className="card-heading-main">
				{title}
				{headingAfter}
			</span>
			{subtitle !== undefined ? (
				<>
					{' '}
					<span className="card-heading-separator visually-hidden">–</span>{' '}
					<span className="card-heading-sub">{subtitle}</span>
				</>
			) : null}
		</Heading>
		{accessoryView != null ? <div className="card-header-accessory">{accessoryView}</div> : null}
	</header>
);

const CardBody: React.FC = ({ children }) => <div className="card-body">{children}</div>;
