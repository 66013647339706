import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { DefinitionList, DefinitionListItem } from '../../components/DefinitionList/DefinitionList';
import Page from '../../components/Global/Page';
import { InputContainer } from '../../components/Input/Input';
import { NumericalInput } from '../../components/Input/NumericalInput';
import { SegmentedControls } from '../../components/SegmentedControls/SegmentedControls';
import { Separator } from '../../components/Separator/Separator';
import { Stack, StackAlignment } from '../../components/Stack/Stack';
import { Table } from '../../components/Table/Table';
import Toggle from '../../components/Toggle';
import useInterval from '../../hooks/useInterval';
import useWeb3 from '../../hooks/useWeb3';
import { stakeABI } from './stakeABI';
import './Staking.scss';
import { tokenABI } from './tokenABI';

enum StakeOption {
	Days7 = 0,
	Days14 = 1,
	Days30 = 2,
}

type Stake = {
	amount: string;
	bonus: string | undefined;
	status: { type: 'timeRemaining'; value: number } | { type: 'unstake' } | { type: 'none' };
};

const EMPTY_STAKES: Stake[] = [
	// {
	// 	amount: "0",
	// 	bonus: undefined,
	// 	status: { type: "none" },
	// },
	// {
	// 	amount: "10000000000000000000",
	// 	bonus: "1",
	// 	status: { type: "timeRemaining", value: 6566 },
	// },
	// {
	// 	amount: "10000000000000000000",
	// 	bonus: "1",
	// 	status: { type: "unstake" },
	// },
	// {
	// 	amount: "10000000000000000000",
	// 	bonus: "1",
	// 	status: { type: "unstake" },
	// },
];

const STAKE_CONTRACT_ADDRESS = '0xc15122898c3eE73211d8a8a4a656Ba1980D53086';
const TOKEN_CONTRACT_ADDRESS = '0xB42e1c3902b85b410334f5fff79cDc51fBeE6950';

const ENABLE_GSPI_APPROVAL_AMOUNT = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
const DISABLE_GSPI_APPROVAL_AMOUNT = '0';

const ADMIN_ADDRESS = '0x8D65Df3f0f5FaB4A997713FAa905Fa906ACE4c82';

const intlTime = new Intl.DateTimeFormat('en', {
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
	hour12: false,
});

export const Staking: React.VFC = () => {
	// Setup
	const web3 = useWeb3();
	const [accounts, setAccounts] = useState<string[]>([]);
	const contract = useMemo(() => new web3.eth.Contract(stakeABI, STAKE_CONTRACT_ADDRESS), [web3.eth.Contract]);
	const token = useMemo(() => new web3.eth.Contract(tokenABI, TOKEN_CONTRACT_ADDRESS), [web3.eth.Contract]);

	const formatNumberStr = useCallback((value: string) => Number.parseFloat(web3.utils.fromWei(value)).toFixed(2), [
		web3.utils,
	]);

	useEffect(() => {
		web3.eth.getAccounts().then(setAccounts);
	}, [web3.eth]);

	// State and derived values
	const [distributeAmountStr, setDistributeAmountStr] = useState('0');
	const distributeAmount = useMemo(() => Number.parseFloat(distributeAmountStr), [distributeAmountStr]);
	const isDistributeAmountValid = useMemo(() => distributeAmount > 0, [distributeAmount]);

	const [isAdmin, setIsAdmin] = useState(false);
	const [allowance, setAllowance] = useState('0');
	const [tokenBalance, setTokenBalance] = useState('0');
	const [dividends, setDividends] = useState('0');
	const [isGspiEnabled, setIsGspiEnabled] = useState(false);
	const isWithdrawEnabled = useMemo(() => Number.parseFloat(dividends) > 0, [dividends]);

	const [stakeOption, setStakeOption] = useState(StakeOption.Days7);
	const hexStakeOption = useMemo(() => {
		const hex = web3.utils.toHex(stakeOption);
		return hex.length % 2 === 1 ? hex.replace('0x', '0x0') : hex;
	}, [stakeOption, web3.utils]);

	const [stakeAmountStr, setAmountStr] = useState('0');
	const stakeAmount = useMemo(() => Number.parseFloat(stakeAmountStr), [stakeAmountStr]);
	const isStakeAmountValid = useMemo(() => stakeAmount > 0, [stakeAmount]);

	const [stakes, setStakes] = useState(EMPTY_STAKES);

	const [isStakingInProgress, setIsStakingInProgress] = useState(false);
	const [isWithdrawingInProgress, setIsWithdrawingInProgress] = useState(false);
	const [isDistributingInProgress, setIsDistributingInProgress] = useState(false);

	const handleIsGspiDisabled = useCallback(async () => {
		if (!isGspiEnabled) {
			try {
				await token.methods.approve(STAKE_CONTRACT_ADDRESS, ENABLE_GSPI_APPROVAL_AMOUNT).send({
					from: accounts[0],
				});
			} catch (e) {
				console.error(e);
			}
		} else {
			try {
				await token.methods.approve(STAKE_CONTRACT_ADDRESS, DISABLE_GSPI_APPROVAL_AMOUNT).send({
					from: accounts[0],
				});
			} catch (e) {
				console.error(e);
			}
		}

		setIsGspiEnabled((prev) => !prev);
	}, [isGspiEnabled, accounts, token.methods]);

	const handleStake = useCallback(async () => {
		if (isStakeAmountValid) {
			try {
				setIsStakingInProgress(true);
				await contract.methods.stake(accounts[0], web3.utils.toWei(stakeAmount.toString()), hexStakeOption).send({
					from: accounts[0],
				});
				setIsStakingInProgress(false);
			} catch (e) {
				console.error(e);
				setIsStakingInProgress(false);
			}
		}
	}, [accounts, stakeAmount, contract.methods, hexStakeOption, isStakeAmountValid, web3.utils]);

	const handleWithdraw = useCallback(async () => {
		if (isWithdrawEnabled) {
			try {
				setIsWithdrawingInProgress(true);
				await contract.methods.withdrawEarnings().send({
					from: accounts[0],
				});
				setIsWithdrawingInProgress(false);
			} catch (e) {
				console.error(e);
				setIsWithdrawingInProgress(false);
			}
		}
	}, [accounts, contract.methods, isWithdrawEnabled]);

	const handleDistribute = useCallback(async () => {
		if (isDistributeAmountValid) {
			try {
				setIsDistributingInProgress(true);
				await contract.methods.distributeDivs(web3.utils.toWei(distributeAmount.toString())).send({
					from: accounts[0],
				});
				setIsDistributingInProgress(false);
			} catch (e) {
				console.error(e);
				setIsDistributingInProgress(false);
			}
		}
	}, [accounts, contract.methods, distributeAmount, isDistributeAmountValid, web3.utils]);

	const handleUnstake = useCallback(
		async (i: number) => {
			try {
				await contract.methods.unstake(i).send({
					from: accounts[0],
				});
			} catch (e) {
				console.error(e);
			}
		},
		[accounts, contract.methods]
	);

	useInterval(() => {
		try {
			if (accounts.length > 0) {
				setIsAdmin(accounts[0].toLowerCase() === ADMIN_ADDRESS.toLowerCase());

				token.methods
					.allowance(accounts[0], STAKE_CONTRACT_ADDRESS)
					.call()
					.then((r: string) => {
						if (allowance !== r) {
							setAllowance(r);
							setIsGspiEnabled(r > '0');
						}
					});

				token.methods
					.balanceOf(accounts[0])
					.call()
					.then((r: string) => {
						if (tokenBalance !== r) {
							setTokenBalance(r);
						}
					});

				contract.methods
					.dividendsOf(accounts[0])
					.call()
					.then((r: string) => {
						if (dividends !== r) {
							setDividends(r);
						}
					});

				contract.methods
					.getPlayersFreezings(accounts[0], 0, 0)
					.call()
					.then((r: [string, number, string][]) => {
						setStakes(
							r.length === 0
								? EMPTY_STAKES
								: r.map(([amount, unlockEpoch, bonus]) => {
										const timestamp = Math.floor(new Date().getTime() / 1000);

										return {
											amount,
											bonus,
											status:
												unlockEpoch - timestamp <= 0
													? { type: 'unstake' }
													: { type: 'timeRemaining', value: unlockEpoch - timestamp },
										};
								  })
						);
					});
			} else {
				console.log('No accounts connected to GSPI staking');
			}
		} catch (e) {
			console.error(e);
		}
	}, 2000);

	return (
		<Page title="Staking" restrictWidth>
			<Card>
				{isAdmin && (
					<>
						<InputContainer label="Admin panel – Distribute" disabled={!isGspiEnabled}>
							<NumericalInput value={distributeAmountStr} onUserInput={setDistributeAmountStr}>
								<span className="unit">GSPI</span>
							</NumericalInput>
						</InputContainer>
						<Stack horizontal={StackAlignment.Center}>
							<Button
								large
								id="distributeButton"
								className="padding-1rem"
								onClick={handleDistribute}
								loading={{
									isLoading: isDistributingInProgress,
									hideLabel: true,
								}}
							>
								Confirm
							</Button>
						</Stack>
						<Separator />
					</>
				)}
				{/*<DefinitionList>
					<DefinitionListItem
						label="Enable GSPI"
						value={<Toggle isActive={isGspiEnabled} toggle={handleIsGspiDisabled} />}
					/>
					<DefinitionListItem label="Wallet balance" value={`${formatNumberStr(tokenBalance)} GSPI`} />
				</DefinitionList>
				<InputContainer label="Stake Amount" disabled={!isGspiEnabled}>
					<NumericalInput value={stakeAmountStr} onUserInput={setAmountStr}>
						<span className="unit">GSPI</span>
					</NumericalInput>
				</InputContainer>
				<InputContainer
					label="Duration"
					secondaryLabel={
						stakeOption === StakeOption.Days14
							? '+10% DIV GSPI'
							: stakeOption === StakeOption.Days30
							? '+25% DIV GSPI'
							: 'No bonus'
					}
					disabled={!isGspiEnabled}
				>
					<SegmentedControls
						active={stakeOption}
						options={[
							{
								id: 'stake-option-7',
								label: '7 days',
								value: StakeOption.Days7,
							},
							{
								id: 'stake-option-14',
								label: '14 days',
								value: StakeOption.Days14,
							},
							{
								id: 'stake-option-30',
								label: '30 days',
								value: StakeOption.Days30,
							},
						]}
						labelId="stake-option-label"
						onChange={setStakeOption}
					/>
				</InputContainer>*/}
				{/* <Stack horizontal={StackAlignment.Center}>
					<Button
						large
						id="stakeButton"
						className="padding-1rem"
						onClick={handleStake}
						disabled={!isGspiEnabled}
						loading={{
							isLoading: isStakingInProgress,
							hideLabel: true,
						}}
					>
						Stake GSPI
					</Button>
				</Stack> */}
				<Separator />
				<DefinitionList>
					<DefinitionListItem label="Your earnings" value={`${formatNumberStr(dividends)} GSPI`} />
				</DefinitionList>
				<Table
					caption="Stakes"
					rows={stakes}
					columns={[
						{
							key: 'amount',
							label: 'Amount staked',
							formatter: (row) => `${formatNumberStr(row.amount)} GSPI`,
						},
						{
							key: 'bonus',
							label: 'Bonus',
							formatter: (row) => (row.bonus !== undefined ? `${row.bonus} %` : '—'),
						},
						{
							key: 'status',
							label: 'Time remaining',
							formatter: (row, i) =>
								row.status.type === 'none' ? (
									'—'
								) : row.status.type === 'timeRemaining' ? (
									intlTime.format(row.status.value)
								) : (
									<Button onClick={() => handleUnstake(i)}>Unstake</Button>
								),
						},
					]}
					hideCaption
				/>
				<Stack horizontal={StackAlignment.Center}>
					<Button
						large
						id="withdraw"
						className="padding-1rem"
						onClick={handleWithdraw}
						disabled={!isWithdrawEnabled}
						loading={{
							isLoading: isWithdrawingInProgress,
							hideLabel: true,
						}}
					>
						Withdraw
					</Button>
				</Stack>
				<div className="watermark">
					<a href="https://team3d.io" rel="nofollow">
						Powered by team3d.io
					</a>
				</div>
			</Card>
		</Page>
	);
};
