import classNames from 'classnames';
import React, { ReactElement, useCallback, useMemo } from 'react';
import './TabbedContent.scss';

type TabConfig<Id extends string> = {
	id: Id;
	label: string;
	logo?: string;
	content: JSX.Element | null;
};

const prefixTabId = (id: string) => `tab-${id}`;

type TabProps<Id extends string> = { tab: TabConfig<Id>; active: Id; onChange: (id: Id) => void };

const Tab = <Id extends string>({ tab: { id, label, logo }, active, onChange }: TabProps<Id>): ReactElement | null => {
	const selected = useMemo(() => id === active, [id, active]);

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLAnchorElement>) => {
			event.preventDefault();
			onChange(id);
		},
		[onChange, id]
	);

	return (
		<li className={classNames({ selected: selected })}>
			<a
				role="tab"
				id={prefixTabId(id)}
				href={`#${id}`}
				aria-controls={id}
				aria-selected={selected}
				tabIndex={selected ? 0 : -1}
				onClick={handleClick}
			>
				{label}
				{logo && <img src={logo} alt={`logo of ${label}`} />}
			</a>
		</li>
	);
};

type TabListProps<Id extends string> = { tabs: TabConfig<Id>[]; active: Id; onChange: (id: Id) => void };

const TabList = <Id extends string>({ tabs, active, onChange }: TabListProps<Id>): ReactElement | null => {
	return (
		<ul className="tabs-list" role="tablist">
			{tabs.map((tab) => (
				<Tab key={tab.id} tab={tab} active={active} onChange={onChange} />
			))}
		</ul>
	);
};

type TabContentProps<Id extends string> = {
	tab: TabConfig<Id>;
	active: Id;
};

const TabPanel = <Id extends string>({ tab: { id, content }, active }: TabContentProps<Id>): ReactElement | null => {
	const selected = useMemo(() => id === active, [id, active]);

	return (
		<article
			className={classNames('tab-panel', { selected: selected })}
			aria-labelledby={prefixTabId(id)}
			id={id}
			role="tabpanel"
		>
			{content}
		</article>
	);
};

type TabContentListProps<Id extends string> = { tabs: TabConfig<Id>[]; active: Id };

const TabPanelList = <Id extends string>({ tabs, active }: TabContentListProps<Id>): ReactElement | null => {
	return (
		<div className="tabs-panels">
			{tabs.map((tab) => (
				<TabPanel key={tab.id} tab={tab} active={active} />
			))}
		</div>
	);
};

type TabsProps<Id extends string> = {
	className?: string;
	tabs: TabConfig<Id>[];
	active: Id;
	onChange: (id: Id) => void;
};

export const TabbedContent = <Id extends string>({
	className,
	tabs,
	active,
	onChange,
}: TabsProps<Id>): ReactElement | null => {
	return (
		<section className={classNames('tabs', className)}>
			<TabList active={active} tabs={tabs} onChange={onChange} />
			<TabPanelList active={active} tabs={tabs} />
		</section>
	);
};
