import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { AutoColumn, ColumnCenter } from '../Column';
import CloseIcon from '../Icons/Close';

export const Wrapper = styled.div`
	width: 100%;
	overflow-y: auto;
`;
export const Section = styled(AutoColumn)`
	padding: 24px;
`;

export const ConfirmedIcon = styled(ColumnCenter)`
	padding: 40px 0;
`;

export const BottomSection = styled(Section)`
	background-color: ${({ theme }) => theme.elementBG};
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
`;

const Heading = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
`;

const StyledContentHeader = styled.div`
	align-items: center;
	display: flex;
`;

const StyledButton = styled.button`
	color: ${({ theme }) => theme.text1};
	background-color: transparent;
	border: none;

	&:hover,
	&:focus,
	&:active {
		color: ${({ theme }) => theme.text1};
		background-color: transparent;
		border: none;
		outline: none;
	}
`;

type ContentHeaderProps = {
	children: ReactNode;
	onDismiss: () => void;
};

export const ContentHeader = ({ children, onDismiss }: ContentHeaderProps) => (
	<StyledContentHeader>
		<Heading>{children}</Heading>
		<StyledButton onClick={onDismiss}>
			<CloseIcon color="text1" />
		</StyledButton>
	</StyledContentHeader>
);
