import { Currency, Percent, Price } from '@pancakeswap-libs/sdk';
import React from 'react';
import { AutoColumn } from '../../components/Column';
import { AutoRow } from '../../components/Row';
import { ONE_BIPS } from '../../constants';
import { Field } from '../../states/mint/actions';
import { TYPE } from '../../theme';

const { Black } = TYPE;

export function PoolPriceBar({
	currencies,
	noLiquidity,
	poolTokenPercentage,
	price,
}: {
	currencies: { [field in Field]?: Currency };
	noLiquidity?: boolean;
	poolTokenPercentage?: Percent;
	price?: Price;
}) {
	return (
		<AutoColumn gap="md">
			<AutoRow justify="space-around" gap="4px">
				<AutoColumn justify="center">
					<Black>{price?.toSignificant(6) ?? '-'}</Black>
					<Black fontSize="14px" pt={1}>
						{currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
					</Black>
				</AutoColumn>
				<AutoColumn justify="center">
					<Black>{price?.invert()?.toSignificant(6) ?? '-'}</Black>
					<Black fontSize="14px" pt={1}>
						{currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
					</Black>
				</AutoColumn>
				<AutoColumn justify="center">
					<Black>
						{noLiquidity && price
							? '100'
							: (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
						%
					</Black>
					<Black fontSize="14px" pt={1}>
						Share of Pool
					</Black>
				</AutoColumn>
			</AutoRow>
		</AutoColumn>
	);
}

export default PoolPriceBar;
