import { Web3Provider } from '@ethersproject/providers';
import { useMemo } from 'react';
import useWeb3 from './useWeb3';

export const useProvider = () => {
	const web3 = useWeb3();
	return useMemo(() => {
		const library = new Web3Provider(web3.currentProvider);
		library.pollingInterval = 15000;
		return library;
	}, [web3.currentProvider]);
};
