import styled from 'styled-components';
import NavigationItem from './NavigationItem';

const Wrapper = styled.nav`
	padding: 2rem 0 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@media (min-width: 1024px) {
		padding: 4rem 0 4rem 3rem;
	}
`;

const List = styled.ul`
	margin: 0 auto;
	max-width: 600px;
	list-style: none;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	padding: 0 1rem;
	flex: 1;
	width: 100%;
	align-content: start;

	@media (min-width: 1024px) {
		grid-template-columns: 1fr;
		padding: 0 4rem 0 0;
		margin: 0;
		max-width: unset;
	}
`;

export const Sidebar: React.VFC = () => {
	return (
		<Wrapper>
			<List>
				<NavigationItem to={'/governance'} type={'governance'} title={'Governance'} />
				<NavigationItem to={'/amm'} type={'amm'} title={'Trading'} />
				<NavigationItem to={'/buy-crypto'} type={'buyCrypto'} title={'Buy Crypto'} />
				<NavigationItem to={'/stake'} type={'buyCrypto'} title={'Staking'} />
				<NavigationItem to={'https://tokenbridge.justliquidity.org'} type={'bridge'} title={'SPI Bridge'} absolute />
			</List>
		</Wrapper>
	);
};
