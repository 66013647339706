import { Currency, ETHER, Token } from '@pancakeswap-libs/sdk';
import { KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useActiveWeb3React } from '../../hooks';
import { useAllTokens, useToken } from '../../hooks/Tokens';
import useTheme from '../../hooks/useTheme';
import { AppState } from '../../states';
import { isAddress } from '../../utils';
import Column from '../Column';
import CloseIcon from '../Icons/Close';
import QuestionHelper from '../QuestionHelper';
import { RowBetween } from '../Row';
import { TYPE } from '../Shared';
import CommonBases from './CommonBases';
import CurrencyList from './CurrencyList';
import { filterTokens } from './filtering';
import SortButton from './SortButton';
import { useTokenComparator } from './sorting';
import { PaddedColumn, SearchInput, Separator } from './styleds';

const { Black: Text } = TYPE;

interface CurrencySearchProps {
	isOpen: boolean;
	onDismiss: () => void;
	selectedCurrency?: Currency | null;
	onCurrencySelect: (currency: Currency) => void;
	otherSelectedCurrency?: Currency | null;
	showCommonBases?: boolean;
	onChangeList: () => void;
}

export function CurrencySearch({
	selectedCurrency,
	onCurrencySelect,
	otherSelectedCurrency,
	showCommonBases,
	onDismiss,
	isOpen,
	onChangeList,
}: CurrencySearchProps) {
	const { chainId } = useActiveWeb3React();
	const theme = useTheme();

	const fixedList = useRef<FixedSizeList>();
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [invertSearchOrder, setInvertSearchOrder] = useState<boolean>(false);
	const allTokens = useAllTokens();

	// if they input an address, use it
	const isAddressSearch = isAddress(searchQuery);
	const searchToken = useToken(searchQuery);

	const showETH: boolean = useMemo(() => {
		const s = searchQuery.toLowerCase().trim();
		return s === '' || s === 'e' || s === 'et' || s === 'eth';
	}, [searchQuery]);

	const tokenComparator = useTokenComparator(invertSearchOrder);

	const audioPlay = useSelector<AppState, AppState['user']['audioPlay']>((state) => state.user.audioPlay);

	const filteredTokens: Token[] = useMemo(() => {
		if (isAddressSearch) return searchToken ? [searchToken] : [];
		return filterTokens(Object.values(allTokens), searchQuery);
	}, [isAddressSearch, searchToken, allTokens, searchQuery]);

	const filteredSortedTokens: Token[] = useMemo(() => {
		if (searchToken) return [searchToken];
		const sorted = filteredTokens.sort(tokenComparator);
		const symbolMatch = searchQuery
			.toLowerCase()
			.split(/\s+/)
			.filter((s) => s.length > 0);
		if (symbolMatch.length > 1) return sorted;

		return [
			...(searchToken ? [searchToken] : []),
			// sort any exact symbol matches first
			...sorted.filter((token) => token.symbol?.toLowerCase() === symbolMatch[0]),
			...sorted.filter((token) => token.symbol?.toLowerCase() !== symbolMatch[0]),
		];
	}, [filteredTokens, searchQuery, searchToken, tokenComparator]);

	const handleCurrencySelect = useCallback(
		(currency: Currency) => {
			onCurrencySelect(currency);
			onDismiss();
			if (audioPlay) {
				const audio = document.getElementById('bgMusic') as HTMLAudioElement;
				if (audio) {
					audio.play();
				}
			}
		},
		[onDismiss, onCurrencySelect, audioPlay]
	);

	// clear the input on open
	useEffect(() => {
		if (isOpen) setSearchQuery('');
	}, [isOpen]);

	// manage focus on modal show
	const inputRef = useRef<HTMLInputElement>();
	const handleInput = useCallback((event) => {
		const input = event.target.value;
		const checksummedInput = isAddress(input);
		setSearchQuery(checksummedInput || input);
		fixedList.current?.scrollTo(0);
	}, []);

	const handleEnter = useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter') {
				const s = searchQuery.toLowerCase().trim();
				if (s === 'eth') {
					handleCurrencySelect(ETHER);
				} else if (filteredSortedTokens.length > 0) {
					if (
						filteredSortedTokens[0].symbol?.toLowerCase() === searchQuery.trim().toLowerCase() ||
						filteredSortedTokens.length === 1
					) {
						handleCurrencySelect(filteredSortedTokens[0]);
					}
				}
			}
		},
		[filteredSortedTokens, handleCurrencySelect, searchQuery]
	);

	return (
		<Column style={{ width: '100%', flex: '1 1' }}>
			<PaddedColumn gap="14px">
				<RowBetween>
					<Text>
						Select a token
						<QuestionHelper
							text={'Find a token by searching for its name or symbol or by pasting its address below.'}
						/>
					</Text>
					<CloseIcon onClick={onDismiss} fill={theme.text1} />
				</RowBetween>
				<SearchInput
					type="text"
					id="token-search-input"
					placeholder={'Search by Symbol or name'}
					value={searchQuery}
					ref={inputRef as RefObject<HTMLInputElement>}
					onChange={handleInput}
					onKeyDown={handleEnter}
				/>
				{showCommonBases && (
					<CommonBases chainId={chainId} onSelect={handleCurrencySelect} selectedCurrency={selectedCurrency} />
				)}
				<RowBetween>
					<Text fontSize="14px">Token name</Text>
					<SortButton ascending={invertSearchOrder} toggleSortOrder={() => setInvertSearchOrder((iso) => !iso)} />
				</RowBetween>
			</PaddedColumn>

			<Separator />

			<div style={{ flex: '1' }}>
				<AutoSizer disableWidth>
					{({ height }) => (
						<CurrencyList
							height={height}
							showETH={showETH}
							currencies={filteredSortedTokens}
							onCurrencySelect={handleCurrencySelect}
							otherCurrency={otherSelectedCurrency}
							selectedCurrency={selectedCurrency}
							fixedListRef={fixedList}
						/>
					)}
				</AutoSizer>
			</div>
		</Column>
	);
}

export default CurrencySearch;
