const routes = {
	shopping: {
		title: 'Shopping',
		path: '/',
	},
	DAO: {
		title: 'DAO',
		children: {
			governance: {
				title: 'Governance',
				path: '/governance',
			},
			farming: {
				title: 'Farming',
				path: '/farming',
			},
			amm: {
				title: 'Trading',
				path: '/amm',
			},
			buyCrypto: {
				title: 'Buy Crypto',
				path: '/buy-crypto',
			},
			bridge: {
				title: 'SPI Bridge',
				path: 'https://tokenbridge.justliquidity.org',
				external: true,
			},
		},
	},
	buyCrypto: {
		title: 'Buy Crypto',
		path: '/buy-crypto',
	},
};

export type HeaderRoute = { title: string; path: string; absolute?: boolean };

export const headerRoutes: Record<string, HeaderRoute> = {
	shopping: {
		title: 'Shopping.io',
		path: 'https://shopping.io',
		absolute: true,
	},
	DAO: {
		title: 'DAO',
		path: '/',
	},
	buyCrypto: {
		title: 'Buy Crypto',
		path: '/buy-crypto',
	},
};

export default routes;
