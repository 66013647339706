import { Token } from '@pancakeswap-libs/sdk';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useActiveWeb3React } from '../../hooks';
import { useAllTokens } from '../../hooks/Tokens';
import { getEtherscanLink, shortenAddress } from '../../utils';
import { Button } from '../Button/Button';
import { AutoColumn } from '../Column';
import CurrencyLogo from '../CurrencyLogo';
import { Modal, ModalBody, ModalHeader, ModalTitle } from '../Modal';
import { AutoRow, RowBetween } from '../Row';
import { ExternalLink, TYPE } from '../Shared';

const { main: Main, blue: Blue, Black: Text } = TYPE;

const Wrapper = styled.div<{ error: boolean }>`
	background: transparent;
	padding: 0.75rem;
	border-radius: 20px;
`;

interface TokenWarningCardProps {
	token?: Token;
}

function TokenWarningCard({ token }: TokenWarningCardProps) {
	const { chainId } = useActiveWeb3React();

	const tokenSymbol = token?.symbol?.toLowerCase() ?? '';
	const tokenName = token?.name?.toLowerCase() ?? '';

	const allTokens = useAllTokens();

	const duplicateNameOrSymbol = useMemo(() => {
		if (!token || !chainId) return false;

		return Object.keys(allTokens).some((tokenAddress) => {
			const userToken = allTokens[tokenAddress];
			if (userToken.equals(token)) {
				return false;
			}
			return userToken.symbol?.toLowerCase() === tokenSymbol || userToken.name?.toLowerCase() === tokenName;
		});
	}, [token, chainId, allTokens, tokenSymbol, tokenName]);

	if (!token) return null;

	return (
		<Wrapper error={duplicateNameOrSymbol}>
			<AutoRow gap="6px">
				<AutoColumn gap="24px">
					<CurrencyLogo currency={token} size="16px" />
					<div> </div>
				</AutoColumn>
				<AutoColumn gap="10px" justify="flex-start">
					<Main>
						{token && token.name && token.symbol && token.name !== token.symbol
							? `${token.name} (${token.symbol})`
							: token.name || token.symbol}{' '}
					</Main>
					{chainId && (
						<ExternalLink style={{ fontWeight: 400 }} href={getEtherscanLink(chainId, token.address, 'token')}>
							<Blue title={token.address}>{shortenAddress(token.address)} (View on BscScan)</Blue>
						</ExternalLink>
					)}
				</AutoColumn>
			</AutoRow>
		</Wrapper>
	);
}

export default function TokenWarningModal({
	isOpen,
	tokens,
	onConfirm,
}: {
	isOpen: boolean;
	tokens: Token[];
	onConfirm: () => void;
}) {
	const [understandChecked, setUnderstandChecked] = useState(false);
	const toggleUnderstand = useCallback(() => setUnderstandChecked((uc) => !uc), []);

	const handleDismiss = useCallback(() => null, []);
	return (
		<Modal
			isOpen={isOpen}
			onDismiss={handleDismiss}
			maxHeight={90}
			semantic
			aria-labelledby="syrup-warning-modal-label"
		>
			<ModalHeader>
				<ModalTitle headingId="syrup-warning-modal-label" headingLevel={2} onClose={handleDismiss}>
					Token imported
				</ModalTitle>
			</ModalHeader>
			<ModalBody scrollable>
				<div className="token-warning-container mt-3 p-6 bg-yellow-100 rounded border border-yellow-500">
					<AutoColumn gap="lg">
						<Text>
							Anyone can create an BEP20 token on BSC with <em>any</em> name, including creating fake versions of
							existing tokens and tokens that claim to represent projects that do not have a token.
						</Text>
						<Text>
							This interface can load arbitrary tokens by token addresses. Please take extra caution and do your
							research when interacting with arbitrary BEP20 tokens.
						</Text>
						<Text>
							If you purchase an arbitrary token, <strong>you may be unable to sell it back.</strong>
						</Text>
						{tokens.map((token) => {
							return <TokenWarningCard key={token.address} token={token} />;
						})}
						<RowBetween>
							<div>
								<label htmlFor="understand-checkbox" style={{ cursor: 'pointer', userSelect: 'none' }}>
									<input
										id="understand-checkbox"
										type="checkbox"
										className="understand-checkbox"
										checked={understandChecked}
										onChange={toggleUnderstand}
									/>{' '}
									<Text as="span" ml="4px">
										I understand
									</Text>
								</label>
							</div>
							<Button
								disabled={!understandChecked}
								destructive
								className="token-dismiss-button"
								onClick={() => {
									onConfirm();
								}}
							>
								Continue
							</Button>
						</RowBetween>
					</AutoColumn>
				</div>
			</ModalBody>
		</Modal>
	);
}
