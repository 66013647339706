import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalTitle } from '../Modal';
import SlippageToleranceSetting from './SlippageToleranceSetting';
import TransactionDeadlineSetting from './TransactionDeadlineSetting';

const SettingsModal = ({ onDismiss, show }: { onDismiss: () => void; show: boolean }) => {
	return (
		<Modal isOpen={show} onDismiss={onDismiss} aria-labelledby="settings-label" semantic>
			<ModalHeader>
				<ModalTitle headingId="settings-label" headingLevel={2} onClose={onDismiss}>
					Settings
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<SlippageToleranceSetting />
				<TransactionDeadlineSetting />
			</ModalBody>
		</Modal>
	);
};

export default SettingsModal;
