import classNames from 'classnames';
import { ComponentClass, FunctionComponent } from 'react';
import './Stack.scss';

export enum StackFlow {
	Row,
	Column,
}

export enum StackAlignment {
	Start,
	Center,
	End,
	SpaceBetween,
	Stretch,
}

type StackProps = {
	/**
	 * @default Flow.Row
	 */
	flow?: StackFlow;

	/**
	 * @default Alignment.Start
	 */
	horizontal?: StackAlignment;

	/**
	 * @default Alignment.Stretch
	 */
	vertical?: StackAlignment;

	withPadding?: boolean;

	component?: FunctionComponent<{ className: string }> | ComponentClass<{ className: string }> | string;
};

export const Stack: React.FC<StackProps> = ({
	children,
	flow = StackFlow.Row,
	horizontal = StackAlignment.Start,
	vertical = StackAlignment.Stretch,
	withPadding,
	component: Component = 'div',
}) => (
	<Component
		className={classNames('stack', {
			'stack--row': flow === StackFlow.Row,
			'stack--column': flow === StackFlow.Column,
			'stack--horizontal--start': horizontal === StackAlignment.Start,
			'stack--horizontal--center': horizontal === StackAlignment.Center,
			'stack--horizontal--end': horizontal === StackAlignment.End,
			'stack--horizontal--space-between': horizontal === StackAlignment.SpaceBetween,
			'stack--horizontal--stretch': horizontal === StackAlignment.Stretch,
			'stack--vertical--start': vertical === StackAlignment.Start,
			'stack--vertical--center': vertical === StackAlignment.Center,
			'stack--vertical--end': vertical === StackAlignment.End,
			'stack--vertical--space-between': vertical === StackAlignment.SpaceBetween,
			'stack--vertical--stretch': vertical === StackAlignment.Stretch,
			'stack--padding': withPadding,
		})}
	>
		{children}
	</Component>
);
