export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement> & {
	level: HeadingLevel;
};

export const Heading: React.FC<HeadingProps> = ({ level, children, ...props }) =>
	level === 1 ? (
		<h1 {...props}>{children}</h1>
	) : level === 2 ? (
		<h2 {...props}>{children}</h2>
	) : level === 3 ? (
		<h3 {...props}>{children}</h3>
	) : level === 4 ? (
		<h4 {...props}>{children}</h4>
	) : level === 5 ? (
		<h5 {...props}>{children}</h5>
	) : (
		<h6 {...props}>{children}</h6>
	);
