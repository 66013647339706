import React from 'react';
import styled from 'styled-components';
import { TYPE } from '../../theme';
import { RowFixed } from '../Row';

const Text = TYPE.Black;

export const FilterWrapper = styled(RowFixed)`
	padding: 8px;
	background-color: ${({ theme }) => theme.elementBG};
	color: ${({ theme }) => theme.text1};
	border-radius: 8px;
	user-select: none;
	& > * {
		user-select: none;
	}
	:hover {
		cursor: pointer;
	}
`;

export default function SortButton({
	toggleSortOrder,
	ascending,
}: {
	toggleSortOrder: () => void;
	ascending: boolean;
}) {
	return (
		<FilterWrapper onClick={toggleSortOrder}>
			<Text fontSize="14px">{ascending ? '↑' : '↓'}</Text>
		</FilterWrapper>
	);
}
