import styled from 'styled-components';

import Base from './Base';

import Image1 from '../../assets/guides/trezor/1.gif';
import Image2 from '../../assets/guides/trezor/2.png';
import Image3 from '../../assets/guides/trezor/3.png';
import Image4 from '../../assets/guides/trezor/4.gif';
import Image5 from '../../assets/guides/trezor/5.png';

const Ol = styled.ol`
	padding-left: 1rem;
`;

const UL = styled.ul`
	padding-left: 2rem;
	list-style: ${({ listStyle }) => listStyle || 'disc'};
`;

const Li = styled.li`
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1rem;
`;

const Title = styled.span`
	font-size: 1rem;
	color: ${({ theme }) => theme.text1};
	margin-bottom: 1rem;
`;

const InnerContent = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	margin-left: -1rem;
	margin-bottom: 1.5rem;
`;

const Img = styled.img`
	border-radius: 36px;

	@media (max-width: 991px) {
		max-width: 100%;
	}
`;

const InnerTitle = styled.span`
	margin-bottom: 0.75rem;
	display: block;
`;

const OuterLi = styled.li`
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
`;

const InnerLi = styled.li`
	padding: 0.5rem 0;
`;

const Anchor = styled.a`
	color: ${({ theme }) => theme.text1};
	text-decoration: underline;
	font-weight: 300;

	&:hover {
		color: ${({ theme }) => theme.text2};
	}
`;

const ReferenceBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	min-height: 56px;
	line-height: 56px;
	background-color: ${({ theme }) => theme.elementBG};
	color: ${({ theme }) => theme.text1};
	font-weight: 700;
	font-size: 1rem;
	width: 100%;
`;

const PageTitle = styled.h4`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${({ theme }) => theme.text1};
	margin-top: 1rem;
	margin-bottom: 1.25rem;
	margin-right: 1rem;
`;

const TrezorGuideline = (props) => {
	return (
		<Base provider={'Trezor'}>
			<PageTitle>Requirements</PageTitle>
			<InnerContent>
				<UL>
					<OuterLi>
						Visit <Anchor href={'https://wallet.trezor.io'}>https://wallet.trezor.io</Anchor> and initialize your Trezor
					</OuterLi>
					<OuterLi>Trezor Model T firmware version 2.3.0.</OuterLi>
					<OuterLi>Trezor Model One firmware version 1.9.3.</OuterLi>
					<OuterLi>An internet connection and an available USB port. Use an adapter for USB-C ports</OuterLi>
					<OuterLi>U2F compatible browsers: Google Chrome, Opera, FireFox, Brave</OuterLi>
				</UL>
			</InnerContent>

			<PageTitle>Connect with MetaMask</PageTitle>
			<Content>
				<Title>
					Binance Smart Chain is compatible with MetaMask. Users can use MetaMask as a bridge between Trezor and Binance
					Smart Chain to make transactions to the Binance Smart Chain.
				</Title>
			</Content>
			<Ol>
				<Li>
					<Content>
						<Title>Connect MetaMask to Binance Smart Chain by following this guide to add a custom network</Title>
						<InnerContent>
							<UL>
								<OuterLi>
									<InnerTitle>MainNet</InnerTitle>
									<UL listStyle={'circle'}>
										<InnerLi>
											<Anchor
												href="https://docs.binance.org/smart-chain/developer/rpc.html"
												target={'_blank'}
												rel={'noreferrer noopener'}
											>
												RPC URLs
											</Anchor>
										</InnerLi>
										<InnerLi>ChainID: 0x38, 56 in decimal (if 56 doesn’t work, try 0x38)</InnerLi>
										<InnerLi>Symbol: BNB</InnerLi>
										<InnerLi>Block Explorer: https://bscscan.com</InnerLi>
									</UL>
								</OuterLi>
							</UL>
						</InnerContent>

						<InnerContent>
							<Img src={Image1} alt="new network" style={{ zoom: '75%' }} />
						</InnerContent>
					</Content>
				</Li>

				<Li>
					<Content>
						<Title>Unlock your Metamask</Title>

						<InnerContent>
							<Img src={Image2} alt="unlock metamask" style={{ zoom: '75%' }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>Click on “Connect Hardware Wallet”</Title>

						<InnerContent>
							<Img src={Image3} alt="connect hardware wallet" style={{ zoom: '75%' }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>Choose an address in your Trezor</Title>

						<InnerContent>
							<Img src={Image4} alt="choose address trezor" style={{ zoom: '75%' }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>You should see a new account</Title>

						<InnerContent>
							<Img src={Image5} alt="check address" style={{ zoom: '75%' }} />
						</InnerContent>
					</Content>
				</Li>
			</Ol>
			<ReferenceBox>
				<span className="pr-2">Source:</span>{' '}
				<Anchor
					href="https://docs.binance.org/smart-chain/wallet/trezor.html"
					target={'_blank'}
					rel={'noreferrer noopener'}
				>
					Binance Smart chain Docs
				</Anchor>
			</ReferenceBox>
		</Base>
	);
};

export default TrezorGuideline;
