import { Provider } from 'react-redux';
import store from './states';
import React from 'react';
import ThemeProvider from './theme';
import { NetworkContextName } from './constants';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import getLibrary from './utils/getLibrary';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const Providers = (props) => {
	return (
		<Web3ReactProvider getLibrary={getLibrary}>
			<Web3ProviderNetwork getLibrary={getLibrary}>
				<Provider store={store}>
					<ThemeProvider>{props.children}</ThemeProvider>
				</Provider>
			</Web3ProviderNetwork>
		</Web3ReactProvider>
	);
};

export default Providers;
