import classNames from 'classnames';
import './Grid.scss';

type GridProps = React.HTMLAttributes<HTMLDivElement>;

export const Grid: React.FC<GridProps> = ({ className, ...props }) => (
	<div {...props} className={classNames('grid', className)} />
);

type Width =
	| '1/1'
	| '1/2'
	| '2/2'
	| '1/3'
	| '2/3'
	| '3/3'
	| '1/4'
	| '2/4'
	| '3/4'
	| '4/4'
	| '1/6'
	| '2/6'
	| '3/6'
	| '4/6'
	| '5/6'
	| '6/6'
	| '1/12'
	| '2/12'
	| '3/12'
	| '4/12'
	| '5/12'
	| '6/12'
	| '7/12'
	| '8/12'
	| '9/12'
	| '10/12'
	| '11/12'
	| '12/12';

type GridColumnProps = React.HTMLAttributes<HTMLDivElement> & {
	width?: Width;
	center?: boolean;
};

export const GridColumn: React.FC<GridColumnProps> = ({ className, width = '1/1', center, ...props }) => (
	<div {...props} className={classNames('grid-column', { 'align-center': center }, className)} data-width={width} />
);
