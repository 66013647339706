import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Badge, BadgeType } from '../../components/Badge/Badge';
import { LinkButton } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import Page from '../../components/Global/Page';
import { Loading } from '../../components/Loading/Loading';
import { Column, getSimpleRowKey, Table } from '../../components/Table/Table';
import { DEFAULT_SPACE } from '../../constants';
import { useActiveWeb3React } from '../../hooks';
import { formatProposals, getScores } from '../../libs/utils';
import { AppState } from '../../states';
import { getProposals, getSpaces } from '../../states/governance/actions';
import { shorten } from '../../states/governance/hooks';
import { IProposal, ISpace } from '../../types/governance';

const breadcrumb = [
	{
		title: 'Proposals',
		url: '/governance',
	},
];

const columns: Column<{ id: string; row: IProposal }>[] = [
	{
		key: 'asset',
		label: 'Description',
		className: 'py-1',
		formatter: (row) => shorten(row.row.title, 'name'),
	},
	{
		key: 'status',
		label: 'Status',
		className: 'shrink',
		formatter: (row) => {
			const ts = Date.now() / 1000;
			const { start, end } = row.row;
			let state: {
				title: string;
				type: BadgeType;
			} =
				ts > end
					? {
							title: 'Closed',
							type: 'disabled',
					  }
					: ts > start
					? {
							title: 'Active',
							type: 'highlighted',
					  }
					: {
							title: 'Pending',
							type: 'info',
					  };
			return <Badge type={state.type}>{state.title}</Badge>;
		},
	},
	{
		key: 'start',
		label: 'Start Date',
		formatter: (row) => moment(row.row.start * 1000).format('YYYY/MM/DD HH:mm'),
	},
	{
		key: 'end',
		label: 'End Date',
		formatter: (row) => moment(row.row.end * 1000).format('YYYY/MM/DD HH:mm'),
	},
	{
		key: 'author',
		label: 'Author',
		formatter: (row) => (
			<>
				{row.row.author.slice(0, 6)}...{row.row.author.slice(-4)}
			</>
		),
	},
];

const GovernancePage: React.VFC<RouteComponentProps> = (props) => {
	const { library } = useActiveWeb3React();
	const { spaces, loading: governanceLoading, proposals } = useSelector<AppState, AppState['governance']>(
		(state) => state.governance
	);
	const [selectedProposal, setSelectedProposal] = useState({});
	const [space, setSpace] = useState<ISpace | null>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getSpaces());
		dispatch(getProposals(DEFAULT_SPACE));
	}, [dispatch]);

	useEffect(() => {
		const space = spaces.find((space) => space.id == DEFAULT_SPACE);
		if (space !== undefined) {
			setSpace(space);
		}
	}, [dispatch, spaces]);

	const tableData = useMemo(() => {
		return proposals.map((proposal) => ({
			id: proposal.id,
			row: proposal,
		}));
	}, [selectedProposal]);

	useEffect(() => {
		const transformProposals = async (proposals: any, id: any) => {
			try {
				if (library && space) {
					let result = {};
					const selected = proposals[id];
					const scores = await getScores(
						id,
						space.strategies,
						space.network,
						library,
						Object.values(selected ?? {}).map((proposal: any) => proposal.address)
					);
					result = Object.fromEntries(
						Object.entries(selected ?? {}).map((proposal: [string, any]) => {
							const transformed = [proposal[0], Object.assign({}, proposal[1])];
							transformed[1].score = scores.reduce((a, b) => a + (b[transformed[1].address] || 0), 0);
							return [transformed[0], transformed[1]];
						})
					);

					result = formatProposals({ ...result });
					const transformedProposal = Object.fromEntries(
						Object.entries(result).sort(
							(a: [string, any], b: [string, any]) => b[1].msg.payload.end - a[1].msg.payload.end
						)
					);
					setSelectedProposal(transformedProposal);
				} else {
					setSelectedProposal({});
				}
			} catch (e) {
				console.log(e);
				setSelectedProposal({});
			}
		};

		transformProposals(proposals, DEFAULT_SPACE);
	}, [proposals, space, library]);

	return (
		<Page title={'Governance'} breadcrumb={breadcrumb}>
			<Card
				title="Proposals"
				headingLevel={2}
				header={<LinkButton to={`${props.match.url}/create`}>Create New</LinkButton>}
			>
				{governanceLoading ? (
					<Loading />
				) : (
					<Table
						caption="Proposals"
						hideCaption
						rows={tableData}
						getRowKey={getSimpleRowKey}
						onClickRow={(_e, row) => {
							props.history.push(`/governance/proposal/${row.id}`);
						}}
						columns={columns}
					/>
				)}
			</Card>
		</Page>
	);
};

export default GovernancePage;
