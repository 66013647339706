import classNames from 'classnames';
import './ProgressBar.scss';

type ProgressBarProps = {
	className?: string;
	label: string;
	labelId: string;
	lowerBound: number;
	upperBound: number;
	current: number;
	valueFormatter: (value: number) => string;
};

const percentFormatter = new Intl.NumberFormat(navigator.languages?.[0] ?? navigator.language, {
	style: 'percent',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const ProgressBar: React.VFC<ProgressBarProps> = ({
	className,
	label,
	labelId,
	lowerBound,
	upperBound,
	current,
	valueFormatter,
}) => {
	const valueStr = valueFormatter(current);
	const percent = current / (upperBound - lowerBound);
	const percentStr = percentFormatter.format(Number.isNaN(percent) ? 0 : percent);

	return (
		<div className={classNames('progress-bar', className)}>
			<div className="progress-bar-labels">
				<div className="progress-bar-label" id={labelId}>
					{label}
				</div>
				<div className="progress-bar-totalvalue" aria-hidden>
					{valueStr}
				</div>
				<div className="progress-bar-percent" aria-hidden>
					{percentStr}
				</div>
			</div>
			<div
				className="progress-bar-display"
				role="progressbar"
				aria-labelledby={labelId}
				aria-valuetext={`${valueStr} (${percentStr})`}
				aria-valuemin={lowerBound}
				aria-valuemax={upperBound}
				aria-valuenow={current}
			>
				<div className="progress-bar-progress" style={{ width: `${percent * 100}%` }} />
			</div>
		</div>
	);
};
