import React, { useCallback, useState } from 'react';
import { Button } from '../Button/Button';
import { AutoColumn } from '../Column';
import { Modal, ModalBody, ModalHeader, ModalTitle } from '../Modal';
import { RowBetween } from '../Row';
import { TYPE } from '../Shared';

const { body: Body, Black: Text } = TYPE;

export default function SyrupWarningModal({
	isOpen,
	transactionType,
	onConfirm,
}: {
	isOpen: boolean;
	transactionType: string;
	onConfirm: () => void;
}) {
	const [understandChecked, setUnderstandChecked] = useState(false);
	const toggleUnderstand = useCallback(() => setUnderstandChecked((uc) => !uc), []);

	const handleDismiss = useCallback(() => null, []);
	return (
		<Modal
			isOpen={isOpen}
			onDismiss={handleDismiss}
			maxHeight={90}
			semantic
			aria-labelledby="syrup-warning-modal-label"
		>
			<ModalHeader>
				<ModalTitle headingId="syrup-warning-modal-label" headingLevel={2} onClose={handleDismiss}>
					Syrup Warning
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<div className="token-warning-container mt-3 p-6 bg-yellow-100 rounded border border-yellow-500">
					<AutoColumn gap="lg">
						{transactionType !== '' && (
							<>
								<Body color="red1">
									Please be careful when <strong>{transactionType}</strong> SYRUP.
								</Body>
								<Body color="red1">
									{transactionType === 'Buying'
										? 'You will not receive CAKE rewards for holding purchased SYRUP.'
										: 'You will need to buy back the same amount of SYRUP to be able to convert back to CAKE.'}
								</Body>
							</>
						)}
						<RowBetween>
							<div>
								<label htmlFor="understand-checkbox" style={{ cursor: 'pointer', userSelect: 'none' }}>
									<input
										id="understand-checkbox"
										type="checkbox"
										className="understand-checkbox"
										checked={understandChecked}
										onChange={toggleUnderstand}
									/>{' '}
									<Text as="span">I understand</Text>
								</label>
							</div>
							<Button
								disabled={!understandChecked}
								destructive
								onClick={() => {
									setUnderstandChecked(false);
									onConfirm();
								}}
							>
								Continue
							</Button>
						</RowBetween>
					</AutoColumn>
				</div>
			</ModalBody>
		</Modal>
	);
}
