import React from 'react';
import { escapeRegExp } from '../../utils';
import { Input } from './Input';

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // match escaped "." characters via in a non-capturing group

type InnerNumericalInputProps = {
	value: string | number | undefined;
	onUserInput: (input: string) => void;
	error?: boolean;
	fontSize?: string;
	size?: string;
	align?: 'right' | 'left';
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>;

const InnerNumericalInput: React.FC<InnerNumericalInputProps> = ({
	value,
	onUserInput,
	placeholder,
	size,
	...rest
}) => {
	const enforcer = (nextUserInput: string) => {
		if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
			onUserInput(nextUserInput);
		}
	};

	return (
		<Input
			{...rest}
			value={value}
			onChange={(event) => {
				// replace commas with periods, because uniswap exclusively uses period as the decimal separator
				enforcer(event.target.value.replace(/,/g, '.'));
			}}
			// universal input options
			inputMode="decimal"
			title="Token Amount"
			autoComplete="off"
			autoCorrect="off"
			size={size}
			// text-specific options
			type="text"
			pattern="^[0-9]*[.,]?[0-9]*$"
			placeholder={placeholder || '0.0'}
			minLength={1}
			maxLength={79}
			spellCheck="false"
			className="numerical"
		/>
	);
};

export const NumericalInput = React.memo(InnerNumericalInput);
