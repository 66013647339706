import { formatUnits } from '@ethersproject/units';
import { multicall } from '../../utils';

export const author = 'bonustrack';
export const version = '0.1.0';

const MAKER_DS_CHIEF_ADDRESS = {
	1: '0x9ef05f7f6deb616fd37ac3c959a2ddd25a54e4f5',
};

const abi = [
	{
		constant: true,
		inputs: [
			{
				name: '',
				type: 'address',
			},
		],
		name: 'deposits',
		outputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
];

export async function strategy(space, network, provider, addresses, options, snapshot) {
	const blockTag = typeof snapshot === 'number' ? snapshot : 'latest';
	const response = await multicall(
		network,
		provider,
		abi,
		addresses.map((address) => [MAKER_DS_CHIEF_ADDRESS[network], 'deposits', [address]]),
		{ blockTag }
	);
	return Object.fromEntries(
		response.map((value, i) => [addresses[i], parseFloat(formatUnits(value.toString(), options.decimals))])
	);
}
