import classNames from 'classnames';
import React from 'react';
import './Table.scss';

export type Column<R extends object> = {
	key: React.Key;
	className?: string;
	label: string;
	formatter: (row: R, rowIndex: number) => React.ReactNode;
};

type TableProps<R extends object> = {
	className?: string;
	caption: string;
	hideCaption?: boolean;
	rows: R[];
	columns: Column<R>[];
	getRowKey?: (row: R, rowIndex: number) => React.Key;
	onClickRow?: (event: React.MouseEvent<HTMLTableRowElement>, row: R) => void;
};

export const getSimpleRowKey = <R extends object>(_row: R, rowIndex: number): React.Key => rowIndex;

export const Table = <R extends object>({
	className,
	caption,
	hideCaption,
	rows,
	columns,
	getRowKey = getSimpleRowKey,
	onClickRow,
}: TableProps<R>): React.ReactElement => (
	<table className={classNames('table', className)}>
		<caption className={classNames({ 'visually-hidden': hideCaption })}>{caption}</caption>
		<thead>
			<tr>
				{columns.map((column) => (
					<th key={column.key} className={column.className}>
						{column.label}
					</th>
				))}
			</tr>
		</thead>
		<tbody>
			{rows.map((row, index) => {
				const key = getRowKey(row, index);

				return (
					<tr key={key} onClick={onClickRow && ((e) => onClickRow(e, row))} tabIndex={onClickRow ? 0 : -1}>
						{columns.map((column) => (
							<td key={column.key} className={column.className}>
								<div aria-hidden className="label">
									{column?.label}
								</div>
								<div className="value">{column.formatter(row, index)}</div>
							</td>
						))}
					</tr>
				);
			})}
		</tbody>
	</table>
);
