import { ChainId, Currency, currencyEquals, ETHER, Token } from '@pancakeswap-libs/sdk'
import React from 'react'
import styled from 'styled-components'
import { SUGGESTED_BASES } from '../../constants'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Row'


const BaseWrapper = styled.div<{ disable?: boolean }>`
	border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.text3)};
	border-radius: 10px;
	display: flex;
	padding: 6px;

	align-items: center;
	:hover {
		cursor: ${({ disable }) => !disable && 'pointer'};
		background-color: ${({ theme, disable }) => !disable && theme.elementBG};
	}

	background-color: ${({ theme, disable }) => disable && theme.elementBG};
	opacity: ${({ disable }) => disable && '0.4'};
`;

export default function CommonBases({
	chainId,
	onSelect,
	selectedCurrency,
}: {
	chainId?: ChainId;
	selectedCurrency?: Currency | null;
	onSelect: (currency: Currency) => void;
}) {
	return (
		<AutoColumn gap="md">
			<AutoRow>
				<TYPE.Black fontSize="14px">Common bases</TYPE.Black>
				<QuestionHelper text="These tokens are commonly paired with other tokens." />
			</AutoRow>
			<AutoRow gap="4px">
				<BaseWrapper
					onClick={() => {
						if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
							onSelect(ETHER);
						}
					}}
					disable={selectedCurrency === ETHER}
				>
					<CurrencyLogo currency={ETHER} style={{ marginRight: 8 }} />
					<TYPE.Black>BNB</TYPE.Black>
				</BaseWrapper>
				{(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
					const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address;
					return (
						<BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
							<CurrencyLogo currency={token} style={{ marginRight: 8 }} />
							<TYPE.Black>{token.symbol}</TYPE.Black>
						</BaseWrapper>
					);
				})}
			</AutoRow>
		</AutoColumn>
	);
}
