import { LinkButton } from '../Button/Button';

const ConnectWalletButton = (props) => {
	return (
		<LinkButton to={'/connect'} primary>
			Connect Wallet
		</LinkButton>
	);
};

export default ConnectWalletButton;
