import classNames from 'classnames';
import './Label.scss';

type LabelProps = {
	className?: string;
	text: React.ReactNode;
	secondaryText?: React.ReactNode;
	htmlFor?: string;
};

export const Label: React.FC<LabelProps> = ({ className, text, htmlFor, secondaryText }) => (
	<div className={classNames('label-row', className)}>
		<label className="primary-label" htmlFor={htmlFor}>
			{text}
		</label>
		{secondaryText != null ? <div className="secondary-label">{secondaryText}</div> : null}
	</div>
);
