import React from 'react';
import styled from 'styled-components';
import { useLastTruthy } from '../../hooks/useLast';
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails';

const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
	width: 100%;
	border-bottom-left-radius: 0.42rem;
	border-bottom-right-radius: 0.42rem;
	color: ${({ theme }) => theme.text2};
	background-color: transparent;
	margin-top: 1.5rem;
`;

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }: AdvancedSwapDetailsProps) {
	const lastTrade = useLastTruthy(trade);

	return (
		<AdvancedDetailsFooter show={Boolean(trade)}>
			<AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
		</AdvancedDetailsFooter>
	);
}
