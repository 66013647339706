import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import './Button.scss';

type SpecialButtonProps = {
	className?: string;
	primary?: boolean;
	destructive?: boolean;
	borderless?: boolean;
	icon?: React.ReactNode;
	iconAtEnd?: boolean;
	hideIconLabel?: boolean;
	hideIconLabelResponsive?: boolean;
	label?: string | React.ReactElement;
	large?: boolean;
	active?: boolean;
	fullWidth?: boolean;
	loading?: {
		isLoading: boolean;
		hideLabel: boolean;
	};
};

const getClassNames = ({
	className,
	primary,
	destructive,
	borderless,
	icon,
	hideIconLabel,
	hideIconLabelResponsive,
	large,
	active,
	fullWidth,
	loading,
}: SpecialButtonProps) =>
	classNames(
		'btn-g',
		{
			'btn-g-primary': primary,
			'btn-g-destructive': destructive,
			'btn-g--borderless': borderless,
			'btn-g--large': large,
			'btn-g--active': active,
			'btn-g-icon': icon != null && hideIconLabel,
			'btn-g-icon--responsive': icon != null && hideIconLabelResponsive,
			'full-width': fullWidth,
			'btn-g--loading': loading?.isLoading,
			'btn-g--loading-only': loading?.isLoading && loading?.hideLabel,
		},
		className
	);

type ButtonProps = SpecialButtonProps &
	React.ClassAttributes<HTMLButtonElement> &
	React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = (props) => {
	const {
		icon,
		iconAtEnd,
		label,
		children,
		loading,
		primary: _1,
		destructive: _2,
		borderless: _3,
		icon: _4,
		iconAtEnd: _5,
		hideIconLabel: _6,
		hideIconLabelResponsive: _7,
		label: _8,
		large: _9,
		active: _10,
		fullWidth: _11,
		...other
	} = props;

	return (
		<button {...other} className={getClassNames(props)}>
			{loading?.isLoading && loading.hideLabel ? <Loading small /> : null}
			{!iconAtEnd && (loading?.isLoading && !loading.hideLabel ? <Loading small /> : icon)}
			<div className="btn-g-label">{label || children}</div>
			{iconAtEnd && (loading?.isLoading && !loading.hideLabel ? <Loading small /> : icon)}
		</button>
	);
};

type LinkButtonProps<S> = Omit<SpecialButtonProps, 'loading'> & LinkProps<S>;

export const LinkButton = <S extends any = unknown>(props: React.PropsWithChildren<LinkButtonProps<S>>) => {
	const {
		icon,
		iconAtEnd,
		label,
		children,
		primary: _1,
		destructive: _2,
		borderless: _3,
		icon: _4,
		iconAtEnd: _5,
		hideIconLabel: _6,
		hideIconLabelResponsive: _7,
		label: _8,
		large: _9,
		active: _10,
		fullWidth: _11,
		...other
	} = props;

	return (
		<Link {...other} className={getClassNames(props)}>
			{!iconAtEnd && icon}
			<div className="btn-g-label">{label || children}</div>
			{iconAtEnd && icon}
		</Link>
	);
};

type ExternalLinkButtonProps = Omit<SpecialButtonProps, 'loading'> & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = (props) => {
	const {
		icon,
		iconAtEnd,
		label,
		children,
		primary: _1,
		destructive: _2,
		borderless: _3,
		icon: _4,
		iconAtEnd: _5,
		hideIconLabel: _6,
		hideIconLabelResponsive: _7,
		label: _8,
		large: _9,
		active: _10,
		fullWidth: _11,
		...other
	} = props;

	return (
		<a {...other} className={getClassNames(props)}>
			{!iconAtEnd && icon}
			<div className="btn-g-label">{label || children}</div>
			{iconAtEnd && icon}
		</a>
	);
};

type ButtonRowProps = React.HTMLAttributes<HTMLElement> & {
	spaceBetween?: boolean;
};

export const ButtonRow: React.FC<ButtonRowProps> = ({ children, className, spaceBetween, ...props }) => (
	<div
		{...props}
		className={classNames(
			'flex flex--align-center btn-row',
			{ 'flex--justify-center': !spaceBetween, 'flex--justify-space-between': spaceBetween },
			className
		)}
	>
		{children}
	</div>
);
