import { createReducer } from '@reduxjs/toolkit';
import { IProposal, ISpace } from '../../types/governance';
import { setLoading, setProposals, setSpaces } from './actions';

export type Space = {
	id: string;
	strategies: { params: { symbol: string } }[];
	network: string | number;
	// token: string;
	symbol: string;
};

export type Spaces = {
	[spaceId: string]: Space;
};

export type ProposalMessagePayload = {
	name: string;
	body: string;
	start: number;
	end: number;
	snapshot: number;
	metadata?: {};
	choices: string[];
};

export type ProposalMessage = {
	version?: string;
	payload: ProposalMessagePayload;
};

export type Proposal = {
	msg: ProposalMessage;
	bpt_voting_disabled?: string;
	address: string;
	authorIpfsHash: string;
};

export type Proposals = {
	[spaceId: string]: {
		[proposalId: string]: Proposal;
	};
};

export type PartialProposalMessagePayload = {
	name: string;
	body: string;
	start: number;
	end: number;
	snapshot: number;
	metadata?: {};
	choices?: string[];
};

export type PartialProposalMessage = {
	version?: string;
	payload?: PartialProposalMessagePayload;
};

export type PartialProposal = {
	msg?: PartialProposalMessage;
	bpt_voting_disabled?: string;
	address: string;
};

export type VoteMessagePayload = {
	choice: number;
};

export type VoteMessage = {
	payload: VoteMessagePayload;
};

export type Vote = {
	address: string;
	msg: VoteMessage;
};

export type Votes = {
	[voteId: string]: Vote;
};

export type GovernanceState = {
	loading: boolean;
	// spaces: Spaces;
	spaces: Array<ISpace>;
	// proposals: Proposals;
	proposals: Array<IProposal>;
};

const initialState: GovernanceState = {
	loading: false,
	spaces: [],
	proposals: [],
};

export default createReducer(initialState, (builder) => {
	builder
		.addCase(setLoading, (state, { payload }) => {
			state.loading = payload.value || false;
		})
		.addCase(setSpaces, (state, { payload }) => {
			state.spaces = payload.spaces;
		})
		.addCase(setProposals, (state, { payload }) => {
			state.proposals = payload.proposals;
		});
});
