import { getDelegations } from '../../plugins';
import { getScores } from '../../utils';

export const author = 'bonustrack';
export const version = '0.1.0';

export async function strategy(space, network, provider, addresses, options, snapshot) {
	const delegations = await getDelegations(space, network, provider, addresses, options, snapshot);
	if (Object.keys(delegations).length === 0) return {};

	const scores = await getScores(
		space,
		options.strategies,
		network,
		provider,
		Object.values(delegations).reduce((a, b) => a.concat(b)),
		snapshot
	);

	return Object.fromEntries(
		addresses.map((address) => {
			const addressScore = delegations[address]
				? delegations[address].reduce((a, b) => a + scores.reduce((x, y) => x + y[b] || 0, 0), 0)
				: 0;
			return [address, addressScore];
		})
	);
}
