import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserSlippageTolerance } from '../../states/user/hooks';
import { TYPE } from '../../theme';
import { Button } from '../Button/Button';
import { Input, InputContainer } from '../Input/Input';
import QuestionHelper from '../QuestionHelper';

const MAX_SLIPPAGE = 5000;
const RISKY_SLIPPAGE_LOW = 50;
const RISKY_SLIPPAGE_HIGH = 500;

const Text = TYPE.Black;

const StyledSlippageToleranceSettings = styled.div`
	margin-bottom: 16px;
`;

const Option = styled.div`
	padding: 0 4px;

	@media (max-width: 767px) {
		flex: 1;
	}
`;

const Flex = styled.div`
	display: flex;
	align-items: center;
	padding: 4px;

	@media (max-width: 767px) {
		justify-content: center;
	}
`;

const Options = styled.div`
	align-items: center;
	display: flex;

	${Option}:first-child,
	${Flex}:first-child {
		padding-left: 0;
	}

	${Option}:last-child,
	${Flex}:last-child {
		padding-right: 0;
	}

	${({ theme }) => theme.grids.sm} {
		flex-direction: row;
	}

	@media (max-width: 767px) {
		flex-direction: column;
		align-items: stretch;
	}
`;

const Label = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 8px;
`;

const predefinedValues = [
	{ label: '0.1%', value: 0.1 },
	{ label: '0.5%', value: 0.5 },
	{ label: '1%', value: 1 },
];

const SlippageToleranceSettings = () => {
	const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance();
	const [value, setValue] = useState(userSlippageTolerance / 100);
	const [error, setError] = useState<string | null>(null);

	const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { value: inputValue } = evt.target;
		setValue(parseFloat(inputValue));
	};

	// Updates local storage if value is valid
	useEffect(() => {
		try {
			const rawValue = value * 100;
			if (!Number.isNaN(rawValue) && rawValue > 0 && rawValue < MAX_SLIPPAGE) {
				setUserslippageTolerance(rawValue);
				setError(null);
			} else {
				setError('Enter a valid slippage percentage');
			}
		} catch {
			setError('Enter a valid slippage percentage');
		}
	}, [value, setError, setUserslippageTolerance]);

	// Notify user if slippage is risky
	useEffect(() => {
		if (userSlippageTolerance < RISKY_SLIPPAGE_LOW) {
			setError('Your transaction may fail');
		} else if (userSlippageTolerance > RISKY_SLIPPAGE_HIGH) {
			setError('Your transaction may be frontrun');
		}
	}, [userSlippageTolerance, setError]);

	return (
		<StyledSlippageToleranceSettings>
			<Label>
				<Text style={{ fontWeight: 600 }}>Slippage tolerance</Text>
				<QuestionHelper text="Your transaction will revert if the price changes unfavorably by more than this percentage." />
			</Label>
			<Options>
				<Flex>
					{predefinedValues.map(({ label, value: predefinedValue }) => {
						const handleClick = () => setValue(predefinedValue);

						return (
							<Option key={predefinedValue}>
								<Button large primary={value === predefinedValue} onClick={handleClick}>
									{label}
								</Button>
							</Option>
						);
					})}
				</Flex>
				<Flex>
					<Option>
						<InputContainer>
							<Input type="number" step={0.1} min={0.1} placeholder="5%" value={value} onChange={handleChange} />
						</InputContainer>
					</Option>
					<Option>
						<Text fontSize="18px">%</Text>
					</Option>
				</Flex>
			</Options>
			{error && (
				<Text mt="8px" color="red1">
					{error}
				</Text>
			)}
		</StyledSlippageToleranceSettings>
	);
};

export default SlippageToleranceSettings;
