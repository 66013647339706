export const APP_NAME: string = process.env.REACT_APP_NAME || 'Stealth shopping app';
export const CHAIN_ID: number = Number(process.env.REACT_APP_CHAIN_ID) || 56;
export const ETHEREUM_NETWORK = process.env.REACT_APP_INFURA_NETWORK_URL;
export const ETHEREUM_WEBSOCKET = process.env.REACT_APP_INFURA_NETWORK_WSS;

export const BSC_NODE_1: string = process.env.REACT_APP_NODE_1 || '';
export const BSC_NODE_2: string = process.env.REACT_APP_NODE_2 || '';
export const BSC_NODE_3: string = process.env.REACT_APP_NODE_3 || '';

export const INITIAL_DARK_MODE: boolean = process.env.REACT_APP_INITIAL_DARK_MODE === 'true';

export const SHOPPING_URL: string | undefined = process.env.REACT_APP_SHOPPING_URL;
export const BUY_CRYPTO_URL: string | undefined = process.env.REACT_APP_BUY_CRYPTO_URL;
export const ABOUT_US_URL: string | undefined = process.env.REACT_APP_ABOUT_US_URL;

export const TYPING_INTERVAL: number = 300;

export const BACKEND_URL: string = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3061/api/v1/';
