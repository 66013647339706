import { Jazzicon } from '@ukstv/jazzicon-react'
import { useState } from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import gspi from '../../../assets/images/gspi.png'
import { headerRoutes } from '../../../constants'
import { useActiveWeb3React } from '../../../hooks'
import { addressShorten } from '../../../utils/getAddressShorten'
import { LinkButton } from '../../Button/Button'
import { HeaderItem } from './HeaderItem'

const Nav = styled.nav``;

const Title = styled.span`
	font-size: 24px;
`;

const ModifiedJazzicon = styled(Jazzicon)`
width: 32px;
height: 32px;
margin-right: 1rem;
`;

export const Header: React.VFC = () => {
	const [openMenu, setOpenMenu] = useState(false);
	const { account } = useActiveWeb3React();
	// const isAdminLoggedIn = useSelector((state) => state.admin.isLoggedIn);
	// const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
	// const cart = useSelector((state) => state.cart);

	const toggleHover = () => {
		setOpenMenu(!openMenu);
	};

	return (
		<header className="flex justify-between items-center py-4.5 px-4.5 lg:px-12 text-dark">
			<div className="flex justify-between items-center w-full max-w-4xl z-20 mr-auto">
				<div className="flex items-center space-x-2 mr-20">
					<img className="w-12" src={gspi} alt="logo" />
					<Title className="font-black text-2xl font-bold">Shopping.io</Title>
				</div>
			</div>
			<button
				type="button"
				className="lg:hidden z-20 text-xs rounded border-white border px-2.5 py-1.5"
				onClick={toggleHover}
			>
				<SVG src={require('../../../assets/images/global/menu.svg').default} />
			</button>

			<Nav
				className={`items-center z-10 transform lg:static text-center
					lg:translate-y-0 flex flex-col lg:flex-row items-stretch lg:items-center lg:justify-end absolute lg:ml-6 left-0 top-0 bg-white
					transition
					w-full pt-28
					pb-10
					lg:py-0

					${openMenu ? 'translate-y-0' : '-translate-y-full'}`}
			>
				<ul className="flex justify-between lg:justify-end items-center w-full px-8 lg:px-0 flex-col py-2.5 lg:flex-row m-0">
					{Object.values(headerRoutes).map((config, index, routes) => (
						<HeaderItem
							key={config.path}
							config={config}
							className={index === routes.length - 1 ? 'lg:border-r lg:border-solid lg:border-lighterGray' : ''}
						/>
					))}
					{/* {isAdminLoggedIn ? (
						<li className="flex lg:hidden py-2.5">
							<Link to={'/admin'}>Admin panel</Link>
						</li>
					) : isLoggedIn ? (
						<li className="flex lg:hidden py-2.5">
							<Link to={'/account'}>Account</Link>
						</li>
					) : null} */}
				</ul>

				<div className="flex flex-col lg:flex-row items-center mt-4.5 lg:mt-0 lg:ml-8 px-6 lg:px-0">
					{/* {isAdminLoggedIn ? (
						<div className={'hidden lg:flex items-center'}>
							<AccountDropdown type={'admin'} lessBorderRadius />
						</div>
					) : isLoggedIn ? (
						<div className={'hidden lg:flex items-center'}>
							<ButtonItem to={'/cart'} activeClassName={'active'}>
								<SVG src={CartIcon} />
								{Number(cart?.totalQty) > 0 && <Badge />}
							</ButtonItem>
							<AccountDropdown type={'user'} lessBorderRadius noMargin={!account} />
						</div>
					) : ( */}
					{account ? (
						<LinkButton to={'/connect'}>
							<span className="flex items-center -mx-5">
								<ModifiedJazzicon address={account} />
								<span className="block">{addressShorten(account)}</span>
							</span>
						</LinkButton>
					) : (
						<LinkButton primary to={'/connect'}>
							Connect
						</LinkButton>
					)}
					{/* )} */}
				</div>
			</Nav>
		</header>
	);
};
