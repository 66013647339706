import axios from 'axios';

const instance = axios.create({ baseURL: 'https://score.snapshot.org/api/scores' });

interface GetScoresData {
	result: {
		state: string;
		scores: Array<any>;
	};
}

export const getScores = async (
	space: string,
	strategies: any[],
	network: string,
	addresses: string[],
	snapshot: number
): Promise<any> => {
	const params = {
		space,
		network,
		snapshot,
		strategies,
		addresses,
	};
	return instance
		.post<GetScoresData>('', {
			params,
		})
		.then((response) => {
			const { scores } = response.data.result;
			if (scores.length > 0) {
				return scores[0];
			}
			return {};
		});
};
