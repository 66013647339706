// The development contracts

// export const SPIO = {
// 	address: '0xb9A0cA0c1b83dB1fd3bE5f249EbFFC244cb4dDBB',
// 	explorer: 'https://goerli.etherscan.io/address/0xb9A0cA0c1b83dB1fd3bE5f249EbFFC244cb4dDBB',
// 	symbol: 'SPIO',
// 	name: 'Shopping.io Staking Token',
// 	decimals: 18,
// };

// export const GSPI = {
// 	address: '0x417e3785417E9b99307f55E6494088F4fa0a5d7F',
// 	explorer: 'https://testnet.bscscan.com/token/0x417e3785417E9b99307f55E6494088F4fa0a5d7F',
// 	symbol: 'GSPI',
// 	name: 'SPI Governance',
// 	decimals: 18,
// };

// The production contracts

export const SPIO = {
	address: '0xc15122898c3ee73211d8a8a4a656ba1980d53086',
	explorer: 'https://etherscan.io/address/0xc15122898c3ee73211d8a8a4a656ba1980d53086',
	symbol: 'SPIO',
	name: 'Shopping.io Staking Token',
	decimals: 18,
};

export const GSPI = {
	address: '0xB42e1c3902b85b410334f5fff79cDc51fBeE6950',
	explorer: 'https://bscscan.com/token/0xB42e1c3902b85b410334f5fff79cDc51fBeE6950',
	symbol: 'GSPI',
	name: 'SPI Governance',
	decimals: 18,
};
