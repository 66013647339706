import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import Page from '../../components/Global/Page';
import { Grid, GridColumn } from '../../components/Grid/Grid';
import { Input, InputContainer, TextArea } from '../../components/Input/Input';
import { NumericalInput } from '../../components/Input/NumericalInput';
import { Label } from '../../components/Label/Label';
import { DEFAULT_SPACE } from '../../constants';
import { useActiveWeb3React } from '../../hooks';
import { useProvider } from '../../hooks/useProvider';
import useWeb3 from '../../hooks/useWeb3';
import Governance from '../../http/Governance';
import { AppState } from '../../states';
import { getSpaces } from '../../states/governance/actions';
import { ISpace } from '../../types/governance';
import './style.scss';

const id = DEFAULT_SPACE!;

const breadcrumb = [
	{
		title: 'Proposals',
		url: '/governance',
	},
	{
		title: 'Create Proposal',
		url: '/governance/create',
	},
];

const Header = styled.div`
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
	margin-top: 10px;
	border: none;
	background-color: transparent;
`;

const Title = styled.h2`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${({ theme }) => theme.text1};
	margin: 0;
`;

const CreateProposal: React.VFC<RouteChildrenProps> = (props) => {
	const { account, library } = useActiveWeb3React();
	const api = useMemo(() => new Governance(), [Governance]);
	const web3 = useWeb3();
	const web3Provider = useProvider();
	const dispatch = useDispatch();
	const { spaces } = useSelector<AppState, AppState['governance']>((state) => state.governance);

	const [loading, setLoading] = useState(false);

	const [name, setName] = useState('');
	const [body, setBody] = useState('');
	const [choices, setChoices] = useState(['', '']);
	const [snapshot, setSnapshot] = useState('');
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const [metadata] = useState({});
	const [space, setSpace] = useState<ISpace | null>(null);

	useEffect(() => {
		dispatch(getSpaces());
	}, [dispatch, getSpaces]);

	useEffect(() => {
		const space = spaces.find((space) => space.id == DEFAULT_SPACE);
		if (space !== undefined) {
			setSpace(space);
		}
	}, [dispatch, spaces]);

	const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (!account) {
			props.history.push('/connect');
			return false;
		}
		if (!space) {
			return;
		}
		const payload = {
			type: 'single-choice',
			name: name,
			body: body,
			snapshot: Number.parseInt(snapshot),
			choices: choices,
			start: new Date(fromDate).valueOf() / 1000,
			end: new Date(toDate).valueOf() / 1000,
			metadata: {
				...metadata,
				strategies: space.strategies,
			},
		};
		if (
			!loading &&
			account &&
			payload.start &&
			payload.end &&
			payload.end > payload.start &&
			payload.name.length >= 1 &&
			payload.name.length <= 256 &&
			payload.body.length >= 1 &&
			payload.body.length <= 40000 &&
			payload.body.length <= 40000 &&
			payload.choices.length >= 2 &&
			payload.choices.length <= 32 &&
			payload.choices.every((a) => a.length > 0) &&
			!Number.isNaN(snapshot)
		) {
			try {
				setLoading(true);
				const msg: {
					address: string;
					msg: string;
					sig?: string;
				} = {
					address: account,
					msg: JSON.stringify({
						version: '0.1.3',
						timestamp: (Date.now() / 1000).toFixed(),
						space: id,
						type: 'proposal',
						payload,
					}),
				};
				msg.sig = await library?.getSigner(account).signMessage(msg.msg);
				const res = await api.sendMessage(msg);
				toast.success('Your Proposal is in!');
				if (res.hasOwnProperty('id')) {
					props.history.push(`/governance/proposal/${res.id}`);
				}
			} catch (error) {
				toast.error('Something went wrong!');
			} finally {
				setLoading(false);
			}
		} else {
			toast.error('Please fill all Fields and your proposal choices should be more than 1');
		}
	};

	useEffect(() => {
		web3.eth.getBlockNumber((err, blockNumber) => {
			if (err) {
				return false;
			}
			setSnapshot(blockNumber.toFixed());
		});
	}, [web3]);

	return (
		<Page title={'Create Proposal'} breadcrumb={breadcrumb}>
			<Card>
				<Header>
					<Title className="card-title">Create Proposal</Title>
				</Header>

				<Grid>
					<GridColumn>
						<InputContainer label="Title">
							<TextArea value={name} rows={5} onChange={(e) => setName(e.target.value)} placeholder="Title" />
						</InputContainer>
					</GridColumn>
					<GridColumn>
						<InputContainer label="What is your Proposal?">
							<TextArea
								value={body}
								rows={5}
								onChange={(e) => setBody(e.target.value)}
								placeholder="What is your Proposal?"
							/>
						</InputContainer>
					</GridColumn>
					<GridColumn width="1/3">
						<InputContainer label="Snapshot">
							<NumericalInput value={snapshot} onUserInput={setSnapshot} placeholder="Snapshot" />
						</InputContainer>
					</GridColumn>
					<GridColumn width="1/3">
						<InputContainer label="Start Date">
							<Input
								type="date"
								value={fromDate}
								onChange={(e) => setFromDate(e.target.value)}
								placeholder="Select a Date"
							/>
							{/* <DatePicker
              wrapperClassName={"d-flex"}
              value={fromDate}
              onChange={(e) => setFromDate(e)}
              colorPrimary="#87dce1"
              colorPrimaryLight="rgba(135, 220, 225, 0.2)"
              renderInput={StartDayPicker}
              shouldHighlightWeekends
              inputPlaceholder={t("governance.proposals.startDatePlaceholder")}
            /> */}
						</InputContainer>
					</GridColumn>
					<GridColumn width="1/3">
						<InputContainer label="End Date">
							<Input
								type="date"
								value={toDate}
								onChange={(e) => setToDate(e.target.value)}
								placeholder="Select a Date"
							/>
							{/* <DatePicker
              wrapperClassName={"d-flex"}
              value={toDate}
              onChange={(e) => setToDate(e)}
              colorPrimary="#87dce1"
              colorPrimaryLight="rgba(135, 220, 225, 0.2)"
              renderInput={EndDayPicker}
              shouldHighlightWeekends
              inputPlaceholder={t("governance.proposals.endDatePlaceholder")}
            /> */}
						</InputContainer>
					</GridColumn>
					<GridColumn>
						<Label text="Choices" />
						{choices.map((choice, index) => (
							<InputContainer
								key={index}
								label={`Choice ${index + 1}`}
								hideLabel
								mobileControls={
									<Button
										label="Remove choice"
										onClick={() => {
											const newChoices = [...choices.slice(0, index), ...choices.slice(index + 1)];
											setChoices(newChoices);
										}}
									/>
								}
							>
								<Input
									key={`choice-${index + 1}`}
									value={choice}
									onChange={(e) => {
										const newChoices = [...choices];
										newChoices[index] = e.target.value;
										setChoices(newChoices);
									}}
									placeholder={`Choice ${index + 1}`}
								>
									<Button
										label="Remove choice"
										onClick={() => {
											const newChoices = [...choices.slice(0, index), ...choices.slice(index + 1)];
											setChoices(newChoices);
										}}
									/>
								</Input>
							</InputContainer>
						))}
						<Button
							icon={
								<svg
									width="15px"
									height="15px"
									viewBox="0 0 16 16"
									className="bi bi-plus-circle"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
									<path
										fillRule="evenodd"
										d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
									/>
								</svg>
							}
							label="Add choice"
							onClick={(e) => {
								e.preventDefault();
								const newChoices = choices.concat('');
								setChoices(newChoices);
							}}
						/>
					</GridColumn>
					<GridColumn center>
						<Button
							label={!account ? 'Connect Wallet' : 'Publish'}
							loading={{
								isLoading: loading,
								hideLabel: true,
							}}
							primary
							large
							onClick={handleSubmit}
						/>
					</GridColumn>
				</Grid>
			</Card>
		</Page>
	);
};

export default CreateProposal;
