import { combineReducers } from '@reduxjs/toolkit';

import preferences from './preferences/reducer';
import governance from './governance/reducer';
import application from './application/reducer';
import burn from './burn/reducer';
import lists from './lists/reducer';
import mint from './mint/reducer';
import multicall from './multicall/reducer';
import swap from './swap/reducer';
import transactions from './transactions/reducer';
import user from './user/reducer';

const rootReducer = combineReducers({
	preferences,
	governance,
	application,
	burn,
	lists,
	mint,
	multicall,
	swap,
	transactions,
	user,
});

export const PERSIST_KEYS: string[] = ['preferences'];

export default rootReducer;
