import classNames from 'classnames';
import './DefinitionList.scss';

type DefinitionListItemProps = {
	label: React.ReactNode;
	value: React.ReactNode;
	alwaysVertical?: boolean;
};

export const DefinitionListItem: React.FC<DefinitionListItemProps> = ({ label, value, alwaysVertical }) => (
	<div className={classNames('definition-list-item', { 'definition-list-item--vertical': alwaysVertical })}>
		<dt className="definition-list-item-label">{label}</dt>
		<dd className="definition-list-item-value">{value}</dd>
	</div>
);

type IconDefinitionListItemProps = {
	label: string;
	icon: React.ReactNode;
	value: React.ReactNode;
	alwaysVertical?: boolean;
};

export const IconDefinitionListItem: React.FC<IconDefinitionListItemProps> = ({
	label,
	icon,
	value,
	alwaysVertical,
}) => (
	<div
		className={classNames('definition-list-item', 'definition-list-item--icon', {
			'definition-list-item--vertical': alwaysVertical,
		})}
	>
		<dt className="definition-list-item-label" title={label}>
			<span className="visually-hidden">{label}</span>
			<div aria-hidden>{icon}</div>
		</dt>
		<dd className="definition-list-item-value">{value}</dd>
	</div>
);

type OneOrMany<T> = T | T[];

export type DefinitionListProps = React.HTMLAttributes<HTMLDListElement> & {
	/**
	 * If `children` are present, the definition items generated by this property precede `children` elements
	 */
	data?: DefinitionListItemProps[];
	children?: OneOrMany<React.ReactElement<DefinitionListItemProps, typeof DefinitionListItem> | null>;
};

export const DefinitionList: React.FC<DefinitionListProps> = ({ children, className, data, ...props }) => (
	<dl {...props} className={classNames('definition-list', className)}>
		{data && data.map((item, index) => <DefinitionListItem {...item} key={index} />)}
		{children}
	</dl>
);

export type IconDefinitionListProps = React.HTMLAttributes<HTMLDListElement> & {
	/**
	 * If `children` are present, the definition items generated by this property precede `children` elements
	 */
	data?: IconDefinitionListItemProps[];
	children?: OneOrMany<React.ReactElement<IconDefinitionListItemProps, typeof IconDefinitionListItem> | null>;
};

export const IconDefinitionList: React.FC<IconDefinitionListProps> = ({ children, className, data, ...props }) => (
	<dl {...props} className={classNames('definition-list', 'definition-list--icon', className)}>
		{data && data.map((item, index) => <IconDefinitionListItem {...item} key={index} />)}
		{children}
	</dl>
);
