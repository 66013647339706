import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Icon from './Icon';

const Item = styled.li`
	padding: 0.5rem 0;
`;

const Title = styled.span`
	position: relative;
	color: ${({ theme }) => theme.text1};
	height: 56px;
	display: flex;
	align-items: center;
	flex: 1;
	padding: 0 0.5rem;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 56px;
		background-image: ${({ theme }) => theme.sidebarActiveBG};
		border-radius: 18px 0 0 18px;
		opacity: 0;
		transform: translateX(45px);
		transition: 0.3s ease all;
	}
`;

type Link = React.FC<{ path: string } & React.AnchorHTMLAttributes<HTMLAnchorElement>>;

const ExternalLink: Link = ({ path, children, ...props }) => (
	<a href={path} target="_blank" rel={'noreferrer noopener'} {...props}>
		{children}
	</a>
);

const InternalLink: Link = ({ path, children, ...props }) => (
	<NavLink exact to={path} activeClassName={'active'} {...props}>
		{children}
	</NavLink>
);

const NavigationItem: React.VFC<{ to: string; type: string; title: string; absolute?: boolean }> = (props) => {
	const Component = props.absolute ? ExternalLink : InternalLink;

	return (
		<Item>
			<Component path={props.to} className="flex">
				<Icon type={props.type} alt={props.title} />
				<Title className={'sidebar__title'}>{props.title}</Title>
			</Component>
		</Item>
	);
};

export default NavigationItem;
