import _ from 'lodash';
import { BSC_NODE_1, BSC_NODE_2, BSC_NODE_3 } from '../constants';

// Array of available nodes to connect to
const nodes = [BSC_NODE_1, BSC_NODE_2, BSC_NODE_3];

const getNodeUrl = () => {
	const randomIndex = _.random(0, nodes.length - 1);
	return nodes[randomIndex];
};

export default getNodeUrl;
