import React, { useContext } from 'react';
import { AlertTriangle } from 'react-feather';
import { ThemeContext } from 'styled-components';
import { Button } from '../Button/Button';
import { AutoColumn } from '../Column';
import { TYPE } from '../Shared';
import { BottomSection, ContentHeader, Section, Wrapper } from './helpers';

const Text = TYPE.Black;

type TransactionErrorContentProps = { message: string; onDismiss: () => void };

const TransactionErrorContent = ({ message, onDismiss }: TransactionErrorContentProps) => {
	const theme = useContext(ThemeContext);
	return (
		<Wrapper>
			<Section>
				<ContentHeader onDismiss={onDismiss}>Error</ContentHeader>
				<AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
					<AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
					<Text fontSize="16px" color="red1" style={{ textAlign: 'center', width: '85%' }}>
						{message}
					</Text>
				</AutoColumn>
			</Section>
			<BottomSection gap="12px">
				<Button onClick={onDismiss}>Dismiss</Button>
			</BottomSection>
		</Wrapper>
	);
};

export default TransactionErrorContent;
