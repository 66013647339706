import { ChainId } from '@pancakeswap-libs/sdk';
import React from 'react';
import { ArrowUpCircle } from 'react-feather';
import useTheme from '../../hooks/useTheme';
import { getEtherscanLink } from '../../utils';
import { Button } from '../Button/Button';
import { AutoColumn } from '../Column';
import { ExternalLink } from '../Shared';
import { ConfirmedIcon, ContentHeader, Section, Wrapper } from './helpers';

type TransactionSubmittedContentProps = {
	onDismiss: () => void;
	hash: string | undefined;
	chainId: ChainId;
};

const TransactionSubmittedContent = ({ onDismiss, chainId, hash }: TransactionSubmittedContentProps) => {
	const theme = useTheme();

	return (
		<Wrapper>
			<Section>
				<ContentHeader onDismiss={onDismiss}>Transaction submitted</ContentHeader>
				<ConfirmedIcon>
					<ArrowUpCircle strokeWidth={0.5} size={97} color={theme.primary} />
				</ConfirmedIcon>
				<AutoColumn gap="8px" justify="center">
					{chainId && hash && (
						<ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>View on bscscan</ExternalLink>
					)}
					<Button onClick={onDismiss}>Close</Button>
				</AutoColumn>
			</Section>
		</Wrapper>
	);
};

export default TransactionSubmittedContent;
