import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
`;

const Header = styled.header`
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;

	@media (max-width: 991px) {
		flex-direction: column;
		align-items: stretch;
	}
`;

const Title = styled.h4`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${({ theme }) => theme.text1};
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 1rem;

	@media (max-width: 991px) {
		margin-bottom: 0.75rem;
	}
`;

const SubTitle = styled.span`
	font-size: 0.875rem;
	font-weight: 400;
	color: ${({ theme }) => theme.text2};
	margin: 0;
`;

const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
`;

const Base = (props) => {
	return (
		<Wrapper>
			<Header>
				<Title>How to Connect?</Title>
				<SubTitle>
					Learn how to change network to "Binance Smart Chain" and connect wallet through {props?.provider}
				</SubTitle>
			</Header>
			<Content>{props.children}</Content>
		</Wrapper>
	);
};

export default Base;
