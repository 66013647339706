import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { HeaderRoute } from '../../../constants';

type Link = React.FC<{ path: string } & React.AnchorHTMLAttributes<HTMLAnchorElement>>;

const ExternalLink: Link = ({ path, children, ...props }) => (
	<a href={path} target="_blank" rel={'noreferrer noopener'} {...props}>
		{children}
	</a>
);

const InternalLink: Link = ({ path, children, ...props }) => (
	<NavLink exact to={path} activeClassName={'active'} {...props}>
		{children}
	</NavLink>
);

export const HeaderItem: React.VFC<{ config: HeaderRoute; className?: string }> = ({ config, className }) => {
	const Component = config.absolute ? ExternalLink : InternalLink;

	return (
		<li key={config.path} className={classNames('cursor-pointer', className)}>
			<Component path={config.path} className="text-dark hover:text-brand1 px-4 py-2.5">
				{config.title}
			</Component>
		</li>
	);
};
