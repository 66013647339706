import { AbstractConnector } from '@web3-react/abstract-connector';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import Page from '../../components/Global/Page';
import GuideLines from '../../components/Guideline';
import { TabbedContent } from '../../components/TabbedContent/TabbedContent';
import { APP_NAME } from '../../constants';
import { useActiveWeb3React } from '../../hooks';
import { config, localStorageKey } from './config';

const Connect: React.VFC = () => {
	const wallet = useActiveWeb3React();

	const activate = (connector: AbstractConnector, id: string) => {
		if (id === 'injected' && !window.ethereum) {
			toast.error('Please install MetaMask or use DApp browser');
			return false;
		}
		wallet.activate(connector);
		window.localStorage.setItem(localStorageKey, '1');
	};

	const [activeTab, setActiveTab] = useState('connector0');

	return (
		<Page>
			<Card title={'Connect to Wallet'}>
				<TabbedContent
					active={activeTab}
					onChange={setActiveTab}
					tabs={config.map((item, i) => {
						// @ts-expect-error
						const GuideLine = GuideLines[item.icon];

						return {
							id: 'connector' + i,
							label: item.title,
							content: (
								<>
									<section className="text-center flex flex-col shadow rounded-sm p-6">
										<h3 className="text-xl">Connect to Wallet</h3>
										<p>
											Allow {APP_NAME} connect to {item.title} to begin.
										</p>
										<Button
											className="self-center mt-6"
											large
											onClick={() => activate(item.connector, item.connectorId)}
										>
											Connect
										</Button>
									</section>
									{item.description && <section className="mt-12">{GuideLine && <GuideLine />}</section>}
								</>
							),
						};
					})}
				/>
			</Card>
		</Page>
	);
};

export default Connect;
