import { VFC } from 'react'
import telegram from '../../../assets/consumerIcons/telegram.svg'
import twitter from '../../../assets/consumerIcons/twitter.svg'
import gspi from '../../../assets/images/gspi.png'
import discord from '../../../assets/images/socials/discord.png'
import facebook from '../../../assets/images/socials/facebook.png'
import instagram from '../../../assets/images/socials/instagram.png'

export const Footer: VFC = () => {
	return (
		<footer className="bg-dark text-white">
			<div
				className="flex flex-col py-12 px-5.5 lg:px-32
                      lg:border-b border-solid border-gray"
			>
				<div className="flex flex-col lg:flex-row lg:justify-between">
					<div className="flex flex-col space-y-4">
						<div className="flex items-center space-x-2 md:mr-20">
							<img className="w-8" src={gspi} alt="logo" />
							<span className="font-black text-2xl font-bold">Shopping.io</span>
						</div>
						<div className="opacity-50">© 2021 Shopping.io</div>
						<div className="flex items-center space-x-4">
							<a href="https://twitter.com/shopping_io" target="_blank" rel="noreferrer">
								<img src={twitter} alt="twitter" />
							</a>
							<a href="https://discord.gg/24eeHDC" target="_blank" rel="noreferrer">
								<img className="w-8" src={discord} alt="discord" />
							</a>
							<a href="https://t.me/joinchat/F8pi_g-SvCtqaLq7hOr2yw" target="_blank" rel="noreferrer">
								<img src={telegram} alt="telegram" />
							</a>
							<a href="https://www.facebook.com/shopping.io" target="_blank" rel="noreferrer">
								<img src={facebook} alt="facebook" />
							</a>
							<a className="w-8" href="https://www.instagram.com/shopping.io_official" target="_blank" rel="noreferrer">
								<img src={instagram} alt="instagram" />
							</a>
						</div>
					</div>

					<div className="flex flex-col space-y-6 py-12 lg:py-0">
						<div className="">
							<div className="">Subscribe to our newsletter</div>
							<div className="opacity-70">Stay in touch with new deals and discounts here at Shopping.io</div>
						</div>
						<div className="flex">
							<div className="relative flex h-12 w-full mr-4.5">
								<input
									type="text"
									className="border border-solid border-lighterGray bg-transparent
                           py-2.5 px-6 rounded-lg w-full h-full"
									placeholder="Email"
								/>
							</div>
							<button type="button" className="bg-brand1 text-white py-3.25 px-2.5 rounded">
								Subscribe
							</button>
						</div>
					</div>
				</div>

				{/* <div className="flex justify-center mt-16">
					<div className="flex flex-row flex-1 justify-between max-w-xl">
						<div className="flex flex-col lg:flex-row flex-1">
							<div className="lg:flex flex-col space-y-2 flex-1">
								<div className="">Project</div>
								<div className="opacity-50">
									<Link to="/aboutus">About Us</Link>
								</div>
								<div className="opacity-50">
									<HashLink to="/spi/stake">Staking</HashLink>
								</div>
								<div className="opacity-50">
									<a href="https://shoppingio.medium.com" target="_blank" rel="noreferrer">
										Blog
									</a>
								</div>
							</div>
							<div className="lg:flex flex-col space-y-2 flex-1">
								<div className="opacity-50">
									<HashLink to="/foundation#roadmap">RoadMap</HashLink>
								</div>
								<div className="opacity-50">
									<Link to="/tokenomics">SPI X GSPI</Link>
								</div>
							</div>
						</div>
						<div className="flex flex-col lg:flex-row flex-1">
							<div className="lg:flex flex-col space-y-2 flex-1">
								<div className="">Help</div>
								<div className="opacity-50">
									<a href="mailto:support@shopping.io" target="_blank" rel="noreferrer">
										Contact us
									</a>
								</div>
								<div className="opacity-50">
									<a href="https://www.trustpilot.com/review/shopping.io" target="_blank" rel="noreferrer">
										Trustpilot
									</a>
								</div>
								<div className="opacity-50">
									<a href="https://t.me/SHOPPINGIO_REVIEWS" target="_blank" rel="noreferrer">
										Review Group
									</a>
								</div>
							</div>
							<div className="lg:flex flex-col space-y-2 flex-1">
								<div className="opacity-50">
									<a href="https://github.com/shoppingio/community-feedback/issues" target="_blank" rel="noreferrer">
										Bugs & Feedback
									</a>
								</div>
								<div className="opacity-50">
									<a href="https://t.me/joinchat/F8pi_g-SvCtqaLq7hOr2yw" target="_blank" rel="noreferrer">
										Telegram
									</a>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</div>
			<div className="text-center py-4">Shopping.io Team ©</div>
		</footer>
	);
};
