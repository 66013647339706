import { useEffect, useState, useRef } from 'react';
import Web3 from 'web3';
import { HttpProviderOptions } from 'web3-core-helpers';
import getRpcUrl from '../utils/getRpcUrl';
import { useActiveWeb3React } from './index';

const RPC_URL = getRpcUrl();
const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 } as HttpProviderOptions);

/**
 * Provides a web3 instance using the provider provided by useWallet
 * with a fallback of an httpProver
 * Recreate web3 instance only if the ethereum provider change
 */
const useWeb3 = () => {
	const { library } = useActiveWeb3React();
	const refEth = useRef(library);
	const [web3, setweb3] = useState(new Web3(Web3.givenProvider || httpProvider));

	useEffect(() => {
		if (library !== refEth.current) {
			setweb3(new Web3(Web3.givenProvider || httpProvider));
			refEth.current = library;
		}
	}, [library]);

	return web3;
};

export default useWeb3;
