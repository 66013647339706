import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';
import './Layout.scss';

export const Layout: React.FC = ({ children }) => {
	return (
		<div id="layout">
			<Header />
			<Sidebar />
			<main>{children}</main>
			<Footer />
		</div>
	);
};
