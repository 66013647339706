import { DialogContent, DialogOverlay, DialogOverlayProps } from '@reach/dialog'
import '@reach/dialog/styles.css'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { ArrowLeft, X } from 'react-feather'
import { animated, AnimatedProps, useTransition } from 'react-spring'
import { Button } from '../Button/Button'
import { Heading, HeadingLevel } from '../Heading/Heading'
import './Modal.scss'

type ModalBackdropProps = AnimatedProps<React.HTMLAttributes<HTMLDivElement> & DialogOverlayProps> & {
	inline?: boolean;
};

const AnimatedDialogOverlay = animated(DialogOverlay);

const ModalBackdrop: React.FC<ModalBackdropProps> = ({ children, inline, ...props }) => (
	<AnimatedDialogOverlay
		{...props}
		as="div"
		className={classNames('modal-backdrop', { 'modal-backdrop--inline': inline })}
	>
		{children}
	</AnimatedDialogOverlay>
);

type ModalContentProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'role'> & {
	'aria-labelledby': string;
	'aria-describedby'?: string;
};

const AnimatedDialogContent = animated(DialogContent);

const ModalContent: React.FC<ModalContentProps> = ({ children, className, ...props }) => (
	<AnimatedDialogContent {...props} as="div" className={classNames('modal', className)} role="dialog">
		{children}
	</AnimatedDialogContent>
);

// Allow CSS Custom Properties to be specified
declare module 'csstype' {
	interface Properties {
		'--modal-min-height'?: string;
		'--modal-max-height'?: string;
		'--modal-max-width'?: string;
	}
}

// destructure to not pass custom props to Dialog DOM element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const StyledDialogContent = styled(({ minHeight, maxHeight, mobile, isOpen, maxWidth, ...rest }) => (
//   <AnimatedDialogContent {...rest} />
// )).attrs({

interface ModalProps {
	className?: string;
	isOpen: boolean;
	onDismiss: () => void;
	minHeight?: number | false;
	maxHeight?: number;
	maxWidth?: number;
	initialFocusRef?: React.RefObject<any>;
	children?: React.ReactNode;
	semantic?: boolean;
	inline?: boolean;
	'aria-labelledby': string;
	'aria-describedby'?: string;
}

export const Modal: React.FC<ModalProps> = ({
	className,
	isOpen,
	onDismiss,
	minHeight = false,
	maxHeight = 90,
	maxWidth = 420,
	initialFocusRef,
	children,
	semantic,
	inline,
	'aria-labelledby': ariaLabelledby,
	'aria-describedby': ariaDescribedby,
}) => {
	const transition = useTransition(isOpen, {
		config: { duration: 200 },
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	const _getScrollbarWidth = () => {
		const scrollDiv = document.createElement('div');
		scrollDiv.className = 'modal-scrollbar-measure';
		document.body.appendChild(scrollDiv);
		const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
		document.body.removeChild(scrollDiv);
		return scrollbarWidth;
	};

	useEffect(() => {
		if (isOpen) {
			const PaddingWidth = _getScrollbarWidth();
			document.body.style.paddingRight = `${PaddingWidth}px`;
		}
	}, [isOpen]);

	// const [, set] = useSpring(() => ({ y: 0, config: { mass: 1, tension: 210, friction: 20 } }));
	// const bind = useGesture({
	//   onDrag: (state) => {
	//     set({
	//       y: state.down ? state.movement[1] : 0,
	//     });
	//     if (state.movement[1] > 300 || (state.velocity > 3 && state.direction[1] > 0)) {
	//       onDismiss();
	//     }
	//   },
	// });

	return (
		<>
			{transition(
				(values, item) =>
					item && (
						<ModalBackdrop
							style={values}
							onDismiss={() => {
								onDismiss();
								setTimeout(() => {
									document.body.style.paddingRight = `0`;
								}, 200);
							}}
							initialFocusRef={initialFocusRef}
							inline={inline}
						>
							<ModalContent
								// {...(isMobile
								//   ? {
								//       ...bind(),
								//     }
								//   : {})}
								aria-labelledby={ariaLabelledby}
								aria-describedby={ariaDescribedby}
								className={classNames(isMobile ? 'modal--mobile' : 'modal--desktop', className, "shadow-xl lg:rounded-lg")}
								style={{
									'--modal-min-height': minHeight === false ? undefined : `${minHeight}vh`,
									'--modal-max-height': `${maxHeight}vh`,
									'--modal-max-width': `${maxWidth}px`,
								}}
							>
								{semantic ? (
									<section className="modal-semantic">
										{/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
										{!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
										{children}
									</section>
								) : (
									<>
										{/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
										{!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
										{children}
									</>
								)}
							</ModalContent>
						</ModalBackdrop>
					)
			)}
		</>
	);
};

type ModalHeaderProps = React.HTMLAttributes<HTMLElement>;

export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, className, ...props }) => (
	<header {...props} className={classNames('modal-semantic-header', className)}>
		{children}
	</header>
);

type ModalTitleProps = {
	headingLevel: HeadingLevel;
	headingId: string;
	onClose: () => void;
	onBack?: () => void;
};

export const ModalTitle: React.FC<ModalTitleProps> = ({ headingLevel, headingId, onClose, onBack, children }) => {
	// const { t } = useTranslation();

	return (
		<div className="modal-semantic-title-row">
			{onBack && <Button label="Back" hideIconLabel icon={<ArrowLeft />} onClick={onBack} />}
			<Heading className="modal-semantic-title" id={headingId} level={headingLevel}>
				{children}
			</Heading>
			<Button label="Close" hideIconLabel icon={<X />} onClick={onClose} />
		</div>
	);
};

type ModalBodyProps = React.HTMLAttributes<HTMLElement> & {
	scrollable?: boolean;
};

export const ModalBody: React.FC<ModalBodyProps> = ({ children, className, scrollable, ...props }) => (
	<div
		{...props}
		className={classNames('modal-semantic-content', { 'modal-semantic-content--scrollable': scrollable }, className)}
	>
		{children}
	</div>
);

type ModalSectionProps = React.HTMLAttributes<HTMLElement> & {
	scrollable?: boolean;
	/**
	 * Remove the separator between this and the next section and removes all
	 * spacing between them that is caused by both sections.
	 */
	joinAfter?: boolean;
};

export const ModalSection: React.FC<ModalSectionProps> = ({ children, className, scrollable, joinAfter, ...props }) => (
	<div
		{...props}
		className={classNames(
			'modal-semantic-content-section',
			{
				'modal-semantic-content-section--scrollable': scrollable,
				'modal-semantic-content-section--joinafter': joinAfter,
			},
			className
		)}
	>
		{children}
	</div>
);

type ModalFooterProps = React.HTMLAttributes<HTMLElement>;

export const ModalFooter: React.FC<ModalFooterProps> = ({ children, className, ...props }) => (
	<footer {...props} className={classNames('modal-semantic-footer', className)}>
		{children}
	</footer>
);
