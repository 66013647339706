import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledNav = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.text2};
	padding: 30px;
	flex: 1;
`;

const ButtonMenu = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.elementBG};
	border-radius: 18px;

	@media (max-width: 991px) {
		flex-direction: column;
		align-items: stretch;
	}
`;

const StyledButton = styled.button`
	height: 44px;
	min-height: 44px;
	background: ${({ theme, variant }) => (variant === 'primary' ? theme.primaryGrad : 'transparent')};
	color: ${({ theme, variant }) => (variant === 'primary' ? theme.white : theme.text1)};
	border-radius: 12px;
	border: none;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s ease all;
	margin: 0.5rem;

	@media (max-width: 991px) {
		height: 40px;
		min-height: 40px;
	}

	&:focus,
	&:active,
	&:hover {
		outline: none;
		background: ${({ theme, variant }) => (variant === 'primary' ? theme.primaryGrad : theme.primaryLight)};
		color: ${({ theme, variant }) => (variant === 'primary' ? theme.white : theme.text1)};
	}
`;

const Nav = ({ activeIndex = 0 }) => (
	<StyledNav>
		<ButtonMenu activeIndex={activeIndex} size="sm" variant="subtle">
			<StyledButton variant={activeIndex === 0 ? 'primary' : 'link'} id="swap-nav-link" to="/amm/swap" as={Link}>
				Swap
			</StyledButton>
			<StyledButton variant={activeIndex === 1 ? 'primary' : 'link'} id="pool-nav-link" to="/amm/pool" as={Link}>
				Liquidity
			</StyledButton>
			<StyledButton
				as={'a'}
				variant={activeIndex === 2 ? 'primary' : 'link'}
				href="https://www.binance.org/en/bridge"
				target="_blank"
				rel="noreferrer noopener"
			>
				Bridge
			</StyledButton>
		</ButtonMenu>
	</StyledNav>
);

export default Nav;
