import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import Column from '../Column';
import { Input, InputContainer } from '../Input/Input';
import Modal from '../Modal/Custom';
import { PaddedColumn, Separator } from '../SearchModal/styleds';
import CurrencyList from './CurrencyList';

export default function CurrencySelectModal({
	isOpen,
	onDismiss,
	onCurrencySelect,
	selectedCurrency,
	currencies,
	type,
}) {
	const fixedList = useRef();
	const [searchQuery, setSearchQuery] = useState('');
	const inputRef = useRef();

	const filteredTokens = useMemo(() => {
		return currencies.filter(
			(token) => JSON.stringify([token.symbol, token.name]).toLowerCase().includes(searchQuery) || !searchQuery
		);
	}, [currencies, searchQuery]);

	useEffect(() => {
		if (isOpen) {
			setSearchQuery('');
		}
	}, [isOpen]);

	const handleInput = useCallback((event) => {
		const input = event.target.value;
		setSearchQuery(input.toLowerCase());
		fixedList.current.scrollTo(0);
	}, []);

	const handleCurrencySelect = useCallback(
		(currency) => {
			onCurrencySelect(currency, type);
			onDismiss();
		},
		[onDismiss, onCurrencySelect, type]
	);

	return (
		<Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={80} minHeight={80}>
			<Column style={{ width: '100%', flex: '1 1', minHeight: '100px' }}>
				<PaddedColumn gap="14px">
					<InputContainer>
						<Input
							id="token-search-input"
							placeholder={'Search name or paste address'}
							value={searchQuery}
							ref={inputRef}
							onChange={handleInput}
						/>
					</InputContainer>
				</PaddedColumn>
				<Separator />

				<div style={{ flex: '1' }}>
					<AutoSizer disableWidth>
						{({ height }) => (
							<CurrencyList
								height={height}
								currencies={filteredTokens}
								onCurrencySelect={handleCurrencySelect}
								selectedCurrency={selectedCurrency}
								fixedListRef={fixedList}
							/>
						)}
					</AutoSizer>
				</div>
			</Column>
		</Modal>
	);
}
