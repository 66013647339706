import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Page from '../../components/Global/Page';
import AddLiquidity from '../AddLiquidity';
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from '../AddLiquidity/redirects';
import Pool from '../Pool';
import RemoveLiquidity from '../RemoveLiquidity';
import { RedirectOldRemoveLiquidityPathStructure } from '../RemoveLiquidity/redirects';
import Swap from '../Swap';

const AMM = (props) => {
	const { path } = useRouteMatch();
	return (
		<Page title="Trading" restrictWidth>
			<Switch>
				<Route exact strict path={`${path}/swap`}>
					<Swap />
				</Route>
				<Route exact strict path={`${path}/pool`}>
					<Pool />
				</Route>
				<Route exact strict path={`${path}/remove`} component={RemoveLiquidity} />
				<Route exact strict path={`${path}/remove/:tokens`} component={RedirectOldRemoveLiquidityPathStructure} />
				<Route exact path={`${path}/add`} component={AddLiquidity} />
				<Route exact path={`${path}/add/:currencyIdA`} component={RedirectOldAddLiquidityPathStructure} />
				<Route exact path={`${path}/add/:currencyIdA/:currencyIdB`} component={RedirectDuplicateTokenIds} />
				<Redirect to={`${path}/swap`} />
			</Switch>
		</Page>
	);
};

export default AMM;
