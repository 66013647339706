import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useActiveWeb3React } from '../../hooks';
import { isTransactionRecent, useAllTransactions } from '../../states/transactions/hooks';
import { TransactionDetails } from '../../states/transactions/reducer';
import { TYPE } from '../../theme';
import { getEtherscanLink } from '../../utils';
import { Button } from '../Button/Button';
import CheckmarkCircleIcon from '../Icons/CheckmarkCircle';
import ErrorIcon from '../Icons/Error';
import Loader from '../Loader';
import { Modal, ModalBody, ModalHeader, ModalTitle } from '../Modal';
import { ExternalLink } from '../Shared';

const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => b.addedTime - a.addedTime;

const Text = TYPE.Black;

const Flex = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const FlexRow = styled(Flex)`
	padding: 6px 12px;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.elementBG};
	border-radius: 12px;

	&:not(:last-child) {
		margin-bottom: 6px;
	}
`;

const StyleExternalLink = styled(ExternalLink)`
	color: ${({ theme }) => theme.text1};
`;

const getRowStatus = (sortedRecentTransaction: TransactionDetails) => {
	const { hash, receipt } = sortedRecentTransaction;

	if (!hash) {
		return { icon: <Loader />, color: 'text' };
	}

	if (hash && receipt?.status === 1) {
		return { icon: <CheckmarkCircleIcon color="success" />, color: 'success' };
	}

	return { icon: <ErrorIcon color="red1" />, color: 'red1' };
};

const RecentTransactionsModal = ({ onDismiss, show }: { onDismiss: () => void; show: boolean }) => {
	const { account, chainId } = useActiveWeb3React();
	const allTransactions = useAllTransactions();

	// Logic taken from Web3Status/index.tsx line 175
	const sortedRecentTransactions = useMemo(() => {
		const txs = Object.values(allTransactions);
		return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
	}, [allTransactions]);

	return (
		<Modal isOpen={show} onDismiss={onDismiss} aria-labelledby="recent-transactions-label" semantic>
			<ModalHeader>
				<ModalTitle headingId="recent-transactions-label" headingLevel={2} onClose={onDismiss}>
					Recent Transactions
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{!account && (
					<Flex>
						<Text mb="12px">Please connect your wallet to view your recent transactions</Text>
						<Button destructive onClick={onDismiss}>
							Close
						</Button>
					</Flex>
				)}
				{account && chainId && sortedRecentTransactions.length === 0 && (
					<Flex>
						<Text mb="12px">No recent transactions</Text>
						<Button destructive onClick={onDismiss}>
							Close
						</Button>
					</Flex>
				)}
				{account &&
					chainId &&
					sortedRecentTransactions.map((sortedRecentTransaction) => {
						const { hash, summary } = sortedRecentTransaction;
						const { icon, color } = getRowStatus(sortedRecentTransaction);

						return (
							<>
								<FlexRow key={hash}>
									<StyleExternalLink href={getEtherscanLink(chainId, hash, 'transaction')} color={color}>
										{summary ?? hash}
									</StyleExternalLink>
									{icon}
								</FlexRow>
							</>
						);
					})}
			</ModalBody>
		</Modal>
	);
};

export default RecentTransactionsModal;
