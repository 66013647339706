import { Jazzicon } from '@ukstv/jazzicon-react';
import React from 'react';
import styled from 'styled-components';
import { _numeral } from '../../libs/utils';

export const ModifiedJazzicon = styled(Jazzicon)`
	width: 24px;
	height: 24px;
	margin-right: 10px;

	@media (min-width: 768px) {
		width: 32px;
		height: 32px;
		margin-right: 15px;
	}
`;

const Text = styled.span`
	color: ${({ theme }) => theme.text1};
	font-size: 0.875rem;
	font-weight: 500;

	@media (min-width: 768px) {
		font-size: 1rem;
	}
`;
const Address = styled.span`
	color: ${({ theme }) => theme.text1};
	font-size: 0.75rem;
	font-weight: 400;

	@media (min-width: 768px) {
		font-size: 1rem;
	}
`;

const voteItem = (props) => {
	return (
		<tr>
			<td>
				<div className="flex items-center">
					<div className={'none lg:block'}>
						<ModifiedJazzicon address={props.address} />
					</div>
					<Address>
						{props.address.slice(0, 6)}...{props.address.slice(-4)}
					</Address>
				</div>
			</td>
			<td>
				<Text className={'none lg:flex'}>{props.vote}</Text>
			</td>
			<td>
				<Text>
					{_numeral(props.score)} {props.symbol}
				</Text>
			</td>
		</tr>
	);
};

export default voteItem;
