import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import thunk from 'redux-thunk';

import rootReducer, { PERSIST_KEYS } from './reducer';

export const store = configureStore({
	reducer: rootReducer,
	middleware: [save({ states: PERSIST_KEYS }), thunk],
	preloadedState: load({ states: PERSIST_KEYS }),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export default store;
