import { injected, bsc } from '../../connectors';

export const config = [
	{
		title: 'MetaMask',
		icon: 'metamask',
		description:
			'This is a recommended trading method. All transaction signing is done within the MetaMask extension, and private keys are never sent to the browser.',
		connectorId: 'injected',
		connector: injected,
	},
	{
		title: 'Trust Wallet',
		icon: 'trustWallet',
		description:
			'This is a recommended trading method. All transaction signing is done within your mobile wallet, and private keys are never sent to the browser.',
		connectorId: 'injected',
		connector: injected,
	},
	{
		title: 'Ledger',
		icon: 'ledger',
		description:
			'This is the recommended trading method. All transaction signing is done within your hardware wallet, and your private keys never leave the device.',
		connectorId: 'injected',
		connector: injected,
	},
	{
		title: 'Trezor',
		icon: 'trezor',
		description:
			'This is the recommended trading method. All transaction signing is done within your hardware wallet, and your private keys never leave the device.',
		connectorId: 'injected',
		connector: injected,
	},
	{
		title: 'Binance Chain Wallet',
		icon: 'binanceChain',
		description: false,
		connectorId: 'bsc',
		connector: bsc,
	},
];

export const localStorageKey = 'accountStatus';
