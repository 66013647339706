import axios from 'axios';
import { GOVERNANCE_HUB_URL } from '../constants';
import { IApiVote, IProposal, ISpace } from '../types/governance';

const instance = axios.create({ baseURL: `${GOVERNANCE_HUB_URL}/graphql` });

const getSpacesQuery = () => {
	return `
	{
		spaces(first: 1000000) {
			id
			name
			about
			network
			symbol
			strategies {
				name
				params
			}
		}
	}`;
};

interface IGetSpacesData {
	data: {
		spaces: Array<ISpace>;
	};
}

export const getSpaces = (): Promise<Array<ISpace>> => {
	return instance
		.post<IGetSpacesData>('', { query: getSpacesQuery() })
		.then((response) => {
			return response.data.data.spaces;
		});
};

const getProposalsQuery = (spaceId: string) => {
	return `
	{
		proposals(first: 1000000, where: { space:  "${spaceId}" }) {
			id
			title
			body
			choices
			start
			end
			snapshot
			state
			author
			created
			plugins
			network
			strategies {
				name
				params
			}
			space {
				id
				name
			}
		}
	}`;
};

interface IGetProposalsData {
	data: {
		proposals: Array<IProposal>;
	};
}

const filtedProposalIds = [
	'QmU66A9jL7Wm7cMYay2G4isoBFqiuorwFjQNbJnrSbf48e',
	'QmfJy7tYMLpE4KVLqRQy96YB24EVUTgG9ijajAr1ejGEDb',
	'QmUgZRKYo6q1pkSQgL9sXTFiTpyXiDy6fpqp5YgnkZonvY',
];

export const getProposals = (spaceId: string): Promise<Array<IProposal>> => {
	return instance
		.post<IGetProposalsData>('', { query: getProposalsQuery(spaceId) })
		.then((response) => {
			return response.data.data.proposals.filter((proposal) => !filtedProposalIds.includes(proposal.id));
		});
};

// Invalid fields: proposal
const getVotesQuery = (proposalId: string) => {
	return `
	{
		votes (first: 1000000, where: { proposal: "${proposalId}" }) {
			id
			voter
			created
			choice
			space {
				id
			}
			ipfs
			proposal{
				id
			}
		}
	}`;
};

interface IGetVotesData {
	data: {
		votes: Array<IApiVote>;
	};
}

export const getVotes = (proposalId: string): Promise<Array<IApiVote>> => {
	return instance
		.post<IGetVotesData>('', { query: getVotesQuery(proposalId) })
		.then((response) => {
			return response.data.data.votes;
		});
};
