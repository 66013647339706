import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse } from '@ethersproject/providers';
import { Currency, currencyEquals, ETHER, TokenAmount, WETH } from '@pancakeswap-libs/sdk';
import React, { useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Button, LinkButton } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import CardNav from '../../components/CardNav';
import { AutoColumn, ColumnCenter } from '../../components/Column';
import CurrencyInputPanel from '../../components/CurrencyInputPanel';
import DoubleCurrencyLogo from '../../components/DoubleLogo';
import AddIcon from '../../components/Icons/Add';
import Pane from '../../components/Pane';
import { MinimalPositionCard } from '../../components/PositionCard';
import Row, { RowBetween, RowFlat } from '../../components/Row';
import { LightCard } from '../../components/StyledCards';
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal';
import { ROUTER_ADDRESS } from '../../constants';
import { PairState } from '../../data/Reserves';
import { useActiveWeb3React } from '../../hooks';
import { useCurrency } from '../../hooks/Tokens';
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback';
import useTheme from '../../hooks/useTheme';
import { Field } from '../../states/mint/actions';
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../states/mint/hooks';
import { useTransactionAdder } from '../../states/transactions/hooks';
import { useIsExpertMode, useUserDeadline, useUserSlippageTolerance } from '../../states/user/hooks';
import { TYPE } from '../../theme';
import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from '../../utils';
import { currencyId } from '../../utils/currencyId';
import { maxAmountSpend } from '../../utils/maxAmountSpend';
import { wrappedCurrency } from '../../utils/wrappedCurrency';
import { Dots, Wrapper } from '../Pool/styleds';
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom';
import { PoolPriceBar } from './PoolPriceBar';

const { italic: Italic, Black: Text } = TYPE;

const PageCard = styled(Card)`
	& .card-header {
		border-bottom-width: 0;
		padding: 0;
	}
`;

export default function AddLiquidity({
	match: {
		params: { currencyIdA, currencyIdB },
	},
	history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
	const { account, chainId, library } = useActiveWeb3React();
	const currencyA = useCurrency(currencyIdA);
	const currencyB = useCurrency(currencyIdB);
	const theme = useTheme();

	const oneCurrencyIsWETH = Boolean(
		chainId &&
			((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
				(currencyB && currencyEquals(currencyB, WETH[chainId])))
	);
	const expertMode = useIsExpertMode();

	// mint state
	const { independentField, typedValue, otherTypedValue } = useMintState();
	const {
		dependentField,
		currencies,
		pair,
		pairState,
		currencyBalances,
		parsedAmounts,
		price,
		noLiquidity,
		liquidityMinted,
		poolTokenPercentage,
		error,
	} = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);
	const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

	const isValid = !error;

	// modal and loading
	const [showConfirm, setShowConfirm] = useState<boolean>(false);
	const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false); // clicked confirm

	// txn values
	const [deadline] = useUserDeadline(); // custom from users settings
	const [allowedSlippage] = useUserSlippageTolerance(); // custom from users
	const [txHash, setTxHash] = useState<string>('');

	// get formatted amounts
	const formattedAmounts = {
		[independentField]: typedValue,
		[dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
	};

	// get the max amounts user can add
	const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
		(accumulator, field) => {
			return {
				...accumulator,
				[field]: maxAmountSpend(currencyBalances[field]),
			};
		},
		{}
	);

	const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
		(accumulator, field) => {
			return {
				...accumulator,
				[field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
			};
		},
		{}
	);

	// check whether the user has approved the router on the tokens
	const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ROUTER_ADDRESS);
	const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ROUTER_ADDRESS);

	const addTransaction = useTransactionAdder();

	async function onAdd() {
		if (!chainId || !library || !account) return;
		const router = getRouterContract(chainId, library, account);

		const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts;
		if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
			return;
		}

		const amountsMin = {
			[Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
			[Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0],
		};

		const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

		let estimate;
		let method: (...args: any) => Promise<TransactionResponse>;
		let args: Array<string | string[] | number>;
		let value: BigNumber | null;
		if (currencyA === ETHER || currencyB === ETHER) {
			const tokenBIsETH = currencyB === ETHER;
			estimate = router.estimateGas.addLiquidityETH;
			method = router.addLiquidityETH;
			args = [
				wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
				(tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
				amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
				amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
				account,
				deadlineFromNow,
			];
			value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString());
		} else {
			estimate = router.estimateGas.addLiquidity;
			method = router.addLiquidity;
			args = [
				wrappedCurrency(currencyA, chainId)?.address ?? '',
				wrappedCurrency(currencyB, chainId)?.address ?? '',
				parsedAmountA.raw.toString(),
				parsedAmountB.raw.toString(),
				amountsMin[Field.CURRENCY_A].toString(),
				amountsMin[Field.CURRENCY_B].toString(),
				account,
				deadlineFromNow,
			];
			value = null;
		}

		setAttemptingTxn(true);
		// const aa = await estimate(...args, value ? { value } : {})
		await estimate(...args, value ? { value } : {})
			.then((estimatedGasLimit) =>
				method(...args, {
					...(value ? { value } : {}),
					gasLimit: calculateGasMargin(estimatedGasLimit),
				}).then((response) => {
					setAttemptingTxn(false);

					addTransaction(response, {
						summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
							currencies[Field.CURRENCY_A]?.symbol
						} and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
					});

					setTxHash(response.hash);
				})
			)
			.catch((e) => {
				setAttemptingTxn(false);
				// we only care if the error is something _other_ than the user rejected the tx
				if (e?.code !== 4001) {
					console.error(e);
				}
			});
	}

	const modalHeader = () => {
		return noLiquidity ? (
			<AutoColumn gap="20px">
				<LightCard mt="20px" borderRadius="20px">
					<RowFlat>
						<Text fontSize="48px">
							{`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`}
						</Text>
						<DoubleCurrencyLogo
							currency0={currencies[Field.CURRENCY_A]}
							currency1={currencies[Field.CURRENCY_B]}
							size={30}
						/>
					</RowFlat>
				</LightCard>
			</AutoColumn>
		) : (
			<AutoColumn gap="20px">
				<RowFlat style={{ marginTop: '20px' }}>
					<Text fontSize="48px">{liquidityMinted?.toSignificant(6)}</Text>
					<DoubleCurrencyLogo
						currency0={currencies[Field.CURRENCY_A]}
						currency1={currencies[Field.CURRENCY_B]}
						size={30}
					/>
				</RowFlat>
				<Row>
					<Text fontSize="24px">
						{`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} Pool Tokens`}
					</Text>
				</Row>
				<Italic fontSize={12} textAlign="left" padding="8px 0 0 0 ">
					{`Output is estimated. If the price changes by more than ${
						allowedSlippage / 100
					}% your transaction will revert.`}
				</Italic>
			</AutoColumn>
		);
	};

	const modalBottom = () => {
		return (
			<ConfirmAddModalBottom
				price={price}
				currencies={currencies}
				parsedAmounts={parsedAmounts}
				noLiquidity={noLiquidity}
				onAdd={onAdd}
				poolTokenPercentage={poolTokenPercentage}
			/>
		);
	};

	const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
		currencies[Field.CURRENCY_A]?.symbol
	} and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`;

	const handleCurrencyASelect = useCallback(
		(currA: Currency) => {
			const newCurrencyIdA = currencyId(currA);
			if (newCurrencyIdA === currencyIdB) {
				history.push(`/amm/add/${currencyIdB}/${currencyIdA}`);
			} else {
				history.push(`/amm/add/${newCurrencyIdA}/${currencyIdB}`);
			}
		},
		[currencyIdB, history, currencyIdA]
	);
	const handleCurrencyBSelect = useCallback(
		(currB: Currency) => {
			const newCurrencyIdB = currencyId(currB);
			if (currencyIdA === newCurrencyIdB) {
				if (currencyIdB) {
					history.push(`/amm/add/${currencyIdB}/${newCurrencyIdB}`);
				} else {
					history.push(`/amm/add/${newCurrencyIdB}`);
				}
			} else {
				history.push(`/amm/add/${currencyIdA || 'ETH'}/${newCurrencyIdB}`);
			}
		},
		[currencyIdA, history, currencyIdB]
	);

	const handleDismissConfirmation = useCallback(() => {
		setShowConfirm(false);
		// if there was a tx hash, we want to clear the input
		if (txHash) {
			onFieldAInput('');
		}
		setTxHash('');
	}, [onFieldAInput, txHash]);

	return (
		<>
			<PageCard header={<CardNav activeIndex={1} />}>
				<Wrapper>
					<TransactionConfirmationModal
						isOpen={showConfirm}
						onDismiss={handleDismissConfirmation}
						attemptingTxn={attemptingTxn}
						hash={txHash}
						content={() => (
							<ConfirmationModalContent
								title={noLiquidity ? 'You are creating a pool' : 'You will receive'}
								onDismiss={handleDismissConfirmation}
								topContent={modalHeader}
								bottomContent={modalBottom}
							/>
						)}
						pendingText={pendingText}
					/>
					<div>
						<AutoColumn gap="20px">
							{noLiquidity && (
								<ColumnCenter>
									<Pane>
										<AutoColumn gap="12px">
											<Text>You are the first liquidity provider.</Text>
											<Text>The ratio of tokens you add will set the price of this pool.</Text>
											<Text>Once you are happy with the rate click supply to review.</Text>
										</AutoColumn>
									</Pane>
								</ColumnCenter>
							)}
							<CurrencyInputPanel
								value={formattedAmounts[Field.CURRENCY_A]}
								onUserInput={onFieldAInput}
								onMax={() => {
									onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '');
								}}
								onCurrencySelect={handleCurrencyASelect}
								showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
								currency={currencies[Field.CURRENCY_A]}
								id="add-liquidity-input-tokena"
								showCommonBases={false}
							/>
							<ColumnCenter>
								<AddIcon fill={theme.text1} />
							</ColumnCenter>
							<CurrencyInputPanel
								value={formattedAmounts[Field.CURRENCY_B]}
								onUserInput={onFieldBInput}
								onCurrencySelect={handleCurrencyBSelect}
								onMax={() => {
									onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '');
								}}
								showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
								currency={currencies[Field.CURRENCY_B]}
								id="add-liquidity-input-tokenb"
								showCommonBases={false}
							/>
							{currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
								<div>
									<Text
										style={{
											textTransform: 'uppercase',
											fontWeight: 600,
											marginBottom: 12,
											padding: '0 1.5rem',
										}}
										fontSize="12px"
									>
										{noLiquidity ? 'Initial prices and pool share' : 'Prices and pool share'}
									</Text>
									<Pane>
										<PoolPriceBar
											currencies={currencies}
											poolTokenPercentage={poolTokenPercentage}
											noLiquidity={noLiquidity}
											price={price}
										/>
									</Pane>
								</div>
							)}

							{!account ? (
								<LinkButton large primary to={'/connect'}>
									Connect Wallet
								</LinkButton>
							) : (
								<AutoColumn gap="md">
									{(approvalA === ApprovalState.NOT_APPROVED ||
										approvalA === ApprovalState.PENDING ||
										approvalB === ApprovalState.NOT_APPROVED ||
										approvalB === ApprovalState.PENDING) &&
										isValid && (
											<RowBetween>
												{approvalA !== ApprovalState.APPROVED && (
													<Button onClick={approveACallback} disabled={approvalA === ApprovalState.PENDING}>
														{approvalA === ApprovalState.PENDING ? (
															<Dots>Approving {currencies[Field.CURRENCY_A]?.symbol}</Dots>
														) : (
															`Approve ${currencies[Field.CURRENCY_A]?.symbol}`
														)}
													</Button>
												)}
												{approvalB !== ApprovalState.APPROVED && (
													<Button onClick={approveBCallback} disabled={approvalB === ApprovalState.PENDING}>
														{approvalB === ApprovalState.PENDING ? (
															<Dots>Approving {currencies[Field.CURRENCY_B]?.symbol}</Dots>
														) : (
															`Approve ${currencies[Field.CURRENCY_B]?.symbol}`
														)}
													</Button>
												)}
											</RowBetween>
										)}
									<Button
										onClick={() => {
											if (expertMode) {
												onAdd();
											} else {
												setShowConfirm(true);
											}
										}}
										disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
										primary
										destructive={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
									>
										{error ?? 'Supply'}
									</Button>
								</AutoColumn>
							)}
						</AutoColumn>
					</div>
				</Wrapper>
			</PageCard>
			{pair && !noLiquidity && pairState !== PairState.INVALID ? (
				<AutoColumn style={{ minWidth: '20rem', marginTop: 0 }}>
					<MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
				</AutoColumn>
			) : null}
		</>
	);
}
