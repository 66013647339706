import { useHistory } from 'react-router-dom';

import { useActiveWeb3React } from '../../hooks';

const withWeb3Account = (Component: any) => {
	return (props: any) => {
		const context = useActiveWeb3React();

		const History = useHistory();
		const toggleWalletModal = () => {
			History.push('/connect');
		};

		return <Component web3={context} toggleWalletModal={toggleWalletModal} {...props} />;
	};
};

export default withWeb3Account;
